import * as yup from 'yup';
import { getPrettyDate } from './utilityFunctions';
import { YYYY_HYP_MM_HYP_DD } from './../types/types';
const TOTAL_ALT_CONTACT = 3;
const PHONE_REGEX = /^\d{3}-\d{3}-\d{4}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9\._%+-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
const ONLY_ALPHABET = /^[a-zA-Z ]*$/;

// Patient registration profile validations and initial values
const getInitialContacts = count => {
  const initialContacts = [];
  for (let i = TOTAL_ALT_CONTACT + 1 - count; i <= TOTAL_ALT_CONTACT; i++) {
    initialContacts.push({
      id: '',
      name: '',
      phone: '',
      relationship: '',
      display_order: i,
    });
  }
  return initialContacts;
};

export const getIndications = indications => {
  return indications ? indications.map(item => item.id || -1) : [];
};

export const getUnderlyingHeartDiseases = heartDiseases => {
  return heartDiseases ? heartDiseases.map(item => item.id || -1) : [];
};

export const getNewProfileValues = () => {
  return {
    patient: {
      first_name: '',
      middle_initial: '',
      last_name: '',
      patient_identifier: '',
      date_of_birth: '',
      sex: 'female',
      primary_indication_id: '',
      indications: [],
      underlying_heart_diseases: [],
      email: '',
      phone: '',
      phone_type: '',
      other_phone: '',
      other_phone_type: '',
      pacemaker: false,
      prior_ecg: null,
      notes: '',
      ecg_analysis_notes: '',
      organization_id: '',
      location_id: '',
    },
    address: {
      line1: '',
      line2: '',
      city: '',
      state_id: '',
      zip_code: '',
    },
    contact: {
      name: '',
      phone: '',
      relationship: '',
      display_order: 1,
    },
  };
};

// initial values for populating patient profile details
export const getInitialProfileValues = patientDetails => {
  return {
    patient: {
      first_name: patientDetails.first_name,
      middle_initial: patientDetails.middle_initial,
      last_name: patientDetails.last_name,
      patient_identifier: patientDetails.patient_identifier,
      date_of_birth: getPrettyDate(
        patientDetails.date_of_birth,
        YYYY_HYP_MM_HYP_DD
      ),
      sex: patientDetails.sex,
      primary_indication_id: patientDetails.primary_indication_id || '',
      indications: getIndications(patientDetails.indications),
      underlying_heart_diseases: getUnderlyingHeartDiseases(
        patientDetails.underlying_heart_diseases
      ),
      email: patientDetails.email || '',
      phone: patientDetails.phone,
      phone_type: patientDetails.phone_type,
      other_phone: patientDetails.other_phone,
      other_phone_type: patientDetails.other_phone_type,
      notes: patientDetails.notes,
      ecg_analysis_notes: patientDetails.ecg_analysis_notes,
      pacemaker: patientDetails.pacemaker,
      prior_ecg: patientDetails.prior_ecg,
      organization_id: patientDetails.organization?.id || '',
      location_id: patientDetails.location?.id || '',
    },
    address: {
      line1: patientDetails.address.line1,
      line2: patientDetails.address.line2,
      city: patientDetails.address.city,
      state_id: patientDetails.address.state_id,
      zip_code: patientDetails.address.zip_code,
    },
    contact: getInitialvaluesUpdate(patientDetails.contacts).sort(
      (a, b) => Number(a.display_order) - Number(b.display_order)
    )[0],
  };
};

const getInitialvaluesUpdate = updateDetails => {
  const details = [...updateDetails];
  const len = details.length;
  if (len < TOTAL_ALT_CONTACT) {
    details.push(...getInitialContacts(TOTAL_ALT_CONTACT - len));
  }
  return details;
};

// customised validations for patient profile. For contact, validation happens only for first Alt contact.
export const PATIENT_REGISTRATION_PROFILE = yup.object().shape({
  patient: yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    patient_identifier: yup
      .string()
      .trim('Patient ID cannot start or end with a space')
      .required('Patient ID is required')
      .strict(),
    date_of_birth: yup.date().required('Date of Birth is required'),
    primary_indication_id: yup
      .string()
      .required('Primary indication is required'),
    phone: yup
      .string()
      .required('Phone is required')
      .matches(PHONE_REGEX, 'Enter valid phone number'),
    email: yup.string().matches(EMAIL_REGEX, 'Email must match valid format'),
    organization_id: yup.string().required('Please select an organization'),
    location_id: yup.string().required('Please select a location'),
  }),
  address: yup.object().shape({
    line1: yup.string().required('Line1 is required'),
    city: yup
      .string()
      .required('City is required')
      .matches(ONLY_ALPHABET, 'Enter valid City'),
    state_id: yup.string().required('State is required'),
    zip_code: yup
      .string()
      .required('Zip Code is required')
      .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Invalid Zip Code'),
  }),
  contact: yup.object().shape({
    name: yup.string().when('display_order', value => {
      if (value == 1) {
        return yup.string().required('Name is required');
      } else {
        return yup.string();
      }
    }),
    phone: yup.string().when('display_order', value => {
      if (value == 1) {
        return yup
          .string()
          .required('Phone is required')
          .matches(PHONE_REGEX, 'Enter valid phone number');
      } else {
        return yup.string();
      }
    }),
    relationship: yup.string().when('display_order', value => {
      if (value == 1) {
        return yup.string().required('Relationship is required');
      } else {
        return yup.string();
      }
    }),
  }),
});

export const EDIT_REPORT_APPROVAL_VALIDATION_SCHEMA = yup.object().shape({
  indication_text: yup.string(),
  reason_for_change: yup
    .string()
    .required('Reason for change must be provided'),
});

export const INITIAL_REPORT_APPROVAL_VALIDATION_SCHEMA = yup.object().shape({
  indication_text: yup.string(),
});
