import React from 'react';
import { SvgIcon } from '@mui/material';
let TestSVG = props => {
  return (
    <SvgIcon
      viewBox='0 0 66.67 91.67'
      style={Object.assign(props.style, {})}
      fillRule='evenodd'
      clipRule='evenodd'
    >
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Summary_Report'>
          <path
            className={props.className}
            d='M66.67,24.58a1.53,1.53,0,0,0-.42-1.25v-.41c0-.42-.42-.84-.83-1.25L44.58.83C44.17.42,43.75.42,43.33,0H12.5A12.28,12.28,0,0,0,0,12.5V79.17a12.28,12.28,0,0,0,12.5,12.5H54.17a12.28,12.28,0,0,0,12.5-12.5V24.58ZM52.5,20.83H45.83V14.17Zm1.67,62.5H12.5a3.93,3.93,0,0,1-4.17-4.16V12.5A3.94,3.94,0,0,1,12.5,8.33h25V25a3.94,3.94,0,0,0,4.17,4.17H58.33v50A3.93,3.93,0,0,1,54.17,83.33Z'
          />
          <text
            className={props.className}
            style={{ fontSize: '48px', fontWeight: 700 }}
            transform='translate(19.59 71.05)'
          >
            $
          </text>
        </g>
      </g>
    </SvgIcon>
  );
};

export default TestSVG;
