import { SvgIcon } from '@mui/material';
const BIconHistory = ({ style, className }) => (
  <SvgIcon style={style} className={className}>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M11.9998 0.922852C5.90747 0.922852 0.922852 5.90747 0.922852 11.9998C0.922852 18.0921 5.90747 23.0767 11.9998 23.0767C18.0921 23.0767 23.0767 18.0921 23.0767 11.9998C23.0767 5.90747 18.0921 0.922852 11.9998 0.922852ZM11.9998 20.3075C7.43054 20.3075 3.69208 16.569 3.69208 11.9998C3.69208 7.43054 7.43054 3.69208 11.9998 3.69208C16.569 3.69208 20.3075 7.43054 20.3075 11.9998C20.3075 16.569 16.569 20.3075 11.9998 20.3075ZM13.569 12.0921C13.4305 11.9536 13.3844 11.769 13.3844 11.5844V7.15362C13.3844 6.78439 13.0613 6.46131 12.6921 6.46131H11.3075C10.9382 6.46131 10.6152 6.78439 10.6152 7.15362V12.7382C10.6152 12.9229 10.7075 13.1075 10.7998 13.2459L14.2152 16.6613C14.4921 16.9382 14.9075 16.9382 15.1844 16.6613L16.1536 15.6921C16.4305 15.4152 16.4305 14.9998 16.1536 14.7229L13.569 12.0921Z'
      fill='currentColor'
    />
  </SvgIcon>
);

export default BIconHistory;
