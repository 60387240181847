// This component creates dropdown list.
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import { IDropdownItem } from '../../types/types';

interface IProps {
  ref?: null;
  label?: string;
  name?: string;
  id: string;
  value?: string;
  menuItems: IDropdownItem[];
  required?: boolean;
  fullWidth?: boolean;
  handleChange?: any;
  handleBlur?: any;
  shrink?: any;
  errors?: any;
  touched?: any;
  className?: any;
  labelWidth?: any;
  size?: string;
  inputClassName?: any;
  img?: string;
  alt?: string;
  disabled?: boolean;
}

const BDropDown = ({
  ref,
  required,
  shrink,
  id,
  label,
  value,
  handleChange,
  labelWidth,
  fullWidth,
  name,
  menuItems,
  className,
  size,
  inputClassName,
  errors,
  disabled,
}: IProps) => {
  return (
    <FormControl fullWidth={fullWidth} variant='outlined'>
      <InputLabel
        required={required}
        shrink={shrink}
        ref={ref}
        htmlFor={id}
        error={errors}
      >
        {label}
      </InputLabel>
      <Select
        className={className}
        native
        id={id}
        value={value}
        onChange={handleChange}
        inputProps={{ className: `${value}`, classes: { root: className } }}
        displayEmpty
        error={errors}
        disabled={disabled}
        defaultValue={''}
        input={
          <OutlinedInput
            notched={shrink}
            label={label}
            name={name}
            inputProps={{ className: inputClassName }}
          />
        }
      >
        {menuItems.map((item: IDropdownItem, index) => {
          // uncomment code below to get the non-native view
          // return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
          return (
            <option
              key={index}
              className={item.grayed ? 'dropdownItemDefault' : ''}
              value={item.value}
            >
              {item.isDefault ? '{ ' : ''}
              {item.label}
              {item.isDefault ? ' }' : ''}
            </option>
          );
        })}
      </Select>
      {errors && (
        <FormHelperText id={`${id}-helper-text`} error={true}>
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default BDropDown;
