import React from 'react';
import { SvgIcon } from '@mui/material';
let Address = props => {
  return (
    <SvgIcon
      style={props.style}
      height='100px'
      width='100px'
      className={props.className}
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      viewBox='0 0 100 100'
      x='0px'
      y='0px'
    >
      <path d='m48.402 4.9844c-15.526 0-28.113 11.976-28.113 26.752 0 4.2191 1.0492 8.3788 3.0605 12.139l0.00586 0.009766c0.53736 0.98576 0.93197 1.933 1.5938 2.8477l23.576 37.023 23.117-36.766c0.64297-0.89908 1.2833-2.1445 1.8066-3.1113 2.0232-3.758 3.0566-7.9182 3.0664-12.141-4.23e-4 -14.776-12.587-26.754-28.113-26.754zm0 11.221a14.828 14.828 0 0 1 14.828 14.828 14.828 14.828 0 0 1-14.828 14.828 14.828 14.828 0 0 1-14.828-14.828 14.828 14.828 0 0 1 14.828-14.828zm0 69.02a27.008 4.7992 0 0 0-27.008 4.7988 27.008 4.7992 0 0 0 27.008 4.7988 27.008 4.7992 0 0 0 27.008-4.7988 27.008 4.7992 0 0 0-27.008-4.7988z' />
    </SvgIcon>
  );
};

export default Address;
