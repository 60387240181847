import { SvgIcon } from '@mui/material';
const BEdit = ({ style, className }) => {
  return (
    <SvgIcon
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.9008 10.2769L5.63926 13.0154C5.76233 13.1385 5.94695 13.1385 6.07003 13.0154L12.9008 6.15385C13.0239 6.03077 13.0239 5.84616 12.9008 5.72308L10.1931 3.01539C10.07 2.89231 9.88541 2.89231 9.76233 3.01539L2.9008 9.87693C2.77772 10 2.77772 10.1846 2.9008 10.2769ZM11.0854 1.75385C10.9623 1.87693 10.9623 2.06154 11.0854 2.18462L13.7931 4.89231C13.9162 5.01539 14.1008 5.01539 14.2239 4.89231L14.9931 4.12308C15.4854 3.66154 15.4854 2.92308 14.9931 2.43077L13.5469 0.984618C13.0546 0.49231 12.2854 0.49231 11.7931 0.984618L11.0854 1.75385ZM0.623873 14.8308C0.562334 15.1385 0.839257 15.4154 1.14695 15.3538L4.5008 14.5539C4.62387 14.5231 4.71618 14.4615 4.77772 14.4L4.83926 14.3385C4.9008 14.2769 4.93157 14.0615 4.80849 13.9385L2.03926 11.1692C1.91618 11.0462 1.7008 11.0769 1.63926 11.1385L1.57772 11.2C1.48541 11.2923 1.45464 11.3846 1.42387 11.4769L0.623873 14.8308Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
};

export default BEdit;
