// This component creates Textbox with optional validation text message.
import { TextField } from '@mui/material';
import { IBTextField } from './../../types/types';

const BTextField = ({
  type,
  placeholder,
  label,
  name,
  id,
  disabled,
  value,
  required,
  fullWidth,
  handleChange,
  handleBlur,
  shrink,
  errors,
  touched,
  style,
  rows,
  multiline,
  className,
  inputStyle,
  defaultValue,
  maxLength,
  max,
  tabIndex,
  autoFocus,
  step,
  additionalProps,
  inputClassName,
  variant,
  size,
}: IBTextField) => {
  // const classes = stylesBTextField();
  additionalProps
    ? (additionalProps = additionalProps)
    : (additionalProps = {});
  return (
    <TextField
      id={id}
      type={type}
      disabled={disabled}
      autoFocus={autoFocus}
      placeholder={placeholder}
      fullWidth={fullWidth}
      required={required}
      label={label}
      name={name}
      // size={size || 'medium'}
      variant={variant || 'outlined'}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      InputLabelProps={{
        classes: {
          // root: classes.labelRoot,
        },
        shrink: shrink,
      }}
      // InputProps => Props applied to materials variety of Inputs, in this case variant outlined
      InputProps={Object.assign(additionalProps, {
        className: inputClassName,
        // classes: { input: classes.inputRoot }
      })}
      // inputProps => attributes applied to html input element
      inputProps={{
        maxLength: maxLength,
        max: max,
        tabIndex: tabIndex,
        step: step,
        style: inputStyle,
      }}
      style={style}
      error={required ? touched && errors : errors}
      helperText={required ? touched && errors : errors || ''}
      FormHelperTextProps={{
        classes: {
          // root: classes.validationMsg
        },
      }}
      rows={rows}
      multiline={multiline}
      className={className}
      defaultValue={defaultValue}
    />
  );
};

export default BTextField;
