import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { PHONE_MASK } from '../../../types/types';
import { generateID } from '../../../utility/utilityFunctions';
import BTextField from '../../common/BTextField';
import BInputMaskTextBox from '../../common/BInputMaskTextBox';
import BFormGroupTitle from '../../common/BFormGroupTitle';
import { ICONS } from '../../../utility/IconSelection';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';

const PatientContact = props => {
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const { canUpdatePatient } = USER_ACCESS;

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '22px',
            marginBottom: '20px',
            backgroundColor: 'rgb(238 238 238)',
          }}
        >
          <BFormGroupTitle
            title='Contact'
            icon={ICONS['CONTACT']}
          ></BFormGroupTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <BInputMaskTextBox
            id={generateID('input', 'pr_profile', 'phone')}
            name='patient.phone'
            value={props.patient.phone}
            required={true}
            handleChange={e => {
              props.setFieldValue('patient.phone', e.target.value);
            }}
            errors={props.errors.patient ? props.errors.patient.phone : false}
            touched={
              props.touched.patient ? props.touched.patient.phone : false
            }
            handleBlur={props.handleBlur}
            label='Primary Phone'
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
          />
        </Grid>
        <Grid item xs={4}>
          <BInputMaskTextBox
            id={generateID('input', 'pr_profile', 'other_phone')}
            required={false}
            errors={
              props.errors.patient ? props.errors.patient.other_phone : false
            }
            touched={
              props.touched.patient ? props.touched.patient.other_phone : false
            }
            name='patient.other_phone'
            value={props.patient.other_phone}
            handleChange={e => {
              props.setFieldValue(
                'patient.other_phone',
                e.target.value.replace(/[-()]/g, '') // remove dashes and parens (only numbers)
              );
            }}
            handleBlur={props.handleBlur}
            label='Other Phone'
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
          />
        </Grid>
        <Grid item xs={4}>
          <BTextField
            id={generateID('input', 'pr_profile', 'email')}
            required={false}
            errors={props.errors.patient?.email}
            touched={props.touched.patient?.email}
            name='patient.email'
            value={props.patient.email}
            handleChange={props.handleChange}
            handleBlur={props.handleBlur}
            label='Email address'
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
          />
        </Grid>
        <Grid item xs={12}>
          <BTextField
            fullWidth
            required={true}
            id={generateID('input', 'pr_profile', 'name_' + 0)}
            label='Alternate contact name'
            shrink='true'
            name={`contact.name`}
            value={props.contact.name}
            handleChange={props.handleChange}
            handleBlur={props.handleBlur}
            errors={
              props.errors && props.errors.contact && props.errors.contact.name
            }
            touched={
              props.touched &&
              props.touched.contact &&
              props.touched.contact.name
            }
            disabled={!canUpdatePatient}
          />
        </Grid>
        <Grid item xs={6}>
          <BInputMaskTextBox
            id={generateID('input', 'pr_profile', 'phone_' + 0)}
            required={true}
            label='Alternate contact phone'
            name={`contact.phone`}
            value={props.contact.phone}
            handleChange={props.handleChange}
            handleBlur={props.handleBlur}
            shrink='true'
            errors={
              props.errors && props.errors.contact && props.errors.contact.phone
            }
            touched={
              props.touched &&
              props.touched.contact &&
              props.touched.contact.phone
            }
            fullWidth={true}
            disabled={!canUpdatePatient}
          />
        </Grid>
        <Grid item xs={6}>
          <BTextField
            id={generateID('input', 'pr_profile', 'relationship_' + 0)}
            fullWidth
            required={true}
            label='Alternate contact relationship'
            name={`contact.relationship`}
            value={props.contact.relationship}
            handleChange={props.handleChange}
            handleBlur={props.handleBlur}
            shrink='true'
            errors={
              props.errors &&
              props.errors.contact &&
              props.errors.contact.relationship
            }
            touched={
              props.touched &&
              props.touched.contact &&
              props.touched.contact.relationship
            }
            disabled={!canUpdatePatient}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientContact;
