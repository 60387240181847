import React from 'react';
import { SvgIcon } from '@mui/material';
let BBillingReport = props => {
  return (
    <SvgIcon
      style={props.style}
      height='200px'
      width='200px'
      className={props.className}
      viewBox='0 0 46 64'
    >
      <polygon
        className={props.className}
        points='31.99 1.79 31.99 14.08 44.24 14.08 31.99 1.79'
      />
      <path
        className={props.className}
        d='M24.29,39.27v5.65a3.65,3.65,0,0,0,2.11-1A2.56,2.56,0,0,0,27.21,42a2.31,2.31,0,0,0-.68-1.67A4.59,4.59,0,0,0,24.29,39.27Z'
      />
      <path
        className={props.className}
        d='M19.57,32a2.16,2.16,0,0,0,.55,1.43,3.86,3.86,0,0,0,1.67,1.07v-5a3.19,3.19,0,0,0-1.62,1A2.28,2.28,0,0,0,19.57,32Z'
      />
      <path
        className={props.className}
        d='M26.82,20a.8.8,0,0,1-.8-.8V0H9A9,9,0,0,0,0,9V55a9,9,0,0,0,9,9H37a9,9,0,0,0,9-9V24.31h0V20Zm2.72,25.88a8.93,8.93,0,0,1-5.25,2.21v2.71h-2.5V48.2A8.69,8.69,0,0,1,17,46.33a7.23,7.23,0,0,1-2.38-4.43l4.5-.4a4.5,4.5,0,0,0,1,2,4.64,4.64,0,0,0,1.69,1.22V38.64a9.91,9.91,0,0,1-4.95-2.46,5.52,5.52,0,0,1-1.58-4,5.18,5.18,0,0,1,1.78-4,7.85,7.85,0,0,1,4.75-1.86V24.89h2.5v1.44a8.2,8.2,0,0,1,4.37,1.57,5.77,5.77,0,0,1,2.06,3.48l-4.36.47a3.07,3.07,0,0,0-2.07-2.32v5.68c2.76.62,4.64,1.44,5.64,2.44a5.21,5.21,0,0,1,1.5,3.85A5.86,5.86,0,0,1,29.54,45.92Z'
      />
    </SvgIcon>
  );
};

export default BBillingReport;
