// This component creates Toggle buttons. The toggle button => out of all only one can be selected at a time
import { ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import { theme } from '../../styles/Theme';

const BToggleButton = ({
  handleChange,
  value,
  toggleButtonList,
  style,
  disable,
}) => {
  return (
    <ToggleButtonGroup
      size='medium'
      exclusive
      onChange={handleChange}
      value={value}
    >
      {toggleButtonList.map((item, index) => {
        return (
          <ToggleButton
            key={index}
            id={item.id}
            style={style}
            value={item.value}
            className={item.className}
            selected={value === item.value}
            disabled={disable.includes(item.label.toLowerCase())}
          >
            {item.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export const PToggleButton = ({
  handleChange,
  value,
  toggleButtonList,
  style,
  disable,
}) => {
  const baxterBlue = theme.palette.primary.main;
  return (
    <ToggleButtonGroup
      size='medium'
      exclusive
      onChange={handleChange}
      value={value}
      style={{
        width: '340px',
        height: '40px',
        border: '2px solid #154898',
        marginLeft: '120px',
        borderRadius: '30px',
      }}
    >
      {toggleButtonList.map((item, index) => {
        return (
          <ToggleButtonOutlined
            key={index}
            id={item.id}
            style={style}
            value={item.value}
            className={item.className}
            selected={value === item.value}
            disabled={disable.includes(item.label.toLowerCase())}
          >
            {item.label}
          </ToggleButtonOutlined>
        );
      })}
    </ToggleButtonGroup>
  );
}

export const ToggleButtonOutlined = styled(ToggleButton)(({ theme }) => ({
  color: '#424242',
  backgroundColor: 'white',
  border: 'none',
  width: '50%',
  borderRadius: '30px !important',
  fontFamily: 'Geogrotesque, Arial, sans-serif',
  fontWeight: 'semibold',
  fontSize: '15px',
  margin: '2px',
  lineHeight: '24px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
}));

export default BToggleButton;
