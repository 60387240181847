import { Route, Navigate, Outlet } from 'react-router-dom';
import EventMonitorPatientList from '../EventMonitorPatientList';
import PatientListComponent from '../PatientListComponent';
import PatientRegistrationComponent from '../PatientRegistrationComponent';
import AdminMain from '../components/admin/AdminMain';
import ReportsViewer from '../components/reports/ReportsViewer';

export const ROUTE_COMPONENTS = {
  events: {
    id: 'events',
    component: (
      <Route
        key={'event'}
        path='events'
        element={<EventMonitorPatientList type='EVENT' />}
      />
    ),
  },
  patient: {
    id: 'patient',
    component: (
      <Route key={'patientregistration'} path='patient' element={<Outlet />}>
        <Route index element={<Navigate to='/patient/new' />} />
        <Route path='new' element={<PatientRegistrationComponent />} />
        <Route path=':id' element={<PatientRegistrationComponent />} />
      </Route>
    ),
  },
  patients: {
    id: 'patients',
    component: (
      <Route
        key={'patient'}
        path='patients'
        element={<PatientListComponent />}
      />
    ),
  },
  reports: {
    id: 'reports',
    component: (
      <Route key={'report'} path='reports/:id' element={<ReportsViewer />} />
    ),
  },
  admin: {
    id: 'admin',
    component: <Route key={'admin'} path='admin' element={<AdminMain />} />,
  },
};
