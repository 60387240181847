import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { commonData } from './../../data/CommonData';

export default function BToastMessage(props) {
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={props.style}
        open={props.open}
        autoHideDuration={
          'autoHide' in props ? props.autoHide : commonData.general.hideToastMsg
        } // milliseconds, null means disabled
        onClose={props.close}
      >
        <Alert id={props.id} severity={props.severity} onClose={props.close}>
          {props.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
