import { Level, MCT_Level, ISetting } from './../../../types/types';
import * as yup from 'yup';
import { commonData } from './../../../data/CommonData';
import { generateID } from '../../utilityFunctions';

// Build output to be sent to save device event values
export const getValuesForMutation = values => {
  const settings = Object.keys(values).map(key => {
    if (key.includes('level')) {
      const splitKey = key.split('_');
      const id = splitKey[1];
      return {
        id,
        level: values[key],
      };
    } else if (key.includes('report')) {
      const splitKey = key.split('_');
      const id = splitKey[0];
      return {
        id,
        value: values[key],
        notification_type: 'reportable',
      };
    }
    return {
      id: key,
      value: values[key],
      notification_type: 'notifiable',
    };
  });
  return settings;
};

export const shapeValuesForMutation = values => {
  const { settings, thresholds } = values;

  // {"123-456": "on"} #==> {id: "123-4556", level: "on"}
  const flatSettings = Object.entries(settings).map(([id, value]) => ({
    id,
    level: value,
  }));

  // {"123-456": "on"} #==> {id: "123-4556", value: 150}
  const flatThresholds = Object.entries(thresholds).map(([id, value]) => ({
    id,
    value,
    notification_type: 'notifiable',
  }));

  return [...flatThresholds, ...flatSettings];
};

export const getFormNameByIds = thresholdId => {
  return `${thresholdId}`;
};

export const getLevelNameById = (id, type = '') => {
  return 'level_' + id + type;
};

export const getFormValueByIds = (thresholdId, values) => {
  return values[`${thresholdId}`];
};

export const getLevelToggleClass = (type, radioName, values) => {
  return values[radioName] === type
    ? 'leveltoggle' + '-' + type
    : 'setting-level-default';
};

// Build structure to get the device event levels
export const getDeviceEventLevel = (id, values, abbr) => {
  let level = commonData.general.deviceType === 'MCT' ? MCT_Level : Level;
  let formedOutput = [];

  if (id && values) {
    for (let key in level) {
      let levelValue = level[key].toLowerCase();
      formedOutput.push({
        label: level[key],
        value: levelValue,
        id: generateID('tglbtn', 'pr_settings', abbr + '_' + levelValue),
        className:
          getLevelToggleClass(levelValue, getLevelNameById(id), values) +
          ' setting-level-common',
      });
    }
  }
  return formedOutput;
};

export const getThresholdValues = (
  type,
  abbr,
  name,
  settingThreshold,
  initialValues,
  settingNameMapping,
  validationSchemaValues
) => {
  settingThreshold.forEach(item => {
    const { min, max } = item.thresholdType;

    initialValues[item.id] = item.value;
    settingNameMapping[item.id] = [abbr, name, item.thresholdType.name];

    validationSchemaValues[item.id] = yup
      .number()
      .typeError('Must be a number')
      .required('Required')
      .min(min, 'Out of range')
      .max(max, 'Out of range');
  });
  return [initialValues, settingNameMapping, validationSchemaValues];
};

// Build initial values and validation schema for form values
export const getInitialAndValidation = (value, reportable = false) => {
  let initialValues = {};
  let validationSchemaValues = {};
  let settingNameMapping = {};
  if (value) {
    value.settings &&
      value.settings.length > 0 &&
      value.settings.map((setting, i) => {
        // Notifiable settings thresholds
        getThresholdValues(
          '',
          setting.settingType.abbr,
          setting.settingType.name,
          setting.thresholds,
          initialValues,
          settingNameMapping,
          validationSchemaValues
        );

        // Reportable settings thresholds
        // TODO: .lessThan(yup.ref(getFormNameByIds(threshold_id + "_report").toString()), "more than report")
        if (setting.threshold_report) {
          getThresholdValues(
            '_report',
            setting.settingType.abbr + ' report',
            setting.settingType.name,
            setting.threshold_report,
            initialValues,
            settingNameMapping,
            validationSchemaValues
          );
        }
        initialValues[getLevelNameById(setting.id)] = setting.severity;
        settingNameMapping[getLevelNameById(setting.id)] = [
          setting.settingType.abbr,
          setting.settingType.name,
        ];
      });
  }
  return [initialValues, validationSchemaValues, settingNameMapping];
};

export const getInitialAndValidationAdmin = (
  settings: ISetting[],
  reportable = false
): [
  initialValues: {
    thresholds: { [id: string]: string | number };
    settings: { [id: string]: 'on' | 'off' };
  },
  thresholdValidations: { [id: string]: any },
] => {
  const initialValues = { thresholds: {}, settings: {} };
  const thresholdValidations = {};

  settings.forEach((setting, i) => {
    initialValues['settings'][setting.id] = setting.severity;

    setting.thresholds.forEach(threshold => {
      const { min, max } = threshold.thresholdType;

      initialValues['thresholds'][threshold.id] = threshold.value;

      thresholdValidations[threshold.id] = yup
        .number()
        .typeError('Must be a number')
        .required('Required')
        .min(min, 'Out of range')
        .max(max, 'Out of range');
    });
  });

  return [initialValues, thresholdValidations];
};

const getFlatThresholdSetting = thresholdSetting => {
  let flatThresholdObj = {};
  for (
    let thresholdIndex = 0;
    thresholdIndex < thresholdSetting.length;
    thresholdIndex++
  ) {
    // levels - severity
    flatThresholdObj[
      thresholdSetting[thresholdIndex]?.settingType?.settingTypeId
    ] = thresholdSetting[thresholdIndex]?.severity;
    // thresholds can be multiple
    for (
      let thresholdTypeIndex = 0;
      thresholdTypeIndex < thresholdSetting[thresholdIndex].thresholds.length;
      thresholdTypeIndex++
    ) {
      flatThresholdObj[
        thresholdSetting[thresholdIndex]?.thresholds[thresholdTypeIndex]
          ?.thresholdType?.thresholdTypeId
      ] = thresholdSetting[thresholdIndex].thresholds[thresholdTypeIndex].value;
    }
  }
  return flatThresholdObj;
};

// values from threshold template to device values
export const thresholdSettingTemplateChange = (
  value: ISetting[],
  initialValues,
  thresholdSetting,
  setFieldValue,
  setChanges
): [value: any, initialValues: any, trackChanges: any] => {
  let flatThresholdSetting = getFlatThresholdSetting(thresholdSetting);
  let trackChanges = {};
  if (value) {
    value.forEach((setting, i) => {
      let changeHappened = false;
      // For notifiable settings thresholds
      let settingTypeId = setting.settingType.id;
      setting.thresholds.map(item => {
        let thresholdTypeId = item.thresholdType.id;
        setFieldValue(
          getFormNameByIds(item.id),
          flatThresholdSetting[thresholdTypeId],
          false
        );
        if (initialValues[item.id] != flatThresholdSetting[thresholdTypeId])
          changeHappened = true;
      });
      setFieldValue(
        getLevelNameById(setting.id),
        flatThresholdSetting[settingTypeId],
        false
      );
      if (initialValues[setting.id] != flatThresholdSetting[settingTypeId])
        changeHappened = true;
      changeHappened
        ? (trackChanges[setting.settingType.abbr] = setting.settingType.abbr)
        : console.log('Dont log this abbr');
    });
    setChanges(trackChanges);
  }
  return [value, initialValues, trackChanges];
};

export const getEventCriteriaValue = (eventCriteriaName, value) => {
  return eventCriteriaName === 'First Occurrence'
    ? value === 0
      ? 'Off'
      : 'On'
    : value;
};
