import moment from 'moment';
import { IView, IDropdownItem } from '../types/types';

export const getDiffFromActivation = t => moment(t).fromNow();
export const dataReceivedExceedsThreshold = (t, threshold_in_days) => {
  const mT = moment(t);
  const thresholdDate = mT.add(threshold_in_days, 'days');

  return moment().isAfter(thresholdDate);
};

export const calculateWearTimeRemaining = (recording_started_at, wear_time_days) => {
  const mStart = moment(recording_started_at);
  const mEnd = mStart.clone().add(wear_time_days, 'days');
  const now = moment();
  const diffInDays = Math.floor(moment.duration(mEnd.diff(now)).asDays());

  return diffInDays;
};

export const getDiffDays = inputDateISO => {
  // calculate time since last transmission - current date - last time the data was transmitted
  let diffDays = moment
    .duration(moment(new Date().valueOf()).diff(moment(inputDateISO)))
    .asDays();
  return diffDays;
};

// date formatting logic
export const getPrettyDate = (inputDateISO, format, local = false) => {
  if (!inputDateISO || !format) return 'Error';
  let formattedDate;
  try {
    if (local) {
      // current timezone
      formattedDate = moment.utc(inputDateISO).local().format(format); // local will convert to the Users system time.
    } else {
      formattedDate = moment.utc(inputDateISO).format(format); // local will convert to the Users system time.
    }
  } catch (error) {
    console.error('Error in Utility function: ', error);
    return 'Error';
  }
  return formattedDate;
};

export const sortViews = (options: IView[]): IView[] => {
  return [...options].sort((a, b) => {
    if (a.name > b.name) return 1;
    if (b.name > a.name) return -1;
    return 0;
  });
}

export const sortViewDropdownOptions = (options: IDropdownItem[]): IDropdownItem[] =>
  options.sort((a, b) => {
    if (a.label > b.label) return 1;
    if (b.label > a.label) return -1;
    return 0;
  });

export const generateID = (BlockType, module, modifier) => {
  return `${BlockType}__${module}__${modifier}`;
};

export const getEventStyles = (type, severity) => {
  severity = severity?.toLowerCase();
  let backgroundColor = 'white';
  let color = 'black';
  let border = 'none';
  let fontWeight = 'none';

  switch (severity) {
    case 'on': {
      backgroundColor = '#ff3434';
      color = 'white';
      fontWeight = 'bold';
      border = 'none';
      break;
    }
    case 'off': {
      if (type === 'settings') {
        color = '#fff';
        backgroundColor = '#c5c7c7';
        fontWeight = 'bold';
        border = 'none';
      } else if (type === 'events') {
        color = '#fff';
        backgroundColor = '#c5c7c7';
        fontWeight = 'bold';
        border = 'none';
      }
      break;
    }
  }
  return { color, backgroundColor, fontWeight, border };
};

export const getEventIconStyles = severity => {
  let color = 'white';
  let outline = 'red';
  let width = '40px';
  const fontSize = '30px';
  const marginTop = '6px';
  const marginRight = '8px';

  let backgroundColor = '#fff';
  switch (severity) {
    case 'on': {
      color = '#ff3434';
      outline = '#ff3434';
      break;
    }
    case 'off': {
      color = '#c5c7c7';
      outline = 'none';
      break;
    }
  }
  return { width, color, outline, fontSize, marginTop, marginRight };
};

export const truncateToDecimal = (num, decimalPoints = 0) => {
  // truncates num to decimalPoints without insignificant trailing zeroes
  let convertedNumber = Number.parseFloat(num).toFixed(decimalPoints);
  return Number(convertedNumber);
};
