import React, { useState, useEffect } from 'react';
import messageFormat from '../../../utility/messageToUser.json';
import BConfirm from '../../common/BConfirm';
import BTextField from '../../common/BTextField';
import { Grid, Typography } from '@mui/material';
import { ThresholdList } from './ThresholdList';
import BButton from '../../common/BButton';
import BCheckbox from '../../common/BCheckBox';
import { getValuesForMutation } from '../../../utility/helper/settings/SettingOperations';
import { generateID } from '../../../utility/utilityFunctions';

const OrgThresholdSettings = props => {
  // default state for the threshold settings
  const [currentThreshold, setCurrentThreshold] = useState(
    props.currentThresholdSelection
  ); // stores group_id selected threshold in dropdown
  const [defaultForNewPatient, setDefaultForNewPatient] = useState(
    props.currentThresholdSelection
  ); // stores groud_id whose default is selected
  const [thresholdDropdown, setThresholdDropdown] = useState(
    props.thresholdList
  );
  const [renameThreshold, setRenameThreshold] = useState('');
  const [openDialogRename, setOpenDialogRename] = useState({ open: false });
  const [openDialogAdd, setOpenDialogAdd] = useState({ open: false });
  const [openDialogDelete, setOpenDialogDelete] = useState({ open: false });
  const [newThreshold, setNewThreshold] = useState('');
  const [systemDefault, setSystemDefault] = useState(props.systemDefaultId);
  const RENAME_THRESHOLD = 1;
  const DEFAULT_NEW_PATIENT = 2;
  const DELETE_THRESHOLD = 3;
  const CREATE_THRESHOLD = 4;

  useEffect(() => {
    setThresholdDropdown(props.thresholdList);
  }, [props.mctAdmin]);

  // update dropdown content
  const updateDropdownContent = (type, newThresholdId = null) => {
    switch (type) {
      case RENAME_THRESHOLD: {
        for (let i = 0; i < thresholdDropdown.length; i++) {
          if (thresholdDropdown[i].value === currentThreshold) {
            thresholdDropdown[i].label = renameThreshold;
            break;
          }
        }
        break;
      }
      case DEFAULT_NEW_PATIENT: {
        for (let i = 0; i < thresholdDropdown.length; i++) {
          if (thresholdDropdown[i].value === currentThreshold) {
            thresholdDropdown[i].isDefault = true;
          } else {
            thresholdDropdown[i].isDefault = false;
          }
        }
        setDefaultForNewPatient(currentThreshold);
        break;
      }
      case DELETE_THRESHOLD: {
        let delIndex = -1;
        for (let i = 0; i < thresholdDropdown.length; i++) {
          if (thresholdDropdown[i].value == currentThreshold) {
            delIndex = i;
            break;
          }
        }
        thresholdDropdown.splice(delIndex, 1);
        setCurrentThreshold(systemDefault);
        props.thresholdSettingChange(
          systemDefault,
          props.setFieldValue,
          props.values
        );
        break;
      }
      case CREATE_THRESHOLD: {
        setThresholdDropdown([
          ...thresholdDropdown,
          {
            value: newThresholdId,
            label: newThreshold,
          },
        ]);
        setCurrentThreshold(newThresholdId);
        break;
      }
    }
  };

  // create threshold to server
  const createThresholdName = () => {
    let createNewThresholdVal = getValuesForMutation(props.values);
    props
      .createOrgSettings({
        variables: {
          organization_id: props.ORGANIZATION_ID,
          settings: createNewThresholdVal,
          name: newThreshold,
          device_type: props.deviceType,
        },
      })
      .then(
        res => {
          // update threshold List;
          updateDropdownContent(
            CREATE_THRESHOLD,
            res.data.createSettingGroup.id
          ); // TODO update with new id from backend;
          setNewThreshold('');
          props.setSavedChanges({
            apiCall: true,
            type: 'success',
            msg: messageFormat.admin.thresholdSetting.createdSuccessfully,
          });
        },
        error => {
          console.log(
            'Error while renaming threshold setting, Error msg: ',
            error
          );
          props.setSavedChanges({
            apiCall: true,
            type: 'error',
            msg: messageFormat.admin.thresholdSetting.createdUnSuccessfully,
          });
        }
      );
  };

  // rename threshold to server
  const updateThresholdName = () => {
    props
      .renameSettings({
        variables: { group_id: currentThreshold, name: renameThreshold },
      })
      .then(
        res => {
          // update threshold List;
          updateDropdownContent(RENAME_THRESHOLD);
          props.setSavedChanges({
            apiCall: true,
            type: 'success',
            msg: messageFormat.admin.thresholdSetting.renamedSuccessfully,
          });
          setRenameThreshold('');
        },
        error => {
          console.log(
            'Error while renaming threshold setting, Error msg: ',
            error
          );
          props.setSavedChanges({
            apiCall: true,
            type: 'error',
            msg: messageFormat.admin.thresholdSetting.renamedUnSuccessfully,
          });
        }
      );
  };
  // set new patient default to server
  const updateDefaultForNewPatient = () => {
    props
      .defaultForNewPatient({
        variables: {
          setting_group_id: currentThreshold,
          organization_id: props.ORGANIZATION_ID,
          device_type: props.deviceType,
        },
      })
      .then(
        res => {
          // update threshold List;
          updateDropdownContent(DEFAULT_NEW_PATIENT);
          props.setSavedChanges({
            apiCall: true,
            type: 'success',
            msg: messageFormat.admin.thresholdSetting.markDefaultSuccessfully,
          });
        },
        error => {
          console.error(
            'Error while setting threshold setting patient default, Error msg: ',
            error
          );
          props.setSavedChanges({
            apiCall: true,
            type: 'error',
            msg: messageFormat.admin.thresholdSetting.markDefaultUnSuccessfully,
          });
        }
      );
  };

  // set new patient default to server
  const deleteThreshold = () => {
    props
      .deleteSettings({ variables: { setting_group_id: currentThreshold } })
      .then(
        res => {
          // update threshold List;
          updateDropdownContent(DELETE_THRESHOLD);
          props.setSavedChanges({
            apiCall: true,
            type: 'success',
            msg: messageFormat.admin.thresholdSetting.deletedSuccessfully,
          });
        },
        error => {
          console.error(
            'Error while deleting setting threshold, Error msg: ',
            error
          );
          props.setSavedChanges({
            apiCall: true,
            type: 'error',
            msg: messageFormat.admin.thresholdSetting.deletedUnSuccessfully,
          });
        }
      );
  };

  return (
    <>
      {/*  Rename Threshold dialog */}
      <BConfirm
        title='Rename Threshold Settings'
        open={openDialogRename.open}
        okDisabled={renameThreshold == ''}
        setOpen={setOpenDialogRename}
        onConfirm={() => {
          setOpenDialogRename({ open: false });
          updateThresholdName();
        }}
        cancelEvent={() => {
          setOpenDialogRename({ open: false });
        }}
      >
        <>
          <br />
          <BTextField
            name=''
            shrink={true}
            label='Setting Name'
            value={renameThreshold}
            handleChange={e => {
              setRenameThreshold(e.target.value);
            }}
          />
        </>
      </BConfirm>

      <BConfirm
        title='Create Threshold Settings'
        open={openDialogAdd.open}
        okDisabled={newThreshold == ''}
        setOpen={setOpenDialogAdd}
        onConfirm={() => {
          setOpenDialogAdd({ open: false });
          createThresholdName();
        }}
        cancelEvent={() => {
          setOpenDialogAdd({ open: false });
        }}
      >
        <>
          <br />
          <BTextField
            name=''
            shrink={true}
            label='Setting Name'
            value={newThreshold}
            handleChange={e => {
              setNewThreshold(e.target.value);
            }}
          />
        </>
      </BConfirm>
      <BConfirm
        title='Delete Threshold Settings'
        severity='warning'
        open={openDialogDelete.open}
        setOpen={setOpenDialogDelete}
        onConfirm={() => {
          setOpenDialogDelete({ open: false });
          deleteThreshold();
        }}
        cancelEvent={() => {
          setOpenDialogDelete({ open: false });
        }}
      >
        <>{messageFormat.admin.thresholdSetting.confirmDeleteThreshold}</>
      </BConfirm>

      <Grid
        container
        spacing={2}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginTop: '12px',
        }}
      >
        <Grid item xs={3}>
          {/* Threshold settings Name text field  */}
          <ThresholdList
            id={generateID('drpdwn', 'orgadmthreshold', 'threshold')}
            name=''
            label='Event Criteria Name'
            menuItems={thresholdDropdown}
            value={currentThreshold}
            shrink={true}
            labelWidth={200}
            fullWidth={true}
            handleChange={event => {
              props.thresholdSettingChange(
                event.target.value,
                props.setFieldValue,
                props.values
              );
              setCurrentThreshold(event.target.value);
            }}
          />
        </Grid>
        {/* Threshold settings Rename  */}
        <Grid item xs={3}>
          <span style={{ padding: '2px' }}></span>
          <BButton
            type='blue'
            id={generateID('btn', 'orgadmthreshold', 'rename_setting')}
            caption='Rename'
            disabled={currentThreshold === systemDefault}
            clickEvent={e => {
              e.preventDefault();
              setRenameThreshold('');
              setOpenDialogRename({ open: true });
            }}
          />
          <span style={{ padding: '2px' }}></span>
          {/* Threshold settings New Setting  */}
          <BButton
            type='blue'
            id={generateID('btn', 'orgadmthreshold', 'new_setting')}
            caption='New'
            clickEvent={e => {
              e.preventDefault();
              setOpenDialogAdd({ open: true });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          {/* Default for New patients checkbox */}
          <BCheckbox
            name='defaultThresholdSettings'
            id={generateID('chkbox', 'orgadmthreshold', 'default_setting')}
            checked={currentThreshold === defaultForNewPatient}
            handleChange={(_, value) => {
              // update BE
              if (value) {
                updateDefaultForNewPatient();
              }
            }}
          />
          <Typography
            style={{ display: 'inline' }}
            id={
              generateID('chkbox', 'orgadmthreshold', 'default_setting') +
              '-label'
            }
          >
            Default for New Patient
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {/* Button to delete the threshold setting */}
          <BButton
            id={generateID('btn', 'orgadmthreshold', 'delete_setting')}
            disabled={
              currentThreshold === systemDefault ||
              currentThreshold === defaultForNewPatient
            }
            type='blue'
            caption='Delete'
            clickEvent={e => {
              e.preventDefault();
              setOpenDialogDelete({ open: true });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OrgThresholdSettings;
