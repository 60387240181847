// External Libraries
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { generateID } from '../../../utility/utilityFunctions';
import BConfirm from '../../common/BConfirm';
import BSaveCancel from '../../common/BSaveCancel';
import BToastMessage from '../../common/ToastMessage';
import { commonData } from './../../../data/CommonData';
import PatientAddress from './PatientAddress';
import PatientContact from './PatientContact';
import PatientInformation from './PatientInformation';
import PatientClinicalInformation from './PatientClinicalInformation';
import PatientNotes from './PatientNotes';
import BSpinner from '../../common/BSpinner';
import { Grid, AlertColor } from '@mui/material';
import {
  PATIENT_REGISTRATION_PROFILE,
  getNewProfileValues,
  getInitialProfileValues,
} from '../../../utility/validationSchema';
import messageFormat from '../../../utility/messageToUser.json';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_PATIENT,
  PATIENT,
  SELECTED_PATIENT_ID,
  SELECT_PATIENT,
  UPDATE_PATIENT,
  ORGANIZATIONS,
  HEART_DISEASES,
} from '../../../data/queries/patient';
import { INDICATIONS } from '../../../data/queries/indication';
import { IPatientDetails } from '../../../types/types';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';
import { useSendAuditTrailLogMessage } from '../../../data/queries/auditTrailLogMessage';
import { patient } from '../../../data/queries/live';

type IProps = {
  patientDetails: IPatientDetails;
  gotoHome: () => void;
  reflectUpdatedPatientChanges: any;
};

const PatientRegistrationProfile = (props: IProps) => {
  const navigate = useNavigate();
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const username = useContext(AccessPermissionsContext)?.name;
  const { canUpdatePatient } = USER_ACCESS;

  const {
    loading: loadingSelectedPatient,
    error: errorSelectedPatient,
    data: { selectedPatientId } = { selectedPatientId: null },
  } = useQuery(SELECTED_PATIENT_ID);
  let { loading, error, data } = useQuery(
    selectedPatientId ? PATIENT : INDICATIONS,
    {
      variables: { patient_id: selectedPatientId },
      fetchPolicy: 'network-only', // patient data MUST be fetched from network only and never cached
    }
  );

  let {
    loading: loadingHeartDiseases,
    error: errorHeartDiseases,
    data: dataHeartDiseases,
  } = useQuery(HEART_DISEASES);

  let {
    loading: loadingOrgs,
    error: errorOrgs,
    data: { organizations } = { organizations: [] },
  } = useQuery(ORGANIZATIONS, {
    fetchPolicy: 'network-only', // patient data MUST be fetched from network only and never cached
  });

  const [select, updated] = useMutation(SELECT_PATIENT);
  const [createPatient] = useMutation(ADD_PATIENT);
  const [updatePatient] = useMutation(UPDATE_PATIENT, {
    refetchQueries: [`getPatients`],
  });
  const [patientDetails, setPatientDetails] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [unsaveConfirm, setUnsaveConfirm] = useState({ open: false });
  const [savedChanges, setSavedChanges] = useState({
    apiCall: false,
    type: '',
    msg: '',
  });
  const { sendAuditMessage } = useSendAuditTrailLogMessage();

  // TODO remove redundancy of "data" and "patientDetails" and "props.patientDetails"
  useEffect(() => {
    if (!data) return;
    setPatientDetails(data);
    if (!data.patient) return;
    sendAuditMessage({
      action: 'DATA_ACCESS',
      phi_flag: true,
      description: `Viewed patient profile ${data.patient.first_name} ${data.patient.last_name} ${data.patient?.id}`,
    });
  }, [data]);

  const gotoHome = () => {
    select({
      variables: { id: null, deviceId: null, isDeviceRegistered: false },
    });
    props.gotoHome();
  };

  if (loading || loadingOrgs || loadingHeartDiseases)
    return <BSpinner text={'Loading patient information...'} />;

  if (error) return <>{commonData.general.errorInAPI}</>;
  // Either patient details - current patient or indications only - new patient
  if (
    (selectedPatientId && patientDetails && patientDetails.patient) ||
    (selectedPatientId == null && patientDetails && patientDetails.indications)
  ) {
    return (
      <>
        <BConfirm
          title={
            messageFormat.patientRegistration.patientProfile
              .confirmUnSavedChanges
          }
          open={unsaveConfirm.open}
          setOpen={setUnsaveConfirm}
          onConfirm={() => {
            gotoHome();
          }}
          severity='warning'
        />
        <Formik
          initialValues={
            selectedPatientId && patientDetails && patientDetails.patient
              ? getInitialProfileValues(patientDetails.patient)
              : getNewProfileValues()
          } // Set initial values based on the new/ existing patient
          validationSchema={PATIENT_REGISTRATION_PROFILE}
          onSubmit={(values, { resetForm }) => {
            if (selectedPatientId) {
              // existing/ updating patient
              values['patientId'] = selectedPatientId;
              updatePatient({ variables: values }).then(
                res => {
                  setSavedChanges({
                    apiCall: true,
                    type: 'success',
                    msg: commonData.general.saveToastMessage,
                  });
                  resetForm({ values: values }); // set the values to initial values with the new saved values and undirty the form
                  props.reflectUpdatedPatientChanges();
                  sendAuditMessage({
                    action: 'DATA_SAVE',
                    phi_flag: true,
                    description: `Saved patient profile ${data?.patient?.first_name} ${data?.patient?.last_name} ${data?.patient?.id}`,
                  });
                },
                error => {
                  console.error(error);
                  setSavedChanges({
                    apiCall: true,
                    type: 'error',
                    msg: commonData.general.saveErrorToastMessage,
                  });
                  sendAuditMessage({
                    action: 'DATA_SAVE_FAILURE',
                    phi_flag: true,
                    description: `Failed to save patient profile ${data?.patient?.first_name} ${data?.patient?.last_name} ${data?.patient?.id}`,
                  });
                }
              );
            } else {
              // new patient
              createPatient({ variables: values }).then(
                res => {
                  setSavedChanges({
                    apiCall: true,
                    type: 'success',
                    msg: commonData.general.saveToastMessage,
                  });
                  // set everything to null as the new patient is created
                  setPatientDetails(null);
                  select({
                    variables: {
                      id: res.data.createPatient.patientId,
                      deviceId: res.data.createPatient.deviceId,
                    },
                  }).then(() => {
                    sendAuditMessage({
                      action: 'DATA_SAVE',
                      phi_flag: true,
                      description: `Created new patient ${values.patient.first_name} ${values.patient.last_name} with patientId ${res.data.createPatient.patientId} and deviceId ${res.data.createPatient.deviceId}`,
                    });
                    navigate(`/patient/${res.data.createPatient.patientId}`);
                  });
                },
                error => {
                  console.error(error);
                  setSavedChanges({
                    apiCall: true,
                    type: 'error',
                    msg: commonData.general.saveErrorToastMessage,
                  });
                  sendAuditMessage({
                    action: 'DATA_SAVE_FAILURE',
                    phi_flag: true,
                    description: `Failed to create new patient ${values.patient.first_name} ${values.patient.last_name} with error ${error}`,
                  });
                }
              );
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            dirty,
            submitForm,
            setFieldValue,
            setStatus,
            validateForm,
            touched,
          }) => (
            <form
              autoComplete='off'
              noValidate
              onSubmit={e => {
                e.preventDefault();
                submitForm();
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={2} style={{ marginTop: '5px' }}>
                    <Grid item md={12} lg={6}>
                      <PatientInformation
                        patient={values.patient}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        organizations={organizations}
                      />
                      <PatientClinicalInformation
                        {...values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        indicationList={
                          (patientDetails && patientDetails.indications) || []
                        }
                        underlyingHeartDiseaseList={
                          dataHeartDiseases.underlyingHeartDiseases || []
                        }
                      />
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <PatientAddress
                        {...values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                      <PatientContact
                        {...values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                      <PatientNotes
                        {...values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={5} md={4}>
                      <span style={{ float: 'left' }}>
                        <span
                          style={{
                            color: commonData.standardColor.bardyRed.color,
                          }}
                        >
                          *
                        </span>{' '}
                        Required field
                      </span>
                    </Grid>
                    <Grid
                      item
                      lg={7}
                      md={8}
                      style={{
                        display: 'inline-flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <BToastMessage
                        id={generateID(
                          'toast',
                          'pr_profile',
                          'notification_msg_down'
                        )}
                        open={savedChanges.apiCall}
                        close={() => {
                          setSavedChanges({
                            apiCall: false,
                            type: '',
                            msg: '',
                          });
                        }}
                        style={{ position: 'inherit', marginRight: '5px' }}
                        severity={savedChanges.type as AlertColor}
                        msg={savedChanges.msg}
                      />
                      {canUpdatePatient ? (
                        <span
                          style={{
                            float: 'right',
                          }}
                        >
                          <BSaveCancel
                            saveId={generateID('btn', 'pr_profile', 'save_btm')}
                            cancelId={generateID(
                              'btn',
                              'pr_profile',
                              'cancel_btm'
                            )}
                            saveDisabled={!dirty}
                            cancelEvent={() => {
                              if (dirty) {
                                setUnsaveConfirm({ open: true });
                              } else {
                                gotoHome();
                              }
                            }}
                          />
                        </span>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </>
    );
  } else {
    return <></>;
  }
};

export default PatientRegistrationProfile;
