import { SvgIcon } from '@mui/material';
let BEOSApproved = props => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.3234 8.76925H20.4926C20.908 8.76925 21.2311 9.09233 21.2311 9.50771V11.8693C20.2499 11.3164 19.1173 11 17.8996 11C14.0177 11 11 14.2157 11 18.0549C11 20.002 11.7762 21.7887 13.0476 23.0769H4.98492C3.78492 23.0769 2.76953 22.0616 2.76953 20.8616V3.13848C2.76953 1.93848 3.78492 0.923096 4.98492 0.923096H12.6465C13.0618 0.923096 13.3849 1.24617 13.3849 1.66156V6.83079C13.3849 8.03079 14.1234 8.76925 15.3234 8.76925ZM16.2003 1.06156L21.0926 5.95386C21.1849 6.04617 21.2311 6.23079 21.2311 6.36925C21.2311 6.64617 21.0003 6.9231 20.7234 6.9231H16.8003C15.9695 6.9231 15.2311 6.18463 15.2311 5.35387V1.43079C15.2311 1.15386 15.508 0.923096 15.7849 0.923096C15.9234 0.923096 16.108 0.96925 16.2003 1.06156Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.8437 16.4211L17.2893 20.8708C17.1107 21.0431 16.813 21.0431 16.6344 20.8708L14.134 18.4306C13.9553 18.2584 13.9553 17.9713 14.134 17.799L14.7888 17.1675C14.9674 16.9952 15.2651 16.9952 15.4437 17.1675L16.7535 18.4593C16.8726 18.5742 17.0809 18.5742 17.2 18.4593L20.534 15.1292C20.7126 14.9569 21.0102 14.9569 21.1888 15.1292L21.8437 15.7608C22.0521 15.933 22.0521 16.2201 21.8437 16.4211Z'
        fill='black'
      />
    </SvgIcon>
  );
};

export default BEOSApproved;
