import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { BrowserUtils } from '@azure/msal-browser';
const Logout = () => {
  const { instance } = useMsal();
  useEffect(() => {
    instance.logoutPopup({
      account: instance.getActiveAccount(),
    });
    window.localStorage.clear();
  }, [instance]);

  return <></>;
};

export default Logout;
