import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { silentRequest } from './auth/msalConfig';
import { useAcquireToken } from './auth/useAcquireToken';
import BSpinner from './components/common/BSpinner';
import AppWrapper from './AppWrapper';
import ErrorProvider from './components/error/ErrorProvider';
import Logout from './components/user/Logout';

const RedirectToLogin = () => {
  const { token, profileId, error } = useAcquireToken();
  const { instance } = useMsal();

  if (token) return <div>TOKEN</div>;
  instance.loginRedirect(silentRequest).catch(e => {
    console.error('Error attempting silent login:', e);
  });

  return <></>;
};

const App = ({ config }) => {
  const { token, profileId, loading } = useAcquireToken();
  const { instance } = useMsal();
  const pathName = window.location.pathname;
  if (pathName === '/logout') {
    // directly start the logout process without getting into authentication template
    return <Logout />;
  } else {
    if (loading) return <BSpinner text={'Logging in...'} />;

    return (
      <div className='App'>
        <AuthenticatedTemplate>
          <ErrorProvider>
            <AppWrapper config={config} token={token} profileId={profileId} />
          </ErrorProvider>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          {/* <h5 className='card-title'>
          Please <button onClick={handleLogin}>sign-in</button> to see your
          profile information.
        </h5> */}
          <RedirectToLogin />
        </UnauthenticatedTemplate>
      </div>
    );
  }
};

export default App;
