import gql from 'graphql-tag';

export const PATIENTS = gql`
  query getPatients {
    patients {
      id
      first_name
      last_name
      date_of_birth
      age
      sex
      pacemaker
      patient_identifier
      physician_id
      email
      phone
      other_phone
      notes
      primary_indication {
        id
        desc
      }
      indications {
        id
        desc
      }
      devices {
        id
        deviceId
        type
        active
        activation_status
        referring_provider
        prescribing_provider
        application
        transmissionGapView {
          days_since_transmission_gap
        }
        deviceEventSettings {
          id
          severity
          deviceEventSettingType {
            name
            abbr
            abbr_type
            display_order
            event_type
          }
        }
        deviceEventGroups(orderByDesc: display_order) {
          id
          start_time
          end_time
          display_order
          events_type
          deviceEvents {
            id
            value
            device_id
            deviceEventSetting {
              severity
              deviceEventSettingThresholds {
                id
                deviceEventSettingThresholdType {
                  name
                  min
                  max
                  unit
                  default_value
                  reference_id
                  display_order
                }
              }
              deviceEventSettingType {
                name
                abbr
                abbr_type
                display_order
                event_type
              }
            }
          }
        }
      }
    }
  }
`;

// add user id once backend is implemented
export const GET_ALL_VIEWS_OF_TYPE = gql`
  query getAllViewsOfType($viewType: String!) {
    views(view_type: $viewType) {
      id
      default
      name
    }
  }
`;

export const GET_VIEW = gql`
  query getView($id: String!) {
    view(id: $id) {
      default
      name
      id
      display_model
      filter_model
      sort_model
    }
  }
`;

// Initial query to get the default view with data
export const PATIENTS_QUERY = gql`
  query defaultAndList(
    $limit: [Int]
    $offset: [Int]
    $filter: FilterObjectType
    $sort: [SortModelInputObjectType]
    $search: SearchListInputType
    $hideTechNotes: Boolean!
  ) {
    paginatedPatients(
      limit: $limit
      offset: $offset
      filter: $filter
      sort: $sort
      search: $search
    ) {
      collection {
        id
        first_name
        last_name
        date_of_birth
        age
        sex
        patient_identifier
        organization {
          name
        }
        history(orderByDesc: pinned_order, pinnedEq: true, typeEq: "note") {
          description @skip(if: $hideTechNotes)
          created_at @skip(if: $hideTechNotes)
        }
        primary_indication {
          id
          desc
        }
        indications {
          id
          desc
        }
        patientNewestDeviceView {
          device_status_with_flags
        }
        devices {
          deviceId
          application
          referring_provider
          prescribing_provider
          id
          active
          activation_status
          deviceStatus {
            battery_level_percentage
            last_transmission_timestamp
          }
          deviceActivations(orderByDesc: timestamp) {
            timestamp
            type
          }
          transmissionGapView {
            days_since_transmission_gap
          }
          deviceEventSettings(
            filter: {
              _and: [
                {
                  columnField: "deviceEventSettingType.abbr"
                  operatorValue: "equals"
                  value: "DATA_TRANSMISSION"
                }
              ]
            }
          ) {
            deviceEventSettingThresholds {
              value
            }
          }
          reports(orderByDesc: created_at) {
            id
            viewed
            file_link
            current_state
            created_at
            updated_at
            reportType {
              name
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const EVENTS_QUERY = gql`
  query eventsdefaultAndList(
    $limit: [Int]
    $offset: [Int]
    $filter: FilterObjectType
    $sort: [SortModelInputObjectType]
    $search: SearchListInputType
    $hideTechNotes: Boolean!
  ) {
    paginatedDeviceEventGroups(
      limit: $limit
      offset: $offset
      filter: $filter
      sort: $sort
      search: $search
    ) {
      collection {
        id
        start_time
        end_time
        events_type
        current_status
        display_order
        created_at

        deviceEvents {
          id
          value
          device_id
          deviceEventSetting {
            severity
            deviceEventSettingThresholds {
              value
            }
            deviceEventSettingType {
              name
              abbr
              abbr_type
              display_order
              event_type
            }
          }
        }
        device {
          id
          activation_status
          deviceStatus {
            battery_level_percentage
            last_transmission_timestamp
          }
          deviceStatusWithFlagsView {
            device_status_with_flags
          }
          transmissionGapView {
            days_since_transmission_gap
          }
          deviceActivations(orderByDesc: timestamp) {
            timestamp
            type
          }
          reports(
            current_stateIn: ["published", "accepted"]
            orderByDesc: created_at
          ) {
            id
            viewed
            file_link
            current_state
            created_at
            updated_at
            reportType {
              name
            }
          }
          deviceEventSettings(severityIn: ["low", "high", "on"]) {
            severity
            deviceEventSettingThresholds {
              value
              deviceEventSettingThresholdType {
                name
                reference_id
                display_order
              }
            }
            deviceEventSettingType {
              name
              abbr
              abbr_type
              display_order
              event_type
            }
          }
          id
          deviceId
          type
          referring_provider
          patients {
            id
            first_name
            last_name
            date_of_birth
            age
            sex
            patient_identifier
            notes
            history(orderByDesc: pinned_order, pinnedEq: true, typeEq: "note") {
              description @skip(if: $hideTechNotes)
              created_at @skip(if: $hideTechNotes)
            }
            primary_indication {
              id
              desc
            }
            indications {
              id
              desc
            }
            organization {
              id
              name
              contact {
                name
                daytime_phone
                after_hours_phone
                daytime_hours_start
                daytime_hours_end
                fax
              }
              providers {
                id
                first_name
                last_name
                email
                phone
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const ADD_TECHNICIAN_NOTES = gql`
  mutation createNote($note: String!, $patientId: String!) {
    createNote(note: $note, patient_id: $patientId) {
      id
      patient_id
      note
      created_at
    }
  }
`;

export const TOGGLE_PINNED_TECHNICIAN_NOTES = gql`
  mutation togglePinned($historyId: String!) {
    togglePinned(patient_history_id: $historyId) {
      id
      pinned_order
    }
  }
`;

export const REVIEW_PATIENT_SUBSCRIPTION = gql`
  subscription OnReviewPatient {
    patientInReview {
      id
      patient_i
      user {
        id
        first_name
        last_name
      }
    }
  }
`;

export const DELETE_PATIENT = gql`
  mutation deletePatient($patientId: String!) {
    deletePatient(patientId: $patientId)
  }
`;

export const EVENT_ADDED_SUBSCRIPTION = gql`
  subscription OnEventsCreated {
    eventsAdded {
      patientId
      events {
        id
        duration
        deviceId
        thresholdValue {
          level
          threshold {
            setting {
              abbr
            }
          }
        }
      }
    }
  }
`;

export const REVIEW_PATIENT = gql`
  mutation reviewPatient($patient_id: String!) {
    reviewPatient(patient_id: $patient_id) {
      first_name
    }
  }
`;

export const UPDATE_VIEW = gql`
  mutation updateView($input: ViewUpdateType) {
    updateView(input: $input)
  }
`;

export const CREATE_VIEW = gql`
  mutation createView($input: ViewInputType) {
    createView(input: $input)
  }
`;

export const DELETE_VIEW = gql`
  mutation deleteView($id: String!) {
    deleteView(viewId: $id)
  }
`;

export const ADD_PATIENT = gql`
  mutation createPatient(
    $patient: CreatePatientInputType
    $address: AddressInputType
    $contact: [ContactInputType]
  ) {
    createPatient(patient: $patient, address: $address, contacts: $contact) {
      patientId
      deviceId
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation updatePatient(
    $patientId: String!
    $patient: CreatePatientInputType
    $address: AddressInputType
    $contact: [ContactInputType]
  ) {
    updatePatient(
      patientId: $patientId
      patient: $patient
      address: $address
      contacts: $contact
    )
  }
`;

export const HEART_DISEASES = gql`
  query underlyingHeartDiseases {
    underlyingHeartDiseases {
      id
      desc
    }
  }
`;

export const ORGANIZATIONS = gql`
  query organizations {
    organizations {
      id
      name
      locations {
        id
        name
      }
    }
  }
`;

export const LOCATIONS = gql`
  query locations($org_id: String!) {
    organizations(id: $org_id) {
      locations {
        id
        name
      }
    }
  }
`;

export const PATIENT = gql`
  query getPatient($patient_id: String!) {
  patient(id: $patient_id) {
    id
    first_name
    last_name
    date_of_birth
    sex
    age
    pacemaker
    patient_identifier
    physician_id
    email
    phone
    phone_type
    prior_ecg
    other_phone
    other_phone_type
      underlying_heart_diseases {
      id
      desc
    }
      location {
      id
      name
    }
      organization {
      id
      name
      timezone
    }
    notes
      address {
      line1
      line2
      city
      state_id
      zip_code
    }
    ecg_analysis_notes
      contacts {
      id
      name
      phone
      relationship
      display_order
    }
    primary_indication_id
    primary_indication {
      id
      desc
    }
      indications {
      id
      code
      desc
    }
  }
    indications {
    id
    code
    desc
  }
    underlyingHeartDiseases {
    id
    desc
  }
}
`;

export const PATIENT_HEADER = gql`
  query getPatientHeader($patient_id: String!) {
  patient(id: $patient_id) {
    id
    first_name
    last_name
    sex
    date_of_birth
    patient_identifier
    primary_indication {
      id
      code
      desc
    }
    notes
      devices {
      type
      active
    }
      organization {
      id
      name
        contact {
        name
        daytime_phone
        after_hours_phone
        daytime_hours_start
        daytime_hours_end
        fax
      }
        providers {
        id
        first_name
        last_name
        email
        phone
      }
    }
      indications {
      id
      code
      desc
    }
    history(orderByDesc: pinned_order, pinnedEq: true, typeEq: "note") {
      description
    }

    insurance{
    id
    }
  }
}
`;

export const SELECT_PATIENT = gql`
  mutation selectPatient(
  $id: String!
    $deviceId: String
    $isDeviceRegistered: Boolean
) {
  selectPatient(
    selectedPatientId: $id
      selectedDeviceId: $deviceId
      isDeviceRegistered: $isDeviceRegistered
  ) @client
}
`;

export const SELECTED_PATIENT_ID = gql`
  query selectedPatientId {
    selectedPatientId @client
}
`;

export const SELECTED_DEVICE_ID = gql`
  query selectedDeviceId {
    selectedDeviceId @client
}
`;

export const SUBSCRIBE_LOCK = gql`
  subscription subscriptionLock($input: ReportLockSubscriptionInputType) {
  reportLockSubscription(input: $input) {
    deviceId
    locked
    username
    timestamp
  }
}
`;

export const GET_REPORT_LOCK_DETAILS = gql`
  mutation getReportLocks($deviceIds: [String]) {
  getReportLocks(deviceIds: $deviceIds) {
    deviceId
    username
    locked
    timestamp
  }
}
`;

export const C_GET_CURRENT_PATIENT = gql`
  query currentPatientDetails{
    currentPatientDetails @client
}
`;
