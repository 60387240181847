import { useContext } from 'react';
import { Grid, TextareaAutosize } from '@mui/material';
import { generateID } from '../../../utility/utilityFunctions';
import BFormGroupTitle from '../../common/BFormGroupTitle';
import { ICONS } from '../../../utility/IconSelection';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';

const PatientNotes = props => {
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const { canUpdatePatient } = USER_ACCESS;

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '22px',
            marginBottom: '20px',
            backgroundColor: 'rgb(238 238 238)',
          }}
        >
          <BFormGroupTitle
            title='Patient Notes'
            icon={ICONS['NOTES']}
          ></BFormGroupTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction='row'>
        <Grid item xs={12}>
          <label>Patient Notes</label>
          <TextareaAutosize
            id={generateID('txtarea', 'pr_profile', 'notes')}
            aria-label='minimum height'
            style={{
              width: '100%',
              fontFamily: [
                'Nunito',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
              ].join(','),
              fontSize: 14,
            }}
            minRows={5}
            name='patient.notes'
            value={props.patient.notes}
            onChange={e => {
              props.setFieldValue('patient.notes', e.target.value);
            }}
            disabled={!canUpdatePatient}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientNotes;
