import React from 'react';
import { SvgIcon } from '@mui/material';
let Transmission = props => {
  return (
    <SvgIcon
      style={props.style}
      height='200px'
      width='200px'
      className={props.className}
      viewBox='0 0 100 100'
      x='0px'
      y='0px'
    >
      <g>
        <path d='M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z'></path>
        <path d='M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z'></path>
        <path d='M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z'></path>
        <path d='M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z'></path>
      </g>
      <g>
        <path d='M51.9,10V5.3c0-2.2-2.4-3.5-4.3-2.4l-16,9.7c-1.8,1.1-1.8,3.7,0,4.8l16,9.7c1.9,1.1,4.3-0.2,4.3-2.4v-5.4     c16,1,28.7,14.2,28.7,30.5c0,7.2-2.5,14.1-7.1,19.6c-0.6,0.7-0.5,1.7,0.1,2.3l4.5,4.2c0.7,0.6,1.8,0.6,2.4-0.1     c6.2-7.2,9.5-16.4,9.5-26C90.1,28.4,73.2,11,51.9,10z'></path>
        <path d='M52.3,72.8c-1.9-1.1-4.3,0.2-4.3,2.4v5.4c-16-1-28.7-14.2-28.7-30.5c0-7.2,2.5-14.1,7.1-19.6c0.6-0.7,0.5-1.7-0.1-2.3     L21.8,24c-0.7-0.6-1.8-0.6-2.4,0.1c-6.2,7.2-9.5,16.4-9.5,26c0,21.5,17,38.9,38.2,39.9v4.7c0,2.2,2.4,3.5,4.3,2.4l16-9.7     c1.8-1.1,1.8-3.7,0-4.8L52.3,72.8z'></path>
      </g>
    </SvgIcon>
  );
};

export default Transmission;
