import { SvgIcon } from '@mui/material';
let BEOSPublished = props => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.4921 8.76923H15.3229C14.1229 8.76923 13.3844 8.03077 13.3844 6.83077V1.66154C13.3844 1.24616 13.0614 0.92308 12.646 0.92308H4.98443C3.78443 0.92308 2.76904 1.93847 2.76904 3.13847V20.8615C2.76904 22.0615 3.78443 23.0769 4.98443 23.0769H19.0152C20.2152 23.0769 21.2306 22.0615 21.2306 20.8615V9.5077C21.2306 9.09231 20.9075 8.76923 20.4921 8.76923ZM21.0921 5.95385L16.1998 1.06154C16.1075 0.969234 15.9229 0.92308 15.7844 0.92308C15.5075 0.92308 15.2306 1.15385 15.2306 1.43077V5.35385C15.2306 6.18462 15.969 6.92308 16.7998 6.92308H20.7229C20.9998 6.92308 21.2306 6.64616 21.2306 6.36923C21.2306 6.23077 21.1844 6.04616 21.0921 5.95385Z'
        fill='black'
      />
    </SvgIcon>
  );
};

export default BEOSPublished;
