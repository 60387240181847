import { useContext } from 'react';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { theme } from './styles/Theme';
import NavigationComponent from './routes/NavigationComponent';
import RouteListComponent from './routes/RouteListComponent';
import FooterComponent from './routes/FooterComponent';
import { AccessPermissionsContext } from './auth/useAccessPermissions';
import BSpinner from './components/common/BSpinner';
import ErrorContainer from './components/error/ErrorContainer';

// TODO rename this component
function AfterAuth() {
  const { loading, role } = useContext(AccessPermissionsContext);

  if (loading) return <BSpinner text={'Loading User Permissions...'} />;
  if (!role) return <BSpinner text={'Loading User Role...'} />;
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <NavigationComponent />
        <ErrorContainer />
        <RouteListComponent />
        <FooterComponent />
      </Router>
    </ThemeProvider>
  );
}

export default AfterAuth;
