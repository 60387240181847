import React from 'react';
import { SvgIcon } from '@mui/material';
let ECG_Viewer = props => {
  return (
    <SvgIcon
      style={props.style}
      height='200px'
      width='200px'
      className={props.className}
      viewBox='0 0 24 24'
      x='0px'
      y='0px'
    >
      <g>
        <path d='M5.106,6.296c0.073-0.176,0.26-0.337,0.437-0.283C5.733,6.017,5.9,6.14,5.963,6.319l1.583,4.505l1.079-7.747   c0.031-0.225,0.223-0.394,0.45-0.397c0.259,0.037,0.422,0.163,0.459,0.387l0.959,5.964l0.509-1.96   c0.047-0.183,0.201-0.317,0.388-0.34c0.19-0.033,0.37,0.07,0.46,0.236l0.922,1.687l4.086-0.022c0.001-0.04,0.006-0.078,0.006-0.118   c0-2.232-0.869-4.33-2.447-5.907C12.838,1.029,10.74,0.16,8.508,0.16S4.178,1.029,2.6,2.607C1.022,4.184,0.153,6.282,0.153,8.514   c0,0.091,0.01,0.179,0.013,0.269h3.905L5.106,6.296z'></path>
        <path d='M23.408,20.808l-5.41-5.409c-0.283-0.284-0.662-0.44-1.066-0.44c-0.203,0-0.4,0.04-0.581,0.115l-1.347-1.347   c0.971-1.209,1.598-2.637,1.788-4.175l-4.29,0.023c-0.001,0-0.002,0-0.002,0c-0.168,0-0.323-0.092-0.404-0.24L11.6,8.426   l-0.767,2.957c-0.055,0.21-0.26,0.326-0.467,0.343c-0.217-0.01-0.397-0.172-0.432-0.386L9.113,6.237l-0.918,6.588   c-0.029,0.211-0.198,0.374-0.409,0.395c-0.016,0.001-0.031,0.002-0.046,0.002c-0.194,0-0.368-0.122-0.434-0.307l-1.813-5.16   L4.802,9.42C4.731,9.591,4.563,9.703,4.378,9.703H0.246c0.252,1.78,1.06,3.425,2.354,4.718C4.178,16,6.276,16.869,8.508,16.869   c1.926,0,3.75-0.65,5.229-1.842l1.332,1.332c-0.227,0.547-0.122,1.201,0.323,1.646l5.41,5.409c0.567,0.569,1.567,0.569,2.133,0   l0.474-0.473c0.283-0.283,0.439-0.662,0.439-1.066C23.847,21.47,23.691,21.091,23.408,20.808z'></path>
      </g>
    </SvgIcon>
  );
};

export default ECG_Viewer;
