import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from "jwt-decode";
import { silentRequest, loginRequest } from '../auth/msalConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

/**
* Custom React hook for acquiring access tokens acquireTokenSilent API of MSAL
* @param scopes list of scopes requested for granular permissions.
* @returns [token, error, loading] access token, error details, or boolean to determine if the asynchronous acquisition is still loading.
*/
export function useAcquireToken() {
  const { instance, accounts } = useMsal();

  const [token, setToken] = useState(null);
  const [profileId, setProfileId] = useState(null)

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const redirectLogin = async () => {
    try {
      const response: any = await instance.loginRedirect({
        scopes: loginRequest.scopes
      })

      // Parse JWT
      // TODO - consider using npm library "jsonwebtoken" to decode -
      //  there seemed to be issues with polyfilled dependencies
      const jwt: any = jwtDecode(response.accessToken)
      setProfileId(jwt?.extension_f_uid || null)

      setToken(response.accessToken);
      setError(false);
      return (response as any).accessToken;
    } catch (e) {

      console.error('Error in redirectLogin:', e);
      setError(e)
    } finally {
      setLoading(false);
    }
  };

  const acquireTokenSilent = async () => {
    try {
      // TODO handle no accounts
      const response = await instance.acquireTokenSilent({
        scopes: silentRequest.scopes,
        account: accounts[0]
      });

      const jwt: any = jwtDecode(response.accessToken)
      setProfileId(jwt?.extension_f_uid || null)

      setToken(response.accessToken);
      setError(false);
    } catch (e) {
      console.error(e);
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect({
          scopes: silentRequest.scopes,
          account: accounts[0]
        })
      }
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // First try silent login,
    // If silent login fails, fall back to redirect login
    if (instance && accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
      acquireTokenSilent();
    } else {
      redirectLogin()
    }
  }, [accounts, instance]);
  return { token, profileId, error, loading };
}