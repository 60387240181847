import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSendAuditTrailLogMessage } from '../../data/queries/auditTrailLogMessage';
import { AccessPermissionsContext } from '../../auth/useAccessPermissions';
import { generateID } from '../../utility/utilityFunctions';
import About from './About';

const REVERSE_PROFILE_MAP = {
  clinician: 'Clinician',
  system_admin: 'System Admin',
  org_admin: 'Organization Administrator',
  qc_user: 'QC User',
  mctECGTech: 'ECG Tech User',
  distributor_admin: 'Distributor Administrator',
};
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function UserProfileMenu({ instance }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openAboutDialog, setOpenAboutDialog] = React.useState(false);
  const open = Boolean(anchorEl);
  const { sendAuditMessage } = useSendAuditTrailLogMessage();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { email, name, role, organization } = React.useContext(
    AccessPermissionsContext
  );

  const handleAboutVisibility = open => {
    setOpenAboutDialog(open);
  };

  return (
    <div>
      <About
        handleAboutVisibility={handleAboutVisibility}
        openAboutDialog={openAboutDialog} // todo check why cant be passed as such
      ></About>
      <Button
        style={{
          background: 'white',
          color: 'black',
          border: '#154898 solid 2px',
          borderRadius: '10px',
        }}
        id={generateID('navbtn', 'usermenu', 'nav')}
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='outlined'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {name}
      </Button>
      <StyledMenu
        id='profile-menu'
        MenuListProps={{
          'aria-labelledby': 'profile-menu-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          style={{ fontWeight: 'bolder' }}
          onClick={handleClose}
          disableRipple
        >
          {email}
        </MenuItem>
        <MenuItem
          style={{ fontSize: '14px', paddingTop: '0px' }}
          onClick={handleClose}
          disableRipple
        >
          {REVERSE_PROFILE_MAP[role]}
        </MenuItem>
        <MenuItem
          style={{ fontSize: '14px', paddingTop: '0px' }}
          onClick={handleClose}
          disableRipple
        >
          {organization}
        </MenuItem>
        <Divider sx={{ my: 1.0 }} />
        <MenuItem
          style={{ fontSize: '14px', paddingTop: '0px' }}
          onClick={() => {
            //open the about window
            handleAboutVisibility(true);
          }}
          disableRipple
        >
          About CAM DxT
        </MenuItem>

        <MenuItem
          onClick={() => {
            const name = instance.getActiveAccount().name;
            const account = instance.getActiveAccount();

            instance.logoutRedirect({
              account,
              postLogoutRedirectUri:
                process.env.REACT_APP_WEB_LOGOUT_REDIRECT_URI,
            });
            sendAuditMessage({
              action: 'LOGOUT',
              phi_flag: true,
              description: `User ${name} logout`,
            });
          }}
          disableRipple
        >
          Sign Out
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
