import React from 'react';
import { SvgIcon } from '@mui/material';
let BIConDevice = props => {
  return (
    <SvgIcon
      style={props.style}
      className={props.className}
      viewBox='0 0 46 46'
    >
      <path
        className={props.className}
        d='M23,0A23,23,0,1,0,46,23,23,23,0,0,0,23,0ZM37.77,24.09H32.44a1,1,0,0,1-1-.77l-.48-2-.58,2.07a1,1,0,0,1-1,.73H25.85l-1.29,9.84a1,1,0,0,1-1,.87h0a1,1,0,0,1-1-.86L20.35,17.38l-1.24,4.77a1,1,0,0,1-.95.75,1,1,0,0,1-1-.71l-1-3.35-1.58,4.57a1,1,0,0,1-.94.68H8.23a1,1,0,0,1,0-2H13l2.36-6.84a1,1,0,0,1,1-.67,1,1,0,0,1,.94.71l.87,2.9L19.7,12a1,1,0,0,1,1-.75,1,1,0,0,1,.93.86l1.9,14.15L24,23a1,1,0,0,1,1-.87h3.71L30.12,17a1.05,1.05,0,0,1,1-.73,1,1,0,0,1,.95.77l1.19,5h4.53a1,1,0,0,1,0,2Z'
      />
    </SvgIcon>
  );
};

export default BIConDevice;
