import { SvgIcon } from '@mui/material';
const BIconDevice = ({ style, className }) => (
  <SvgIcon style={style} className={className}>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M0.921875 11.9998C0.921875 5.90747 5.90649 0.922852 11.9988 0.922852C18.0911 0.922852 23.0757 5.90747 23.0757 11.9998C23.0757 18.0921 18.0911 23.0767 11.9988 23.0767C5.90649 23.0767 0.921875 18.0921 0.921875 11.9998ZM12.001 17.9998C12.1972 17.9082 17.002 15.6676 17.4597 11.7311C17.8217 8.61246 15.663 7.86544 14.3109 8.03194C13.7704 8.0681 13.2553 8.27129 12.8396 8.61242C12.4238 8.95354 12.1287 9.41511 11.9964 9.931C11.8679 9.41604 11.5772 8.954 11.1658 8.61062C10.7543 8.26724 10.2431 8.06002 9.70474 8.01844C8.32975 7.86544 6.17102 8.61246 6.54227 11.7311C6.99513 15.6655 11.7989 17.9055 11.9998 17.9992L12.001 17.9998Z'
      fill='currentColor'
    />
  </SvgIcon>
);

export default BIconDevice;
