import { SvgIcon } from '@mui/material';
let BEOSDue = props => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='icon'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.4926 8.76901H15.3234C14.1234 8.76901 13.3849 8.03054 13.3849 6.83054V1.66131C13.3849 1.24593 13.0618 0.922852 12.6465 0.922852H4.98492C3.78492 0.922852 2.76953 1.93824 2.76953 3.13824V20.8613C2.76953 22.0613 3.78492 23.0767 4.98492 23.0767H19.0157C20.2157 23.0767 21.2311 22.0613 21.2311 20.8613V9.50747C21.2311 9.09208 20.908 8.76901 20.4926 8.76901ZM21.0926 5.95362L16.2003 1.06131C16.108 0.969005 15.9234 0.922852 15.7849 0.922852C15.508 0.922852 15.2311 1.15362 15.2311 1.43054V5.35362C15.2311 6.18439 15.9695 6.92285 16.8003 6.92285H20.7234C21.0003 6.92285 21.2311 6.64593 21.2311 6.36901C21.2311 6.23054 21.1849 6.04593 21.0926 5.95362Z'
        fill='black'
      />
      <rect
        id='Rectangle 894'
        x='12'
        y='12'
        width='14'
        height='14'
        rx='7'
        fill='#F5F5F5'
      />
      <path
        id='icon_2'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 19.5556C24 19.8333 23.7222 20.1111 23.4444 20.1111H20.1111V23.4444C20.1111 23.7222 19.8333 24 19.5556 24H18.4444C18.1667 24 17.8889 23.7222 17.8889 23.4444V20.1111H14.5556C14.2778 20.1111 14 19.8333 14 19.5556V18.4444C14 18.1667 14.2778 17.8889 14.5556 17.8889H17.8889V14.5556C17.8889 14.2778 18.1667 14 18.4444 14H19.5556C19.8333 14 20.1111 14.2778 20.1111 14.5556V17.8889H23.4444C23.7222 17.8889 24 18.1667 24 18.4444V19.5556Z'
        fill='black'
      />
    </SvgIcon>
  );
};

export default BEOSDue;
