import { isEmpty } from 'lodash';
import BToastMessage from '../common/ToastMessage';
import BSaveCancel from '../common/BSaveCancel';
import { generateID } from '../../utility/utilityFunctions';
import { Grid } from '@mui/material';
import { IDirtyField } from '../../types/types';

const AdminSettingsFooter = ({
  savedChanges,
  setSavedChanges,
  changedEvents,
  dirtyFields,
  values,
  dirty,
  errors,
  setSettingUpdatesConfirm,
  setUnsaveConfirm,
  isDefault = false,
}) => {
  const getChangedEventAbbr = (dirtyFields: { [id: string]: IDirtyField }) => {
    const unique_fields_set = new Set(
      Object.values(dirtyFields).map(field => field.setting_abbr)
    );
    const unique_fields = Array.from(unique_fields_set);

    return unique_fields.join(', ');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <BToastMessage
              id={generateID('toast', 'pr_settings', 'notification_msg_down')}
              open={savedChanges.apiCall}
              close={() => {
                setSavedChanges({ apiCall: false, type: '', msg: '' });
              }}
              style={{ position: 'inherit', marginRight: '5px' }}
              severity={savedChanges.type}
              msg={savedChanges.msg}
            />

            <BToastMessage
              id={generateID('toast', 'pr_settings', 'msg_changedevents_down')}
              open={dirty}
              style={{ position: 'inherit', marginRight: '5px' }}
              severity='info'
              title='Info!'
              msg={getChangedEventAbbr(dirtyFields)}
            />
            <span>
              <BSaveCancel
                saveDisabled={!dirty || !isEmpty(errors) || isDefault}
                saveId={generateID('btn', 'pr_settings', 'save_btm')}
                cancelId={generateID('btn', 'pr_settings', 'cancel_btm')}
                saveEvent={e => {
                  e.preventDefault();
                  setSettingUpdatesConfirm({
                    open: true,
                    data: [],
                  });
                }}
                cancelEvent={() => {
                  if (dirty) {
                    setUnsaveConfirm({ open: true });
                  }
                }}
              />
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminSettingsFooter;
