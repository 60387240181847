import { ApolloProvider, ServerParseError } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { createApolloClient } from './data/Client';
import AfterAuth from './AfterAuth';
import AccessPermissionsProvider from './auth/useAccessPermissions';
import { useContext, useMemo } from 'react';
import { ErrorProviderContext } from './components/error/ErrorProvider';

const AppWrapper = ({ config, profileId, token }) => {
  const { addError, setLatestFetchVariables } = useContext(ErrorProviderContext);
  const { instance, accounts } = useMsal();

  // useMemo is important so errors don't reinstantiate this client
  const client = useMemo(() => {
    return createApolloClient(
      config.apiEndpointUrl,
      { instance, accounts },
      config.NODE_ENV,
      addError,
      setLatestFetchVariables
    );
  }, []);

  if (!token) return null;

  return (
    <ApolloProvider client={client}>
      <AccessPermissionsProvider profileId={profileId} token={token}>
        <AfterAuth />
      </AccessPermissionsProvider>
    </ApolloProvider>
  );
};

export default AppWrapper;
