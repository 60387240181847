import { BSpinnerReports } from '../../images/icons';

interface IProps {
  text?: string;
}
const BSpinner = ({ text }: IProps) => {
  return (
    <div
      className='spinner-wrapper'
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {text && <p>{text}</p>}
      <BSpinnerReports
        sx={{
          width: '70px !important',
          height: '70px !important',
          color: '#154898',
        }}
      />
    </div>
  );
};

export default BSpinner;
