import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import BStyledTableRow from '../../common/BStyledTableRow';

const HistoryMoreInfo = (props: any) => {
  return (
    <>
      <Typography>
        <TableContainer
          component={Paper}
          style={{ minWidth: '500px', borderCollapse: 'inherit' }}
        >
          <Table size='small'>
            <colgroup>
              <col width='40%' />
              <col width='30%' />
              <col width='30%' />
            </colgroup>
            <TableHead>
              <tr className='notification-header'>
                <TableCell>Field</TableCell>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
              </tr>
            </TableHead>
            <TableBody>
              {props.historyItem.changes.map((item, i) => {
                return (
                  <BStyledTableRow key={i}>
                    <TableCell>{item[0]}</TableCell>
                    <TableCell>
                      {typeof item[1] == 'boolean'
                        ? item[1].toString()
                        : item[1]}
                    </TableCell>
                    <TableCell>
                      {typeof item[2] == 'boolean'
                        ? item[2].toString()
                        : item[2]}
                    </TableCell>
                  </BStyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Typography>
    </>
  );
};

export default HistoryMoreInfo;
