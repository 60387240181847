import React from 'react';
import { SvgIcon } from '@mui/material';
const BClinicalNotes = props => {
  return (
    <SvgIcon
      style={props.style}
      className={props.className}
      viewBox='0 0 46 46'
    >
      <g>
        <path
          className={props.className}
          d='M13.38,41.38l-8.76-8.76c-0.2-0.2-0.2-0.51,0-0.71L27.28,9.26c0.2-0.2,0.51-0.2,0.71,0l8.76,8.76
		c0.2,0.2,0.2,0.51,0,0.71L14.08,41.38C13.89,41.58,13.57,41.58,13.38,41.38z'
        />
        <path
          className={props.className}
          d='M38.26,16.5L29.5,7.74c-0.2-0.2-0.2-0.51,0-0.71l1.7-1.7c0.2-0.2,0.51-0.2,0.71,0l8.76,8.76
		c0.2,0.2,0.2,0.51,0,0.71l-1.7,1.7C38.77,16.7,38.45,16.7,38.26,16.5z'
        />
        <path
          className={props.className}
          d='M42.11,12.65l-8.76-8.76c-0.2-0.2-0.2-0.51,0-0.71l2-2c1.54-1.54,4.04-1.54,5.58,0l3.89,3.89
		c1.54,1.54,1.54,4.04,0,5.58l-2,2C42.63,12.84,42.31,12.84,42.11,12.65z'
        />
        <path
          className={props.className}
          d='M1.28,45.94l10.28-2.75c0.37-0.1,0.5-0.57,0.23-0.84l-8.13-8.13c-0.27-0.27-0.74-0.15-0.84,0.23L0.06,44.72
		C-0.14,45.46,0.54,46.14,1.28,45.94z'
        />
      </g>
    </SvgIcon>
  );
};

export default BClinicalNotes;
