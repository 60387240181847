import React from 'react';
import { SvgIcon } from '@mui/material';
let Battery = props => {
  return (
    <SvgIcon
      style={props.style}
      height='100px'
      width='100px'
      className={props.className}
      viewBox='0 0 100 100'
      x='0px'
      y='0px'
    >
      <g>
        <g>
          <g>
            <path d='M70.3,11.3h-9.6v-7c0-1-0.8-1.7-1.7-1.7H41.1c-1,0-1.7,0.8-1.7,1.7v7h-9.6c-4.5,0-8.1,3.6-8.1,8.1v70     c0,4.5,3.6,8.1,8.1,8.1h40.5c4.5,0,8.1-3.6,8.1-8.1v-70C78.4,14.9,74.8,11.3,70.3,11.3z M70.8,89.4c0,0.3-0.2,0.5-0.5,0.5H29.7     c-0.3,0-0.5-0.2-0.5-0.5v-70c0-0.3,0.2-0.5,0.5-0.5h40.5c0.3,0,0.5,0.2,0.5,0.5V89.4z'></path>
            <path d='M65.3,56.7H34.7c-0.5,0-0.9,0.4-0.9,0.9v26.9c0,0.5,0.4,0.9,0.9,0.9h30.7c0.5,0,0.9-0.4,0.9-0.9V57.6     C66.2,57.1,65.8,56.7,65.3,56.7z'></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Battery;
