import { useState, useContext, ReactElement } from 'react';
import {
  Typography,
  Grid,
  Tooltip,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { generateID } from '../../../utility/utilityFunctions';
import { styled } from '@mui/system';
import { Close } from '@mui/icons-material';
import { getOrgContactDetails } from '../../../utility/helper/patientlist/logicPatientList';
import OrgContactInformation from '../../organization/OrgContactInformation';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';
import BIcon from '../../common/BIcon';
import { ICONS } from '../../../utility/IconSelection';
import { getPatientIndications } from '../../../utility/utilityFunctions';

// TODO type this properly - IPatientDetails ?
interface IProps {
  patientDetails: any;
  children?: ReactElement;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const PatientRegistrationHeader = ({ patientDetails, children }: IProps) => {
  const [open, setOpen] = useState(false);
  const patient = patientDetails?.patient;
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const { canAccessTechNotes } = USER_ACCESS;

  if (!patient) return null;

  const getPatientAge = dob => {
    const birthday = new Date(dob);
    const age_in_milliseconds = new Date().getTime() - birthday.getTime();
    return Math.floor(age_in_milliseconds / (1000 * 60 * 60 * 24 * 365));
  };

  const getPatientNotes = patient => {
    return patient.history.map(item => item.description.text).join(', ');
  };

  const patientIndications = getPatientIndications(patient);
  const patientNotes = getPatientNotes(patient);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ContactInfoDialog = () => (
    <BootstrapDialog
      onClose={handleClose}
      open={open}
      style={{ minWidth: '800px' }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>Contact information</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <OrgContactInformation
          sideBySide={false}
          {...getOrgContactDetails(patientDetails?.patient?.organization)}
        />
      </DialogContent>
    </BootstrapDialog>
  );

  return (
    <>
      <ContactInfoDialog />
      <div className='patient-header'>
        <Grid
          container
          style={{ padding: '25px', justifyContent: 'space-between' }}
        >
          <Grid item>
            <div
              className='flex'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Typography
                id={generateID('txt', 'prheader', 'patient_details')}
                noWrap
                style={{ fontWeight: 'bold', fontSize: '1.5em' }}
              >
                {patient.last_name}, {patient.first_name}
              </Typography>
              <Tooltip disableFocusListener title={patientIndications} arrow>
                <Typography
                  id={generateID('txt', 'prheader', 'patient_indications')}
                  noWrap
                >
                  &nbsp;|&nbsp;{patientIndications} &nbsp;
                </Typography>
              </Tooltip>
            </div>
            <div className='flex' style={{ display: 'flex' }}>
              <Typography
                id={generateID('txt', 'prheader', 'patient_identifier')}
                noWrap
                sx={{ lineHeight: 2.5 }}
              >
                {patient.patient_identifier}&nbsp;|&nbsp;
              </Typography>
              <Typography
                id={generateID('txt', 'prheader', 'patient_age')}
                noWrap
                sx={{ lineHeight: 2.5 }}
              >
                {getPatientAge(patient.date_of_birth)}:
                {patient.sex[0].toUpperCase()}&nbsp;|&nbsp;
              </Typography>
              <Grid
                item
                style={{
                  textDecoration: 'underline',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={handleClickOpen}
                className='org-contact-link'
              >
                Clinical Contact
              </Grid>
            </div>
          </Grid>
          {canAccessTechNotes && patientNotes ? (
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
              }}
            >
              <BIcon icon={ICONS['note']} />
              <Tooltip disableFocusListener title={patientNotes} arrow>
                <Typography
                  id={generateID('txt', 'prheader', 'clinical_notes')}
                  noWrap
                >
                  {patientNotes}
                </Typography>
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
        {children}
      </div>
    </>
  );
};
export default PatientRegistrationHeader;
