import React from 'react';
const BIcon = props => {
  const IconComponent = props.icon;
  return (
    <span title={props.title}>
      <IconComponent
        id={props.id}
        style={Object.assign(
          {
            marginRight: '5px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          props.style
        )}
        className={props.class}
        onClick={props.onClick}
      />
    </span>
  );
};

export default BIcon;
