import React from 'react';
import { SvgIcon } from '@mui/material';
let TestReport = props => {
  return (
    <SvgIcon viewBox='0 0 46 64' style={Object.assign(props.style, {})}>
      {/* <path className={props.className} d="M380,60.26a9.36,9.36,0,0,0-6.64-2.76H167.5a89.11,89.11,0,0,0-89,89v453a89,89,0,0,0,89,89h277a89,89,0,0,0,89-89V297.2h0V217.67a9.43,9.43,0,0,0-2.76-6.67ZM394.9,158.6l37.5,37.5H394.9Zm49.6,470.9h-277a30,30,0,0,1-30-30v-453a30,30,0,0,1,30-30H335.9V247.17a7.93,7.93,0,0,0,7.93,7.93H474.5V599.5A30,30,0,0,1,444.5,629.5Z" transform="translate(-78.5 -57.5)" />
            <path className={props.className} d="M256,311.33c-29.33-3.33-75.75,13.11-68,81.34C198,480.67,306,530,306,530h0V353.34h0S299.06,316.23,256,311.33Z" transform="translate(-78.5 -57.5)" />
            <path className={props.className} d="M356,311.33c-43.05,4.9-50,42-50,42V530s108-49.33,118-137.33C431.75,324.44,385.34,308,356,311.33Z" transform="translate(-78.5 -57.5)" /> */}
      <g>
        <path
          className={props.className}
          d='M30.48,0.28C30.3,0.1,30.06,0,29.81,0L9,0C4.04,0,0,4.05,0,9.03v45.95C0,59.96,4.03,64,9,64h28c4.97,0,9-4.04,9-9.03V24.31   l0,0l0-8.07c0-0.25-0.1-0.5-0.28-0.68L30.48,0.28z M31.99,10.25l3.79,3.8h-3.79V10.25z M37,58.02H9c-1.68,0-3.03-1.36-3.03-3.04   V9.03c0-1.68,1.36-3.04,3.03-3.04h17.03v13.25c0,0.44,0.36,0.8,0.8,0.8h13.21v34.93C40.03,56.65,38.68,58.02,37,58.02z'
        />
      </g>
      <g>
        <path
          className={props.className}
          d='M15.66,30.59c-0.44-0.13-1.32-0.19-2.7-0.19h-3.8v12.67h2.71v-4.59h1.19c1.01,0,1.8-0.06,2.35-0.18   c0.42-0.1,0.84-0.31,1.24-0.62c0.41-0.31,0.74-0.75,1-1.29c0.26-0.54,0.39-1.21,0.39-1.99c0-1.02-0.23-1.86-0.68-2.51   C16.92,31.23,16.34,30.79,15.66,30.59z M15.27,34.43c0,0.31-0.07,0.59-0.21,0.81c-0.14,0.23-0.33,0.39-0.58,0.5   c-0.18,0.08-0.61,0.17-1.65,0.17h-0.96v-2.95h0.82c0.97,0,1.37,0.04,1.54,0.08c0.3,0.06,0.55,0.21,0.74,0.45   C15.17,33.73,15.27,34.04,15.27,34.43z'
        />
        <path
          className={props.className}
          d='M26.7,31.85c-0.47-0.57-1.03-0.97-1.69-1.2c-0.49-0.17-1.17-0.25-2.07-0.25h-4.11v12.67h4.23c0.81,0,1.46-0.09,1.96-0.27   c0.66-0.25,1.19-0.6,1.58-1.04c0.51-0.57,0.9-1.33,1.17-2.25c0.22-0.75,0.33-1.64,0.33-2.66c0-1.16-0.12-2.15-0.35-2.95   C27.51,33.1,27.15,32.4,26.7,31.85z M25.41,36.74c0,0.92-0.07,1.64-0.21,2.17c-0.13,0.5-0.3,0.86-0.49,1.07   c-0.2,0.21-0.44,0.35-0.74,0.44c-0.24,0.07-0.64,0.11-1.21,0.11h-1.33v-7.56h0.7c0.84,0,1.4,0.04,1.67,0.11   c0.35,0.09,0.63,0.25,0.85,0.5c0.23,0.25,0.41,0.61,0.54,1.08C25.34,35.12,25.41,35.83,25.41,36.74z'
        />
        <polygon
          className={props.className}
          points='36.84,32.96 36.84,30.4 29.17,30.4 29.17,43.07 31.78,43.07 31.78,37.9 36.15,37.9 36.15,35.34 31.78,35.34    31.78,32.96  '
        />
      </g>
    </SvgIcon>
  );
};

export default TestReport;
