import { SvgIcon } from '@mui/material';
let BEOSApproved = props => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.3695 9.0923H2.63104C2.21566 9.0923 1.89258 9.41538 1.89258 9.83076V20.6769C1.89258 21.8769 2.86181 22.8461 4.06181 22.8461H19.9387C21.1387 22.8461 22.108 21.8769 22.108 20.6769V9.83076C22.108 9.41538 21.7849 9.0923 21.3695 9.0923ZM8.40027 19.2C8.40027 19.6154 8.07719 19.9385 7.66181 19.9385H6.23104C5.81566 19.9385 5.49258 19.6154 5.49258 19.2V17.7692C5.49258 17.3538 5.81566 17.0308 6.23104 17.0308H7.66181C8.07719 17.0308 8.40027 17.3538 8.40027 17.7692V19.2ZM13.431 19.2C13.431 19.6154 13.108 19.9385 12.6926 19.9385H11.2618C10.8464 19.9385 10.5233 19.6154 10.5233 19.2V17.7692C10.5233 17.3538 10.8464 17.0308 11.2618 17.0308H12.6926C13.108 17.0308 13.431 17.3538 13.431 17.7692V19.2ZM13.431 14.1692C13.431 14.5846 13.108 14.9077 12.6926 14.9077H11.2618C10.8464 14.9077 10.5233 14.5846 10.5233 14.1692V12.7385C10.5233 12.3231 10.8464 12 11.2618 12H12.6926C13.108 12 13.431 12.3231 13.431 12.7385V14.1692ZM18.508 14.1692C18.508 14.5846 18.1849 14.9077 17.7695 14.9077H16.3387C15.9233 14.9077 15.6003 14.5846 15.6003 14.1692V12.7385C15.6003 12.3231 15.9233 12 16.3387 12H17.7695C18.1849 12 18.508 12.3231 18.508 12.7385V14.1692ZM19.9387 3.32307H18.1387V2.58461C18.1387 1.79999 17.4926 1.15384 16.708 1.15384C15.9233 1.15384 15.2772 1.79999 15.2772 2.58461V3.32307H8.7695V2.58461C8.7695 1.79999 8.12335 1.15384 7.33873 1.15384C6.55412 1.15384 5.90796 1.79999 5.90796 2.58461V3.32307H4.06181C2.86181 3.32307 1.89258 4.2923 1.89258 5.4923V6.23076C1.89258 6.64615 2.21566 6.96922 2.63104 6.96922H21.4157C21.831 6.96922 22.1541 6.64615 22.1541 6.23076V5.4923C22.108 4.2923 21.1387 3.32307 19.9387 3.32307Z'
        fill='#EB4940'
      />
    </SvgIcon>
  );
};

export default BEOSApproved;
