// External Libraries/components
import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { Grid, TextareaAutosize } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Internal Libraries/components
import { commonData } from './../../data/CommonData';
import BConfirm from '../common/BConfirm';
import SavedSettingGroups from './SavedSettingGroups';
import AdminSettingsFooter from './AdminSettingsFooter';
import ThresholdSettingsRow from './ThresholdSettingsRow';
import { generateID } from '../../utility/utilityFunctions';
import {
  getInitialAndValidationAdmin,
  shapeValuesForMutation,
} from '../../utility/helper/settings/SettingOperations';
import {
  THRESHOLD_ADMIN_SETTINGS,
  ADMIN_SETTINGS,
  UPDATE_ORG_SETTINGS,
} from '../../data/queries/setting';
import messageFormat from '../../utility/messageToUser.json';
import {
  DEVICE_TYPE_MCT,
  THRESHOLD_SETTING_HEADER_OPTIONS,
  ISetting,
  ISettingGroup,
  IDirtyField,
  IDropdownItem,
} from '../../types/types';
import { useSendAuditTrailLogMessage } from '../../data/queries/auditTrailLogMessage';

type IProps = {
  orgId: string | null;
  orgName: string | null;
};

const AdminSettings = ({ orgId, orgName }: IProps) => {
  const { sendAuditMessage } = useSendAuditTrailLogMessage();
  const theme = useTheme();
  // create parameter for fetching the organization threshold settings -
  // This grabs all of the Settings Groups for an organization (and their respective threshold settings)
  // as well as the global default Settings Group settings/thresholds, and puts them all into one list
  // so that they can be put into the dropdown together
  const getVariableForOrgThreshold = id => {
    var varOrgThreshold = [
      {
        fieldName: 'global_default',
        value: 'true',
      },
      {
        fieldName: 'organization_id',
        value: id,
      },
    ];
    return JSON.stringify(varOrgThreshold);
  };
  const THRESHOLD_SETTINGS_HEADER = [
    THRESHOLD_SETTING_HEADER_OPTIONS['event'],
    THRESHOLD_SETTING_HEADER_OPTIONS['notification_limit'],
    THRESHOLD_SETTING_HEADER_OPTIONS['notification_buttons'],
  ];

  const sortSettingsByDisplayOrder = (
    settingsArray: ISetting[]
  ): ISetting[] => {
    if (!settingsArray) return [];
    let sortArray = [...settingsArray];
    let sortedThresholds = [];
    // sort by setting events
    sortArray.sort(
      (a, b) => a.settingType.display_order - b.settingType.display_order
    );

    // sort by thresholds within setting events
    sortedThresholds = sortArray.map(item => {
      let thresholds = [...item.thresholds]; // Assigning to thresholds and then perform sort on it since item is immutable
      thresholds = thresholds.sort(
        (a, b) => a.thresholdType.display_order - b.thresholdType.display_order
      );
      return {
        ...item,
        thresholds: thresholds,
      };
    });
    return sortedThresholds;
  };

  // queries
  const {
    loading: loadingOrgSettings,
    error: errorOrgSettings,
    data: dataOrgSettings,
    fetchMore: dataOrgFetchMore,
    refetch: refetchOrgSettings,
  } = useQuery(ADMIN_SETTINGS, {
    variables: {
      organization_value: getVariableForOrgThreshold(orgId),
      organization_id: orgId,
      device_type: DEVICE_TYPE_MCT,
    },
    fetchPolicy: 'network-only',
    skip: !orgId,
  });

  // mutations
  const [updateOrgSettings] = useMutation(UPDATE_ORG_SETTINGS);

  // states
  const [savedChanges, setSavedChanges] = useState({
    apiCall: false,
    type: '',
    msg: '',
  });
  const [settingNotes, setSettingNotes] = useState('');
  const [settingUpdatesConfirm, setSettingUpdatesConfirm] = useState({
    open: false,
    data: [],
  });
  const [settings, setSettings] = useState<ISetting[]>([]);
  const [settingGroupDropdownList, setSettingGroupDropdownList] = useState<
    IDropdownItem[]
  >([]);
  const [activeSettingGroup, setActiveSettingGroup] = useState<string | null>(
    null
  );
  const [systemDefaultId, setSystemDefault] = useState(null);
  const [orgDefaultId, setOrgDefault] = useState(null);
  const [initialValues, setInitialValues] = useState({
    thresholds: {},
    settings: {},
  });
  const [validationSchemaValues, setValidationSchemaValues] = useState({});
  const [unsaveConfirm, setUnsaveConfirm] = useState({ open: false });
  const [changedEvents, setChangedEvents] = useState({});
  const [dirtyFields, setDirtyFields] = useState<{
    [setting_id: string]: IDirtyField;
  }>({});

  // Form the initial values for form once settings data is received
  useEffect(() => {
    if (!dataOrgSettings) return; // if we are admin page without data, return
    /**
     * Will be used in Admin Settings page
     * Logic: Admin Threshold settings
     * Admin: Setting belongs to the threshold and any selection from the threshold templates will be the teamplate itself, so entire setting object will be replaced
     */

    const { settingGroups } = dataOrgSettings;

    const org_default_setting_group_settings: ISetting[] | undefined =
      settingGroups.find(sg => sg.organization_default)?.settings;

    const global_default_setting_group_settings: ISetting[] =
      settingGroups.find(sg => sg.global_default)?.settings;

    /**
     * use org default settings if an org default is set,
     * otherwise fall back to the global default settings
     */
    const newSettings =
      org_default_setting_group_settings ||
      global_default_setting_group_settings ||
      [];

    const sortedSettings: ISetting[] = sortSettingsByDisplayOrder(newSettings);
    setSettings(sortedSettings);

    const dropdownList = getSettingGroupDropdownList(settingGroups);
    setSettingGroupDropdownList(dropdownList);

    setFormValues(newSettings);

    setDirtyFields({});
  }, [dataOrgSettings]);

  // TODO: this probably needs to be rethought:
  // why are we getting settings directly from state here?
  // should grab based on new activeSettingGroup
  useEffect(() => {
    if (activeSettingGroup) {
      setFormValues(settings);
    }
  }, [activeSettingGroup]);

  // creates the structure for the initial values for form, validation object and mappings for confirming the setting changes
  const setFormValues = (settings: ISetting[]) => {
    const [initialVals, thresholdValidations] = getInitialAndValidationAdmin(
      settings,
      true
    );

    setInitialValues(initialVals);
    setValidationSchemaValues(
      yup.object().shape({
        settings: yup.object().shape({}), // no field validation needed, all of these are booleans (currently)
        thresholds: yup.object().shape({ ...thresholdValidations }),
      })
    );
  };

  // create the threshold list for the threshold setting dropdown
  const getSettingGroupDropdownList = (
    settingGroups: ISettingGroup[]
  ): IDropdownItem[] => {
    if (!settingGroups.length) return [];

    const globalDefault = settingGroups.find(group => group.global_default);
    const orgDefault = settingGroups.find(group => group.organization_default);

    if (globalDefault) setSystemDefault(globalDefault.id);
    if (orgDefault) {
      setOrgDefault(orgDefault.id);
      setActiveSettingGroup(orgDefault.id);
    } else if (globalDefault) {
      setSystemDefault(globalDefault.id);
      setOrgDefault(globalDefault.id);
      setActiveSettingGroup(globalDefault.id);
    } else {
      setActiveSettingGroup(settingGroups[0].id);
    }

    const dropdownThresholds: IDropdownItem[] = settingGroups.map(
      settingGroup => ({
        value: settingGroup.id, // threshold setting id
        label: settingGroup.name,
        isDefault: settingGroup.global_default,
        grayed: settingGroup.global_default,
      })
    );

    return dropdownThresholds;
  };

  // handling the change in the threshold dropdown
  const thresholdSettingChange = newThresholdValue => {
    // Threshold template changed in org admin settings - replace settings
    dataOrgFetchMore({
      query: THRESHOLD_ADMIN_SETTINGS,
      variables: { device_id: newThresholdValue },
      updateQuery: (p, { fetchMoreResult }) => {
        if (fetchMoreResult) {
          let settingValue: ISetting[] = fetchMoreResult.settings; //getCombinedState({ states: [{ settings: data.settings, patient_id }], nestedKeysCreator });
          const sortedArray = sortSettingsByDisplayOrder(settingValue);
          settingValue = [...sortedArray];
          setSettings(settingValue);
          setActiveSettingGroup(newThresholdValue);
          setChangedEvents({});
          setDirtyFields({});
        }
      },
    });
  };

  const haveValuesChanged = (setting, values, initialValues): boolean => {
    // check for diffs in setting.severity (initialValues vs values)
    // then: for each threshold in setting,
    //   check for diffs in threshold value (values vs initalValues)
    const change =
      values['settings'][setting.id] !== initialValues['settings'][setting.id];

    return setting.thresholds.reduce((output, threshold) => {
      return (
        output ||
        values['thresholds'][threshold.id] !==
          initialValues['thresholds'][threshold.id]
      );
    }, change);
  };

  const getDiffValues = (values, initialValues): string => {
    // check for diffs in setting.severity (initialValues vs values)
    // then: for each threshold in setting,
    //   check for diffs in threshold value (values vs initalValues)
    let output = '';
    for (let setting of settings) {
      const setting_id = setting.id;
      if (!setting_id) continue;
      if (
        values['settings'][setting_id] !== initialValues['settings'][setting_id]
      ) {
        output += `${setting.settingType.abbr} ${initialValues['settings'][setting_id]} -> ${values['settings'][setting_id]}, `;
      }
      for (let threshold of setting.thresholds) {
        const threshold_id = threshold.id;
        if (!threshold_id) continue;
        if (
          values['thresholds'][threshold_id] !==
          initialValues['thresholds'][threshold_id]
        ) {
          output += `${setting.settingType.abbr} ${threshold.thresholdType.name} ${initialValues['thresholds'][threshold_id]} -> ${values['thresholds'][threshold_id]}, `;
        }
      }
    }
    return output;
  };

  const handleFormikSubmit = async (values, actions) => {
    await updateOrgSettings({
      variables: {
        setting_group_id: activeSettingGroup,
        settings: shapeValuesForMutation(values),
        notes: settingNotes,
      },
    }).then(
      res => {
        setSavedChanges({
          apiCall: true,
          type: 'success',
          msg: commonData.general.saveToastMessage,
        });
        sendAuditMessage({
          action: 'RESOURCE_UPDATE',
          organization_id: orgId,
          phi_flag: true,
          description: `Saved Org Settings for ${orgName}: ${getDiffValues(
            values,
            initialValues
          )}}`,
        });
      },
      error => {
        console.error(error);
        setSavedChanges({
          apiCall: true,
          type: 'error',
          msg: commonData.general.saveErrorToastMessage,
        });
        sendAuditMessage({
          action: 'RESOURCE_UPDATE_FAILURE',
          organization_id: orgId,
          phi_flag: true,
          description: `Failed to save Org ${orgName} Settings for ${activeSettingGroup}`,
        });
      }
    );

    // NOTE: 'initialValues' contains original values and 'values' contains updated values
    // TODO: should setInitialValues only happen if we succeed in updating?
    actions.setSubmitting(false);
    actions.resetForm({ values });
    setInitialValues(values);
  };

  if (!orgId) return <p>Please Select an Organization</p>;
  if (loadingOrgSettings) return <p> Loading Organization Settings... </p>;

  if (settingGroupDropdownList.length === 0)
    return <p> Loading Dropdown list... </p>;

  return (
    <div
      style={{
        width: '100%',
        borderLeft: '1px solid lightgray',
        borderRight: '1px solid lightgray',
      }}
    >
      <div
        style={{
          width: '100%',
          backgroundColor: theme.palette.primary.light,
          padding: '20px',
          borderTop: '1px solid lightgray',
          borderBottom: '1px solid lightgray',
        }}
      >
        <h1 style={{ fontSize: '1.5em' }}>{orgName}</h1>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchemaValues}
        onSubmit={handleFormikSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          dirty,
          touched,
          values,
          errors,
        }) => {
          const handleSettingChange = (setting, newValue) => {
            const newDirtyFields = { ...dirtyFields };
            const id = setting.id;
            const { abbr, name } = setting.settingType;

            if (newDirtyFields[id]) {
              newDirtyFields[id].value = newValue;
              newDirtyFields[id].initialValue = initialValues['settings'][id];
              if (
                newDirtyFields[id].value === newDirtyFields[id].initialValue
              ) {
                delete newDirtyFields[id];
              }
            } else {
              const newField = {
                setting_abbr: abbr,
                name,
                value: newValue,
                initialValue: initialValues['settings'][id],
              };
              newDirtyFields[id] = newField;
            }

            setFieldValue(`settings.${setting.id}`, newValue);
            setDirtyFields(newDirtyFields);
          };

          const handleThresholdChange = (threshold, setting_abbr, newValue) => {
            const newDirtyFields = { ...dirtyFields };
            const { id } = threshold;
            const { name } = threshold.thresholdType;

            if (newDirtyFields[id]) {
              newDirtyFields[id].value = newValue;
              newDirtyFields[id].initialValue = initialValues['thresholds'][id];
              if (
                newDirtyFields[id].value === newDirtyFields[id].initialValue
              ) {
                delete newDirtyFields[id];
              }
            } else {
              const newField = {
                setting_abbr,
                name,
                value: newValue,
                initialValue: initialValues['thresholds'][id],
              };
              newDirtyFields[id] = newField;
            }

            setFieldValue(`thresholds.${threshold.id}`, parseFloat(newValue));
            setDirtyFields(newDirtyFields);
          };

          return (
            <form>
              <BConfirm
                title={
                  messageFormat.patientRegistration.patientProfile
                    .confirmUnSavedChanges
                }
                open={unsaveConfirm.open}
                setOpen={setUnsaveConfirm}
                onConfirm={() => {}}
                severity='warning'
                saveId={generateID('btn', 'pr_settings', 'discard_changes')}
                cancelId={generateID('btn', 'pr_settings', 'stay_on_page')}
              />
              <BConfirm
                title={
                  messageFormat.patientRegistration.patientSetting.verifyChanges
                }
                open={settingUpdatesConfirm.open}
                setOpen={setSettingUpdatesConfirm}
                onConfirm={() => {
                  setSettingUpdatesConfirm({ open: false, data: [] });
                  setChangedEvents({});
                  setDirtyFields({});
                  handleSubmit();
                }}
                cancelEvent={() => {
                  setSettingUpdatesConfirm({ open: false, data: [] });
                }}
                saveId={generateID('btn', 'pr_settings', 'confirm_changes')}
                cancelId={generateID('btn', 'pr_settings', 'cancel_changes')}
                okDisabled={settingNotes === ''}
              >
                <SettingDiffList fields={dirtyFields} />
                <br />
                <TextareaAutosize
                  id={generateID('txtarea', 'pr_profile', 'notes')}
                  aria-label='minimum height'
                  style={{
                    minWidth: '300px',
                    fontFamily: [
                      'Nunito',
                      'Roboto',
                      '"Helvetica Neue"',
                      'Arial',
                      'sans-serif',
                    ].join(','),
                    fontSize: 16,
                  }}
                  required
                  minRows={3}
                  value={settingNotes}
                  name='setting_notes'
                  onChange={e => {
                    setSettingNotes(e.target.value);
                  }}
                />
              </BConfirm>

              <Grid container style={{ paddingLeft: 20, paddingRight: 20 }}>
                <div
                  style={{ width: '100%', borderBottom: '1px solid lightgray' }}
                >
                  <h2 style={{ fontSize: '1.2em' }}>Event Criteria</h2>
                </div>
                <SavedSettingGroups
                  deviceType={DEVICE_TYPE_MCT}
                  organization_id={orgId}
                  settingGroupDropdownList={settingGroupDropdownList}
                  thresholdSettingChange={thresholdSettingChange}
                  values={values}
                  currentThresholdSelection={activeSettingGroup}
                  systemDefaultId={systemDefaultId}
                  orgDefaultId={orgDefaultId}
                  refetch={refetchOrgSettings}
                  setSavedChanges={setSavedChanges}
                />
                <Grid
                  container
                  spacing={1}
                  style={{
                    fontWeight: 'bolder',
                    marginTop: '5px',
                    borderBottom: '1px solid lightgray',
                  }}
                  className='patient-list-title'
                >
                  <Grid container style={{ justifyContent: 'center' }}>
                    {THRESHOLD_SETTINGS_HEADER.map(thresholdItem => {
                      return (
                        <Grid
                          item
                          md={thresholdItem.mdSize}
                          xs={thresholdItem.xsSize}
                          className={thresholdItem.className}
                          key={thresholdItem.label}
                        >
                          <label>{thresholdItem.label}</label>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid
                    container
                    sx={{ height: 'calc(100vh - 410px)', overflowY: 'scroll' }}
                  >
                    {settings.map(setting => (
                      <ThresholdSettingsRow
                        key={setting.settingType.abbr}
                        handleSettingChange={handleSettingChange}
                        handleThresholdChange={handleThresholdChange}
                        initialValues={initialValues}
                        setting={setting}
                        changed={haveValuesChanged(
                          setting,
                          values,
                          initialValues
                        )}
                        {...{
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          dirty,
                          setFieldValue,
                        }}
                      />
                    ))}
                  </Grid>
                </Grid>
                <AdminSettingsFooter
                  savedChanges={savedChanges}
                  setSavedChanges={setSavedChanges}
                  changedEvents={changedEvents}
                  isDefault={activeSettingGroup === systemDefaultId}
                  dirtyFields={dirtyFields}
                  values={values}
                  dirty={dirty}
                  errors={errors}
                  setSettingUpdatesConfirm={setSettingUpdatesConfirm}
                  setUnsaveConfirm={setUnsaveConfirm}
                />
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

const SettingDiffList = ({
  fields,
}: {
  fields: { [id: string]: IDirtyField };
}) => {
  return (
    <>
      {Object.values(fields).map(
        ({ name, setting_abbr, initialValue, value }) => {
          return (
            <SettingDiffText
              key={name}
              name={name}
              setting_abbr={setting_abbr}
              initialValue={initialValue}
              value={value}
            />
          );
        }
      )}
    </>
  );
};

const SettingDiffText = ({
  name,
  setting_abbr,
  initialValue,
  value,
}: IDirtyField) => (
  <p key={name}>
    {setting_abbr} {name} &nbsp;
    {initialValue} &#x2192; {value}{' '}
  </p>
);

// export default AdminSettings
export default AdminSettings;
