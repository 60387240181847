import React, {
  useState,
  ChangeEvent,
  useEffect,
  ReactNode,
  useContext,
} from 'react';
import { Tabs, Tab, Box, Typography, AppBar } from '@mui/material';
import { generateID } from './utility/utilityFunctions';
import { IPatientTab } from './types/types';
import { useQuery, useMutation } from '@apollo/client';
import {
  C_GET_CURRENT_PATIENT,
  PATIENT_HEADER,
  SELECTED_PATIENT_ID,
  SELECT_PATIENT,
} from './data/queries/patient';
import BTabStepStatus from './components/common/BTabStepStatus';
import BIcon from './components/common/BIcon';
import { ICONS } from './utility/IconSelection';
import PatientRegistrationProfile from './components/patientregistration/profile/PatientRegistrationProfile';
import PatientRegistrationDevice from './components/patientregistration/device/PatientRegistrationDevice';
import PatientRegistrationHistory from './components/patientregistration/history/PatientRegistrationHistory';
import { useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router';
import PatientRegistrationHeader from './components/patientregistration/header/PatientRegistrationHeader';
import { AccessPermissionsContext } from './auth/useAccessPermissions';
import PatientRegistrationInsurance from './components/patientregistration/insurance/PatientRegistrationInsurance';

interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: IPatientTab;
  value: string;
}

const TabPanel = ({ children, value, index, ...props }: TabPanelProps) => {
  if (value !== index) return null;
  return (
    <div
      style={{ paddingLeft: '25px', paddingRight: '25px' }}
      role='tabpanel'
      id={generateID('win', 'prwin_tabpanel', index)}
      aria-labelledby={generateID('win', 'prwin_tab', index)}
      {...props}
    >
      {children}
    </div>
  );
};

const PatientRegistrationComponent = () => {
  const { pathname, state } = useLocation();
  const location = state; // TODO rename this probably?
  const pathnameSplit = pathname.split(/[/&]/).filter(part => part !== '');
  const { access } = useContext(AccessPermissionsContext);

  const navigate = useNavigate();
  const gotoHome = () => {
    let redirectTo = state?.redirectTo === 'EVENT' ? '/events' : '/patients';
    navigate(redirectTo);
  };

  const selectedPatientId = pathnameSplit[1];

  const newOrEmptyPatientId = (selectedPatientId === 'new' || !selectedPatientId); //skip the query if selectedPatientId is 'new' or empty/undefined

  let {
    loading: loadingPatientDetails,
    error: errorPatientDetails,
    data: patientDetails,
    refetch,
  } = useQuery(PATIENT_HEADER, {
    variables: { patient_id: selectedPatientId },
    fetchPolicy: 'network-only',
    skip: newOrEmptyPatientId,
  });

  const [setPatient] = useMutation(SELECT_PATIENT);
  useEffect(() => {
    // grab selectedPatientId from path
    // TODO check if current is logged in and has permission to view this path
    const pathname = pathnameSplit[pathnameSplit.length - 1]; // patientId then deviceId
    setPatient({ variables: { id: pathname === 'new' ? null : pathname } });
  }, []);

  const [activeTab, setActiveTab] = useState<IPatientTab>('profile');

  const handleTabChange = (event: ChangeEvent<{}>, newValue: IPatientTab) => {
    setActiveTab(newValue);
  };

  const TabLabel = ({ icon, text }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <BTabStepStatus completed={selectedPatientId} />
      <BIcon
        style={{ verticalAlign: 'middle', fontSize: '2rem' }}
        icon={icon}
      />{' '}
      {text}
    </div>
  );

  if (selectedPatientId === 'new' && !access.canRegisterNewPatient) {
    // do not have permission to create new patient
    navigate('/pagenotfound');
  }

  // TODO implement this redirect, including auth once users are available
  if (patientDetails?.patient === null) gotoHome(); // if an invalid selectedPatientId is in URL
  const reflectUpdatedPatientChanges = () => {
    refetch();
  };
  return (
    <div>
      <AppBar
        position='relative'
        color='default'
        sx={{
          backgroundColor: 'white',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor='primary'
          variant='fullWidth'
          TabIndicatorProps={{
            style: {
              background: 'primary',
              height: '6px',
              fontWeight: 'bolder',
            },
          }}
        >
          <Tab
            id={generateID('win', 'prwin_tab', 'profile')}
            aria-controls={generateID('win', 'prwin_tabpanel', 'profile')}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BTabStepStatus completed={patientDetails?.patient_id} />
                <BIcon
                  style={{ verticalAlign: 'middle', fontSize: '2rem' }}
                  icon={ICONS['INFORMATION']}
                />
                Profile
              </div>
            }
            value={'profile'}
          />

          <Tab
            id={generateID('win', 'prwin_tab', 'insurance')}
            aria-controls={generateID('win', 'prwin_tabpanel', 'insurance')}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BTabStepStatus
                  completed={
                    patientDetails?.patient.insurance &&
                    patientDetails.patient.insurance.length > 0
                      ? patientDetails.patient.insurance
                      : false
                  }
                />
                <BIcon
                  style={{ verticalAlign: 'middle', fontSize: '2rem' }}
                  icon={ICONS['INSURANCE']}
                />
                Insurance
              </div>
            }
            value={'insurance'}
          />

          <Tab
            disabled={!selectedPatientId}
            id={generateID('win', 'prwin_tab', 'device')}
            aria-controls={generateID('win', 'prwin_tabpanel', 'device')}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BTabStepStatus
                  // TODO clean this up and pass in boolean
                  completed={patientDetails?.patient?.devices?.some(
                    d => d.active
                  )}
                  titleIncompleteStep='Device not registered'
                />
                <BIcon
                  style={{ verticalAlign: 'middle', fontSize: '2rem' }}
                  icon={ICONS['DEVICE']}
                />
                Device
              </div>
            }
            value={'device'}
          />
          <Tab
            disabled={!selectedPatientId}
            id={generateID('win', 'prwin_tab', 'history')}
            aria-controls={generateID('win', 'prwin_tabpanel', 'history')}
            label={<TabLabel icon={ICONS['REPORTS']} text={'History'} />}
            value={'history'}
          />
        </Tabs>
      </AppBar>
      <PatientRegistrationHeader patientDetails={patientDetails} />
      {/* Tab Content */}
      <TabPanel value={activeTab} index={'profile'}>
        <PatientRegistrationProfile
          patientDetails={patientDetails}
          reflectUpdatedPatientChanges={reflectUpdatedPatientChanges}
          gotoHome={gotoHome}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={'insurance'}>
        <PatientRegistrationInsurance gotoHome={gotoHome} />
      </TabPanel>
      <TabPanel value={activeTab} index={'device'}>
        <PatientRegistrationDevice gotoHome={gotoHome} />
      </TabPanel>
      <TabPanel value={activeTab} index={'history'}>
        <PatientRegistrationHistory
          patientDetails={patientDetails}
          params={location?.params}
          gotoHome={gotoHome}
          reflectUpdatedPatientChanges={reflectUpdatedPatientChanges}
        />
      </TabPanel>
      <Outlet />
    </div>
  );
};

export default PatientRegistrationComponent;
