import { useEffect, useContext } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import PageNotFound from '../PageNotFound';
import { AccessPermissionsContext } from '../auth/useAccessPermissions';
import Logout from '../components/user/Logout';

/* Responsible for actually defining the routes - when path is changed in the URL from Browser
    Will be loaded in place here with the help of Outlet
*/
const RouteListComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const ROLE = useContext(AccessPermissionsContext)?.role;
  const PERMISSIONS = useContext(AccessPermissionsContext)?.permissions ?? [];

  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (isAuthenticated && pathname === '/') {
      // redirects based on the role
      if (
        ROLE === 'mctECGTech' &&
        PERMISSIONS.includes('MCTTechnicianAccess')
      ) {
        navigate('/events');
      } else if (ROLE === 'clinician') {
        navigate('/patients');
      } else {
        // change this for other roles as required
        navigate('/patients');
      }
    }
  }, [isAuthenticated, ROLE, PERMISSIONS]);

  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;

  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        {USER_ACCESS.routeList}
      </Route>
      <Route path='logout' element={<Logout />} />
      <Route path='pagenotfound' element={<PageNotFound />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteListComponent;
