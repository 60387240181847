import Checkbox from '@mui/material/Checkbox';

interface IProps {
  id: string;
  name: string;
  checked: boolean;
  handleChange: (e, val) => void;
  disabled?: boolean;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'default';
  style?;
}
const BCheckbox = ({
  id,
  name,
  checked,
  handleChange,
  disabled = false,
  color = 'default',
  style = {},
}: IProps) => {
  return (
    <Checkbox
      id={id}
      name={name}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      color={color}
      style={style}
      inputProps={{ 'aria-label': 'default checkbox' }}
    />
  );
};

export default BCheckbox;
