import { SvgIcon } from '@mui/material';
let BEOSApproved = props => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.0925 5.95386L16.2002 1.06156C16.1079 0.96925 15.9233 0.923096 15.7848 0.923096C15.5079 0.923096 15.231 1.15386 15.231 1.43079V5.35387C15.231 6.18463 15.9694 6.9231 16.8002 6.9231H20.7233C21.0002 6.9231 21.231 6.64617 21.231 6.36925C21.231 6.23079 21.1848 6.04617 21.0925 5.95386ZM19.2628 10.8462H15.3551C14.3412 10.8462 13.3064 10.5259 12.5217 9.74114C11.7369 8.95638 11.4167 7.92159 11.4167 6.90769V3H5.01665C4.98912 3 4.93275 3.01233 4.87318 3.07191C4.8136 3.13148 4.80127 3.18785 4.80127 3.21538V20.9385C4.80127 20.966 4.8136 21.0224 4.87318 21.0819C4.93275 21.1415 4.98912 21.1538 5.01665 21.1538H12.552C13.6241 22.3535 15.1655 23.1099 16.8996 23.1099C17.8083 23.1099 18.6843 22.8828 19.4606 22.4885L19.9233 22.9662C19.653 23.0866 19.3563 23.1538 19.0474 23.1538H5.01665C3.81665 23.1538 2.80127 22.1385 2.80127 20.9385V3.21538C2.80127 2.01538 3.81665 1 5.01665 1H12.6782C13.0936 1 13.4167 1.32308 13.4167 1.73846V6.90769C13.4167 8.10769 14.1551 8.84615 15.3551 8.84615H20.5243C20.9397 8.84615 21.2628 9.16923 21.2628 9.58462V12.9737C20.7097 12.3498 20.0302 11.8445 19.2628 11.5015V10.8462Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8826 12C19.5869 12 21.7653 14.2488 21.7653 17.0404C21.7653 17.8934 21.5399 18.7464 21.1643 19.4443L23.831 22.1971C24.0563 22.4297 24.0563 22.7787 23.831 23.0113L23.0423 23.8255C22.8169 24.0582 22.4789 24.0582 22.2535 23.8255L19.662 21.1502C18.8732 21.7318 17.8967 22.0808 16.8826 22.0808C14.1784 22.0808 12 19.832 12 17.0404C12 14.2488 14.1784 12 16.8826 12ZM16.8826 19.7544C18.3474 19.7544 19.5117 18.5525 19.5117 17.0404C19.5117 15.5283 18.3474 14.3263 16.8826 14.3263C15.4178 14.3263 14.2535 15.5283 14.2535 17.0404C14.2535 18.5525 15.4178 19.7544 16.8826 19.7544Z'
        fill='black'
      />
    </SvgIcon>
  );
};

export default BEOSApproved;
