// This component creates Textbox with optional validation text message.
// TODO remove this link after outlined input implemented: https://codesandbox.io/s/material-demo-forked-03ip3?file=/demo.tsx:1898-1919
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import { IMaskInput } from 'react-imask';
import React from 'react';

const BInputMaskTextBox = ({
  label,
  name,
  id,
  value,
  handleChange,
  handleBlur,
  errors,
  touched,
  required,
  shrink,
  fullWidth,
  disabled,
}) => {
  return (
    <FormControl variant='outlined' fullWidth={fullWidth} disabled={disabled}>
      <InputLabel
        error={required && errors && touched}
        shrink
        htmlFor={name}
        required={required}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        notched={shrink}
        label={label}
        required={required}
        value={value}
        onChange={handleChange}
        name={name}
        id={id}
        inputComponent={TextMaskCustom as any}
        error={required && errors && touched}
        onBlur={handleBlur}
      />
      <FormHelperText error={required && errors && touched} id={name}>
        {required && errors && touched ? errors : ''}
      </FormHelperText>
    </FormControl>
  );
};

const TextMaskCustom = React.forwardRef(function TextMaskCustom(
  props: any,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='#00-000-0000'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
      overwrite
    />
  );
});

export default BInputMaskTextBox;
