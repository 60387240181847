import React from 'react';
import BDropDown from '../../common/BDropDown';

// This component will show the list of the organization specific thresholds including the global threshold
export const ThresholdList = props =>
  props.menuItems.length > 0 ? (
    <BDropDown
      id={props.id}
      name={props.name}
      label={props.label}
      menuItems={props.menuItems}
      value={props.value}
      shrink={true}
      labelWidth={props.labelWidth}
      fullWidth={true}
      handleChange={event => {
        props.handleChange(event);
      }}
    />
  ) : null;
