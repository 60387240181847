import React from 'react';
import { SvgIcon } from '@mui/material';
let Notes = props => {
  return (
    <SvgIcon
      style={props.style}
      height='200px'
      width='200px'
      className={props.className}
      viewBox='0 0 100 100'
      x='0px'
      y='0px'
    >
      <path d='M84.68,72.2H74.09a3,3,0,0,0-3,3V85.78a1,1,0,0,0,1.71.71L85.38,73.9A1,1,0,0,0,84.68,72.2Z'></path>
      <path d='M84.09,12H15.76a4,4,0,0,0-4,4V85.2a4,4,0,0,0,4,4H63.09a2,2,0,0,0,2-2v-12a9,9,0,0,1,9-9h12a2,2,0,0,0,2-2V16A4,4,0,0,0,84.09,12ZM55,62.51H25.67a3,3,0,1,1,0-6H55a3,3,0,0,1,0,6Zm20-15H25.67a3,3,0,1,1,0-6H75a3,3,0,0,1,0,6Zm0-15H25.67a3,3,0,0,1,0-6H75a3,3,0,1,1,0,6Z'></path>
    </SvgIcon>
  );
};

export default Notes;
