import {
  gridRowCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { CustomColumnMenu } from './logicPatientList';
import { commonData } from './../../../data/CommonData';
import TablePagination from '@mui/material/TablePagination';
import { BDataGrid } from '../../../components/common/BDataGrid';

export default function PatientListGrid({
  gridCols,
  rowData,
  filterFEModel,
  sortFEModel,
  handleSortChange,
  handleFilterChange,
  rowsPerPage,
  totalRowCount,
  handleChangePage,
  setPage,
  setRowsPerPage,
  openPopOverId,
  loading,
  openDeviceEvent,
  handleClose,
  anchorEl,
  gridRef,
  page,
  handleDensityChange,
  density,
  eventType,
}) {
  return (
    <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
      {gridCols.length > 0 && ( // fixes issue with initial column render
        <BDataGrid
          apiRef={gridRef}
          rows={rowData}
          columns={gridCols}
          pageSize={rowsPerPage}
          onStateChange={newState => {
            if (newState.density.value != density) {
              handleDensityChange(newState.density.value);
            }
          }}
          density={density}
          page={page}
          rowCount={totalRowCount}
          rowsPerPageOptions={commonData.dataGrid.paginationGridPageSizeOptions}
          onPageChange={newPage => {
            handleChangePage(newPage, rowsPerPage);
          }}
          onPageSizeChange={pageSize => {
            setRowsPerPage(() => pageSize);
            handleChangePage(0, pageSize);
          }}
          initialState={{
            filter: {
              filterModel: { items: filterFEModel },
            },
            sorting: { sortModel: sortFEModel },
            pagination: {
              page: page,
            },
          }}
          sortModel={sortFEModel}
          filterModel={{
            items: filterFEModel,
          }}
          onSortModelChange={change => {
            handleSortChange(change);
          }}
          onFilterModelChange={change => {
            handleFilterChange(change.items);
          }}
          sortingOrder={['asc', 'desc']}
          components={{
            ColumnMenu: CustomColumnMenu,
            Pagination: CustomPagination,
            Toolbar: CustomToolbarForGrid,
          }}
          componentsProps={{
            filterPanel: { linkOperators: ['and'] },
            pagination: {
              loading: loading,
            },
          }}
          paginationMode='server'
          sortingMode='server'
          filterMode='server'
          pagination
          disableMultipleSelection
          loading={loading}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          headerHeight={40}
          localeText={{
            columnHeaderSortIconLabel: 'Sort, shift-click for secondary sort',
          }}
        />
      )}
    </div>
  );
}

// Custom pagination for data grid
export function CustomPagination({ loading }) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
  const rowsPerPage = useGridSelector(apiRef, gridPageSizeSelector);

  return (
    <TablePagination
      SelectProps={{ disabled: loading }}
      backIconButtonProps={loading ? { disabled: loading } : null}
      nextIconButtonProps={loading ? { disabled: loading } : null}
      count={totalRowCount}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={commonData.dataGrid.paginationGridPageSizeOptions}
      onPageChange={(event, value) => apiRef.current.setPage(value)}
      onRowsPerPageChange={event => {
        apiRef.current.setPageSize(parseInt(event.target.value));
      }}
    />
  );
}

export function CustomToolbarForGrid({}) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}
