import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { ABOUT_DETAILS } from '../../types/constants';

const About = ({ openAboutDialog, handleAboutVisibility }) => {
  const getAboutContent = details => {
    let component = null;
    switch (details.id) {
      case 'termsofuse':
      case 'privacypolicy': {
        component = (
          <Link
            to={`/${details.id}`}
            state={
              details.id === 'termsofuse' ? { viewOnlyRequested: true } : null
            }
          >
            <Typography
              sx={theme => ({
                color: theme.palette.primary.main,
              })}
            >
              {details.content}
            </Typography>
          </Link>
        );

        break;
      }
      case 'softwareversion': {
        component = <Typography>{(window as any).appVersion}</Typography>;
        break;
      }
      default: {
        component = <Typography>{details.content}</Typography>;
      }
    }
    return component;
  };
  return (
    <React.Fragment>
      <Dialog
        open={openAboutDialog}
        onClose={() => handleAboutVisibility(false)}
        style={{ border: 'solid 2px blue' }}
      >
        <DialogTitle
          style={{
            height: '300px',
            alignContent: 'end',
            backgroundColor: '#154898',
            color: 'lightgray',
          }}
        >
          <button
            className='error-card-close'
            style={{ color: 'lightgray' }}
            onClick={() => {
              handleAboutVisibility(false);
            }}
          >
            x
          </button>
          <Typography style={{ fontSize: '25px', fontWeight: 'bolder' }}>
            About CAM DxT
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} style={{ paddingTop: '10px' }}>
            {ABOUT_DETAILS.map((aboutContent, index) => {
              return (
                <React.Fragment>
                  <Grid item xs={5}>
                    <Typography>{aboutContent.title}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {getAboutContent(aboutContent)}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default About;
