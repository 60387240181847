import React from 'react';
import { SvgIcon } from '@mui/material';
const BMoreInfo = props => {
  return (
    <SvgIcon style={props.style} className={props.className} viewBox='0 0 33 9'>
      <circle className={props.className} cx='4.5' cy='4.5' r='4.5' />
      <circle className={props.className} cx='16.5' cy='4.5' r='4.5' />
      <circle className={props.className} cx='28.5' cy='4.5' r='4.5' />
    </SvgIcon>
  );
};

export default BMoreInfo;
