import React from 'react';
import { SvgIcon } from '@mui/material';
const BPinOff = props => {
  return (
    <SvgIcon
      style={props.style}
      className={props.className}
      viewBox='0 0 46 46'
    >
      <path
        className={props.className}
        d='M24.24,4.8,34.52,11A18.14,18.14,0,0,0,24,29.14l-4.38-2.63-1.64-1-.39-.23-4.37-2.63a17.91,17.91,0,0,0,8.51-7.38A17.93,17.93,0,0,0,24.24,4.8M21.83,0a.85.85,0,0,0-.74.42l-.21.34a1.52,1.52,0,0,0-.15,1.18A15.11,15.11,0,0,1,9.46,20.7a1.49,1.49,0,0,0-1,.69l-.42.69a.86.86,0,0,0,.3,1.19L16,27.86,6.18,44.21a1.18,1.18,0,0,0,.4,1.62,1.13,1.13,0,0,0,.61.17,1.18,1.18,0,0,0,1-.57L18,29.08l7.63,4.59a.92.92,0,0,0,.45.12.86.86,0,0,0,.74-.42l.42-.7a1.52,1.52,0,0,0,.15-1.18A15.13,15.13,0,0,1,38.69,12.73a1.49,1.49,0,0,0,1-.68l.21-.34a.87.87,0,0,0-.3-1.19L22.27.12A.88.88,0,0,0,21.83,0Z'
      />
    </SvgIcon>
  );
};

export default BPinOff;
