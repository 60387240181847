import { SvgIcon } from '@mui/material';
let BPeriodicSummaryDue = props => {
  return (
    <SvgIcon height='24' viewBox='0 0 24 24' fill='none'>
      <path
        id='Subtract'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.092 5.95362L16.1997 1.06131C16.1074 0.969005 15.9228 0.922852 15.7843 0.922852C15.5074 0.922852 15.2305 1.15362 15.2305 1.43054V5.35362C15.2305 6.18439 15.9689 6.92285 16.7997 6.92285H20.7228C20.9997 6.92285 21.2305 6.64593 21.2305 6.36901C21.2305 6.23054 21.1843 6.04593 21.092 5.95362ZM19.2623 10.8459H15.3546C14.3407 10.8459 13.3059 10.5256 12.5212 9.74089C11.7364 8.95614 11.4162 7.92135 11.4162 6.90745V2.99976H5.01617C4.98863 2.99976 4.93226 3.01208 4.87269 3.07166C4.81311 3.13124 4.80078 3.1876 4.80078 3.21514V20.9382C4.80078 20.9658 4.81311 21.0221 4.87269 21.0817C4.93226 21.1413 4.98863 21.1536 5.01617 21.1536H12.3376C12.572 21.8793 12.9217 22.5531 13.3651 23.1536H5.01617C3.81617 23.1536 2.80078 22.1382 2.80078 20.9382V3.21514C2.80078 2.01514 3.81617 0.999756 5.01617 0.999756H12.6777C13.0931 0.999756 13.4162 1.32283 13.4162 1.73822V6.90745C13.4162 8.10745 14.1546 8.84591 15.3546 8.84591H20.5239C20.9392 8.84591 21.2623 9.16899 21.2623 9.58437V12.3734C20.6314 12.1581 19.9599 12.0303 19.2623 12.0046V10.8459Z'
        fill='black'
      />
      <path
        id='icon'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 19.5556C24 19.8333 23.7222 20.1111 23.4444 20.1111H20.1111V23.4444C20.1111 23.7222 19.8333 24 19.5556 24H18.4444C18.1667 24 17.8889 23.7222 17.8889 23.4444V20.1111H14.5556C14.2778 20.1111 14 19.8333 14 19.5556V18.4444C14 18.1667 14.2778 17.8889 14.5556 17.8889H17.8889V14.5556C17.8889 14.2778 18.1667 14 18.4444 14H19.5556C19.8333 14 20.1111 14.2778 20.1111 14.5556V17.8889H23.4444C23.7222 17.8889 24 18.1667 24 18.4444V19.5556Z'
        fill='black'
      />
    </SvgIcon>
  );
};

export default BPeriodicSummaryDue;
