import { useMsal } from '@azure/msal-react';
import { useEffect, createContext, useContext, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_USER_ROLE, SIGN_TERM_OF_USE } from '../data/queries/login';
import { getFinalPermissions } from '../types/permissions';
import { Context } from 'react';
import {
  IUserAccessObject,
  IUserAccessPermissions,
  IUserAccessRole,
} from '../types/types';
import { useSendAuditTrailLogMessage } from '../data/queries/auditTrailLogMessage';

const PROFILE_MAP: { [k: string]: IUserAccessRole } = {
  Clinician: 'clinician',
  'System Admin': 'system_admin',
  'Organization Administrator': 'org_admin',
  'QC User': 'qc_user',
  'ECG Tech User': 'mctECGTech',
  'Distributor Administrator': 'distributor_admin',
};

const defaultPermissions: IUserAccessObject = {
  canAccessTechNotes: false,
  canUpdatePatient: false,
  canUpdateSettings: false,
  canRegisterDevice: false,
  canLaunchECGViewer: false,
  canDeactivateDevice: false,
  canPublishInitialReport: false,
  canViewCCReviewDue: false,
  canApproveReports: false,
  canViewReports: false,
  canRegisterNewPatient: false,
  canDeleteDevice: false,
  canDeletePatient: false,
  navigationMenu: [],
  historyFilter: [],
  routeList: [],
};

type IAccessPermissionsContext = Context<{
  permissions: IUserAccessPermissions[];
  role: IUserAccessRole | null;
  token: string;
  access: IUserAccessObject;
  loading: boolean;
  error: ApolloError | false;
  name: string;
  email: string;
  organization: string;
  legalDocSigned: string;
  saveUserAgreement: Function;
}>;

export const AccessPermissionsContext: IAccessPermissionsContext =
  createContext({
    permissions: null,
    token: null,
    role: null,
    access: defaultPermissions,
    loading: false,
    error: false,
    name: null,
    email: null,
    organization: null,
    legalDocSigned: null,
    saveUserAgreement: null,
  });

const AccessPermissionsProvider = ({ profileId, token, children }): any => {
  // TODO - probably merge this logic with useAcquireToken into one big Auth provider?
  const [permissions, setPermissions] = useState<IUserAccessPermissions[]>([]);
  const [role, setRole] = useState<IUserAccessRole | null>(null);
  const [access, setAccess] = useState(defaultPermissions);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [legalDocSigned, setLegalDocSigned] = useState(null);
  const { sendAuditMessage } = useSendAuditTrailLogMessage();
  const [signTermsOfUse] = useMutation(SIGN_TERM_OF_USE);

  const { data, error, loading } = useQuery(GET_USER_ROLE, {
    variables: {
      id: profileId,
    },
  });

  const { instance } = useMsal();
  useEffect(() => {
    if (data && data.user === null) {
      const name = instance.getActiveAccount().name;
      const account = instance.getActiveAccount();

      instance.logoutRedirect({
        account,
        postLogoutRedirectUri: process.env.REACT_APP_WEB_LOGOUT_REDIRECT_URI,
      });
      sendAuditMessage({
        action: 'LOGOUT',
        phi_flag: true,
        description: `User ${name} logout`,
      });
    }
    const newRole = PROFILE_MAP[data?.user?.profile?.name] || null;
    const newPermissions = data?.user?.permissions.map(p => p.name) || [];
    const accessObj = getFinalPermissions(newRole, newPermissions);
    const newName = `${data?.user?.last_name}, ${data?.user?.first_name}`;
    const email = data?.user?.email;
    const organization = data?.user?.organization?.name;
    const legalDocSigned = data?.user?.signed_legal_doc_timestamp
      ? new Date(Number(data?.user?.signed_legal_doc_timestamp)).toISOString()
      : null;

    setRole(newRole);
    setPermissions(newPermissions);
    setAccess(accessObj);
    setName(newName);
    setEmail(email);
    setOrganization(organization);
    setLegalDocSigned(legalDocSigned);

    sendAuditMessage({
      action: 'LOGIN',
      phi_flag: true,
      description: `User ${name} logged in with role ${role}`,
    });
  }, [data]);
  if (error) {
    console.error('Error getting permissions:', error);
  }
  const saveUserAgreement = ID => {
    signTermsOfUse({
      variables: {
        legal_doc_id: ID,
      },
    }).then(() => {
      setLegalDocSigned(true);
    });
  };

  const value = {
    permissions: permissions,
    role,
    access,
    token,
    loading,
    error,
    name,
    email,
    organization,
    legalDocSigned,
    saveUserAgreement,
  };

  return (
    <AccessPermissionsContext.Provider value={value}>
      {children}
    </AccessPermissionsContext.Provider>
  );
};

export default AccessPermissionsProvider;
