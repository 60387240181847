// Component for the confirmation dialog box with customised ok and cancel captions, title and children.
// By default ok caption is OK and cancel caption is CANCEL
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Alert,
  Backdrop,
} from '@mui/material';
import BSaveCancel from './BSaveCancel';

interface IBConfirmDialog {
  title?: string;
  children?: React.ReactNode;
  open?: boolean;
  setOpen?: any;
  onConfirm?: any;
  okCaption?: string;
  cancelCaption?: string;
  cancelEvent?: any;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
  severity?: any;
  saveId?: string;
  cancelId?: string;
  showCancelButton?: boolean;
}
const BConfirm = (props: IBConfirmDialog) => {
  const {
    title,
    children,
    open,
    setOpen,
    onConfirm,
    okCaption,
    cancelCaption,
    cancelEvent,
    okDisabled,
    cancelDisabled,
    severity,
    saveId,
    cancelId,
    showCancelButton,
  } = props;
  return (
    <>
      <Backdrop open={open} />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth='xl'
      >
        <DialogTitle
          style={{ borderBottom: 'solid #f1f1f1', padding: '0' }}
          id='Confirm-dialog'
        >
          <Alert
            severity={
              ['error', 'info', 'success', 'warning'].includes(severity)
                ? severity
                : 'info'
            }
          >
            <Typography>{title}</Typography>
          </Alert>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ marginTop: '15px' }}>{children}</Typography>
        </DialogContent>
        <DialogActions disableSpacing>
          <BSaveCancel
            showCancelButton={showCancelButton}
            saveId={saveId}
            saveDisabled={okDisabled || false}
            saveCaption={okCaption || 'OK'}
            saveEvent={() => {
              setOpen({ open: false });
              onConfirm();
            }}
            cancelId={cancelId}
            cancelDisabled={cancelDisabled || false}
            cancelCaption={cancelCaption || 'CANCEL'}
            cancelEvent={() => {
              setOpen({ open: false });
              cancelEvent();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BConfirm;
