import { Avatar } from '@mui/material';
import BIcon from './../common/BIcon';
import { ICONS } from './../../utility/IconSelection';
import {
  getEventIconStyles,
  getEventStyles,
} from '../../utility/utilityFunctions';

const EventIconSpacer = () => (
  <span style={{ width: '40px', marginRight: '8px' }}></span>
);
export const PatientArrhythmiaEventIconList = ({ eventList }) => {
  const PATIENT_ARRHYTHMIA_EVENT_ABBRS = ['AF', 'VT', 'P', 'T', 'B'];

  return (
    <>
      {PATIENT_ARRHYTHMIA_EVENT_ABBRS.map(abbr => {
        const row = eventList.find(e => e.abbr === abbr);
        if (!row) return <EventIconSpacer key={`spacer-${abbr}`} />;
        return (
          <span key={`arr-${row.name}`}>
            {
              <Avatar
                variant='rounded'
                sx={getEventStyles('events', row.severity)}
                style={{
                  display: 'inline-flex',
                  marginRight: '8px',
                  fontSize: '16px',
                }}
                title={row.name}
              >
                {row.abbr}
              </Avatar>
            }
          </span>
        );
      })}
    </>
  );
};

const DeviceEventIcon = ({ eventData }) => (
  <span>
    <BIcon
      icon={ICONS[eventData.type]}
      style={getEventIconStyles(eventData.severity)}
      fontSize='large'
      title={eventData.name}
    />
  </span>
);
export const DeviceEventIconList = ({ eventList }) => {
  const DEVICE_EVENT_ABBRS = ['PATIENT_EVENTS', 'BASELINE'];
  return (
    <>
      {DEVICE_EVENT_ABBRS.map(abbr => {
        const row = eventList.find(e => e.abbr === abbr);
        if (!row) return <EventIconSpacer key={`spacer-${abbr}`} />;
        return <DeviceEventIcon key={`eve-${row.name}`} eventData={row} />;
      })}
    </>
  );
};
