import { useState, useContext } from 'react';
import {
  Typography,
  Grid,
  Tooltip,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { generateID } from '../../../utility/utilityFunctions';
import { styled } from '@mui/system';
import { Close } from '@mui/icons-material';
import { getOrgContactDetails } from '../../../utility/helper/patientlist/logicPatientList';
import OrgContactInformation from '../../organization/OrgContactInformation';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';
import { getPatientIndications } from '../../../utility/utilityFunctions';

// TODO type this properly - IPatientDetails ?
interface IProps {
  patientDetails: any;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const PatientRegistrationHeader = ({ patientDetails }: IProps) => {
  const [open, setOpen] = useState(false);
  const patient = patientDetails?.patient;
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const { canAccessTechNotes } = USER_ACCESS;

  if (!patient) return null;

  const getPatientDetails = patient => {
    // patient.age is always zero, instead compute age based on birthday
    const birthday = new Date(patient.date_of_birth);
    const age_in_milliseconds = new Date().getTime() - birthday.getTime();
    // convert milliseconds to years
    const age = Math.floor(age_in_milliseconds / (1000 * 60 * 60 * 24 * 365));
    return ` ${patient.last_name}, ${patient.first_name} |  ${
      patient.patient_identifier
    }  |  ${age + ''}:${patient.sex[0].toUpperCase()}  `;
  };

  const getPatientNotes = patient => {
    return patient.history.map(item => item.description.text).join(', ');
  };

  const patientMain = getPatientDetails(patient);
  const patientIndications = getPatientIndications(patient);
  const patientNotes = getPatientNotes(patient);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        style={{ minWidth: '800px' }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Contact information</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <OrgContactInformation
            sideBySide={false}
            {...getOrgContactDetails(patientDetails?.patient?.organization)}
          />
        </DialogContent>
      </BootstrapDialog>
      <Grid container className='patient-header'>
        <Grid item xs={4}>
          <Typography
            id={generateID('txt', 'prheader', 'patient_details')}
            noWrap
            style={{ fontWeight: 'bold', marginLeft: '13px' }}
            sx={{ lineHeight: 2.5 }}
          >
            {patientMain} &emsp;
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Tooltip
            disableFocusListener
            title={patientIndications}
            arrow
          >
            <Typography
              id={generateID('txt', 'prheader', 'patient_indications')}
              noWrap
              sx={{ lineHeight: 2.5 }}
            >
              {patientIndications} &nbsp;
            </Typography>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={1}
          style={{
            textDecoration: 'underline',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleClickOpen}
          className='org-contact-link'
        >
          Clinical Contact
        </Grid>
        {canAccessTechNotes ? (
          <Grid item xs={4}>
            <Tooltip disableFocusListener title={patientNotes} arrow>
              <Typography
                id={generateID('txt', 'prheader', 'clinical_notes')}
                noWrap
                sx={{ lineHeight: 2.5 }}
              >
                {' '}
                {patientNotes} &nbsp;
              </Typography>
            </Tooltip>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};
export default PatientRegistrationHeader;
