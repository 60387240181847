import { createTheme } from '@mui/material/styles';
import { commonData } from '../data/CommonData';
export const theme = createTheme({
  palette: {
    primary: {
      main: commonData.standardColor.baxterBlue.color,
      light: commonData.standardColor.baxterBlueBackground.color
    },
    secondary: {
      main: commonData.standardColor.baxterDisabledGray.color,
    },
    error: {
      main: commonData.standardColor.bardyRed.color,
    },
    grey: {
      500: '#777A7C',
    },
    success: {
      main: commonData.standardColor.successGreen.color,
    },
  },
  typography: {
    fontFamily: [
      'Nunito',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 14,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: state =>
          state.variant === 'contained' && {
            minWidth: '75px',
            color: 'white',
          },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 1000,
          color: '#fff',
        },
      },
    },
  },
});
