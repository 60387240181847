import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const BButtonBase = props => {
  return (
    <Button
      id={props.id}
      disabled={props.disabled}
      style={props.style || {}}
      variant='contained'
      className={`${props.className}`}
      color={props.color}
      onClick={props.clickEvent}
      type={props.submit || 'submit'}
    >
      {props.caption}
    </Button>
  );
};

export const BButton = styled(BButtonBase)(({ theme }) => ({
  borderRadius: '30px',
}));

export default BButton;
