import React from 'react';
import { SvgIcon } from '@mui/material';
let Information = props => {
  return (
    <SvgIcon
      style={props.style}
      height='100px'
      width='100px'
      className={props.className}
      viewBox='0 0 33.99994 44'
      x='0px'
      y='0px'
    >
      <path d='M18.99994,23H15A15,15,0,0,0,0,38v6H33.99994V38A15,15,0,0,0,18.99994,23Z'></path>
      <circle cx='16.99994' cy='10' r='10'></circle>
    </SvgIcon>
  );
};

export default Information;
