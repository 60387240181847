import { SvgIcon } from '@mui/material';
let BEOSApproved = props => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.2623 7.45H16.943H15.8C15.1373 7.45 14.6 6.91274 14.6 6.25V6V3H5.01617C4.98863 3 4.93226 3.01233 4.87269 3.07191C4.81311 3.13148 4.80078 3.18785 4.80078 3.21538V20.9385C4.80078 20.966 4.81311 21.0224 4.87269 21.0819C4.93226 21.1415 4.98863 21.1538 5.01617 21.1538H19.0469C19.0745 21.1538 19.1308 21.1415 19.1904 21.0819C19.25 21.0224 19.2623 20.966 19.2623 20.9385V7.45ZM20.7105 5.42068C21.0647 5.79257 21.2623 6.28647 21.2623 6.80005V20.9385C21.2623 22.1385 20.2469 23.1538 19.0469 23.1538H5.01617C3.81617 23.1538 2.80078 22.1385 2.80078 20.9385V3.21538C2.80078 2.01538 3.81617 1 5.01617 1H15.6429C16.1902 1 16.7136 1.22431 17.0911 1.62063L20.7105 5.42068Z'
        fill='black'
      />
    </SvgIcon>
  );
};

export default BEOSApproved;
