import React, { useState, useEffect } from 'react';
import messageFormat from '../../utility/messageToUser.json';
import BConfirm from '../common/BConfirm';
import BTextField from '../common/BTextField';
import { Grid, Typography } from '@mui/material';
import BButton from '../common/BButton';
import BCheckbox from '../common/BCheckBox';
import BDropDown from '../common/BDropDown';
import { shapeValuesForMutation } from '../../utility/helper/settings/SettingOperations';
import { generateID } from '../../utility/utilityFunctions';
import { useMutation } from '@apollo/client';
import { IDropdownItem } from '../../types/types';

import {
  CREATE_ORG_SETTINGS,
  RENAME_THRESHOLD_SETTINGS,
  SET_DEFAULT_NEW_PATIENT,
  DELETE_THRESHOLD_SETTINGS,
} from '../../data/queries/setting';

import { useSendAuditTrailLogMessage } from '../../data/queries/auditTrailLogMessage';

interface IProps {
  currentThresholdSelection: string | null;
  settingGroupDropdownList: IDropdownItem[];
  systemDefaultId: string;
  orgDefaultId: string;
  refetch: any;
  values: any;
  thresholdSettingChange: (newThresholdValue: string) => void;
  organization_id: any;
  deviceType: any;
  setSavedChanges: any;
}

const SavedSettingGroups = ({
  currentThresholdSelection,
  settingGroupDropdownList,
  systemDefaultId,
  orgDefaultId,
  refetch,
  values,
  thresholdSettingChange,
  organization_id,
  deviceType,
  setSavedChanges,
}: IProps) => {
  const [createOrgSettings] = useMutation(CREATE_ORG_SETTINGS);
  const [renameSettings] = useMutation(RENAME_THRESHOLD_SETTINGS);
  const [deleteSettings] = useMutation(DELETE_THRESHOLD_SETTINGS);
  const [propsSetDefaultForNewPatient] = useMutation(SET_DEFAULT_NEW_PATIENT);

  const [renameThreshold, setRenameThreshold] = useState('');
  const [openDialogRename, setOpenDialogRename] = useState({ open: false });
  const [openDialogAdd, setOpenDialogAdd] = useState({ open: false });
  const [openDialogDelete, setOpenDialogDelete] = useState({ open: false });
  const [newThreshold, setNewThreshold] = useState('');

  const { sendAuditMessage } = useSendAuditTrailLogMessage();

  const RENAME_THRESHOLD = 1;
  const DEFAULT_NEW_PATIENT = 2;
  const DELETE_THRESHOLD = 3;
  const CREATE_THRESHOLD = 4;

  // update dropdown content
  const updateDropdownContent = (type, newThresholdId = null) => {
    switch (type) {
      case RENAME_THRESHOLD: {
        for (let i = 0; i < settingGroupDropdownList.length; i++) {
          if (settingGroupDropdownList[i].value === currentThresholdSelection) {
            settingGroupDropdownList[i].label = renameThreshold;
            break;
          }
        }
        break;
      }
      case DEFAULT_NEW_PATIENT: {
        break;
      }
      case DELETE_THRESHOLD: {
        const delIndex = settingGroupDropdownList.findIndex(
          item => item.value === currentThresholdSelection
        );

        if (!delIndex) break;
        settingGroupDropdownList.splice(delIndex, 1);
        // setCurrentThreshold(systemDefaultId);
        thresholdSettingChange(systemDefaultId);
        break;
      }
      case CREATE_THRESHOLD: {
        break;
      }
    }
    refetch();
  };

  // create threshold to server
  const createThresholdName = () => {
    let newSettings = shapeValuesForMutation(values);

    createOrgSettings({
      variables: {
        organization_id: organization_id,
        settings: newSettings,
        name: newThreshold,
        device_type: deviceType,
      },
    }).then(
      res => {
        // update threshold List;
        updateDropdownContent(CREATE_THRESHOLD, res.data.createSettingGroup.id); // TODO update with new id from backend;
        setNewThreshold('');
        setSavedChanges({
          apiCall: true,
          type: 'success',
          msg: messageFormat.admin.thresholdSetting.createdSuccessfully,
        });
        sendAuditMessage({ action: "RESOURCE_ADDITION", phi_flag: true,
          description: `Created Org Settings for ${organization_id}` });
      },
      error => {
        console.log(
          'Error while renaming threshold setting, Error msg: ',
          error
        );
        setSavedChanges({
          apiCall: true,
          type: 'error',
          msg: messageFormat.admin.thresholdSetting.createdUnSuccessfully,
        });
        sendAuditMessage({ action: "RESOURCE_ADDITION_FAILURE", phi_flag: true,
          description: `Failed to create Org Settings for ${organization_id}` });
      }
    );
  };

  // rename threshold to server
  const updateThresholdName = () => {
    renameSettings({
      variables: { group_id: currentThresholdSelection, name: renameThreshold },
    }).then(
      res => {
        // update threshold List;
        updateDropdownContent(RENAME_THRESHOLD);
        setSavedChanges({
          apiCall: true,
          type: 'success',
          msg: messageFormat.admin.thresholdSetting.renamedSuccessfully,
        });
        thresholdSettingChange('');
        sendAuditMessage({ action: "RESOURCE_UPDATE", phi_flag: true,
          description: `Renamed Org Settings for ${organization_id} to ${renameThreshold}` });
        setRenameThreshold('');
      },
      error => {
        console.log(
          'Error while renaming threshold setting, Error msg: ',
          error
        );
        setSavedChanges({
          apiCall: true,
          type: 'error',
          msg: messageFormat.admin.thresholdSetting.renamedUnSuccessfully,
        });
        sendAuditMessage({ action: "RESOURCE_UPDATE_FAILURE", phi_flag: true,
          description: `Failed to rename Org Settings for ${organization_id} to ${renameThreshold}` });
      }
    );
  };
  // set new patient default to server
  const updateDefaultForNewPatient = () => {
    propsSetDefaultForNewPatient({
      variables: {
        setting_group_id: currentThresholdSelection,
        organization_id: organization_id,
        device_type: deviceType,
      },
    }).then(
      res => {
        // update threshold List;
        updateDropdownContent(DEFAULT_NEW_PATIENT);
        setSavedChanges({
          apiCall: true,
          type: 'success',
          msg: messageFormat.admin.thresholdSetting.markDefaultSuccessfully,
        });
        sendAuditMessage({ action: "RESOURCE_UPDATE", phi_flag: true,
          description: `Set default for patient for orgId ${organization_id} for setting_group_id ${currentThresholdSelection}` });
      },
      error => {
        console.error(
          'Error while setting threshold setting patient default, Error msg: ',
          error
        );
        setSavedChanges({
          apiCall: true,
          type: 'error',
          msg: messageFormat.admin.thresholdSetting.markDefaultUnSuccessfully,
        });
        sendAuditMessage({ action: "RESOURCE_UPDATE_FAILURE", phi_flag: true,
          description: `Failed to set default for patient for orgId ${organization_id} for setting_group_id ${currentThresholdSelection}` });
      }
    );
  };

  // set new patient default to server
  const deleteThreshold = () => {
    deleteSettings({
      variables: { setting_group_id: currentThresholdSelection },
    }).then(
      res => {
        // update threshold List;
        updateDropdownContent(DELETE_THRESHOLD);
        setSavedChanges({
          apiCall: true,
          type: 'success',
          msg: messageFormat.admin.thresholdSetting.deletedSuccessfully,
        });
        sendAuditMessage({ action: "RESOURCE_DELETION", phi_flag: true,
          description: `Deleted setting_group_id ${currentThresholdSelection}` });
      },
      error => {
        console.error(
          'Error while deleting setting threshold, Error msg: ',
          error
        );
        setSavedChanges({
          apiCall: true,
          type: 'error',
          msg: messageFormat.admin.thresholdSetting.deletedUnSuccessfully,
        });
        sendAuditMessage({ action: "RESOURCE_DELETION_FAILURE", phi_flag: true,
          description: `Failed to delete setting_group_id ${currentThresholdSelection}` });
      }
    );
  };

  return (
    <>
      {/*  Rename Threshold dialog */}
      <BConfirm
        title='Rename Threshold Settings'
        open={openDialogRename.open}
        okDisabled={renameThreshold == ''}
        setOpen={setOpenDialogRename}
        onConfirm={() => {
          setOpenDialogRename({ open: false });
          updateThresholdName();
        }}
        cancelEvent={() => {
          setOpenDialogRename({ open: false });
        }}
      >
        <br />
        <BTextField
          name=''
          shrink={true}
          label='Setting Name'
          value={renameThreshold}
          handleChange={e => {
            setRenameThreshold(e.target.value);
          }}
        />
      </BConfirm>

      <BConfirm
        title='Create Threshold Settings'
        open={openDialogAdd.open}
        okDisabled={newThreshold == ''}
        setOpen={setOpenDialogAdd}
        onConfirm={() => {
          setOpenDialogAdd({ open: false });
          createThresholdName();
        }}
        cancelEvent={() => {
          setOpenDialogAdd({ open: false });
        }}
      >
        <br />
        <BTextField
          name=''
          shrink={true}
          label='Setting Name'
          value={newThreshold}
          handleChange={e => {
            setNewThreshold(e.target.value);
          }}
        />
      </BConfirm>
      <BConfirm
        title='Delete Threshold Settings'
        severity='warning'
        open={openDialogDelete.open}
        setOpen={setOpenDialogDelete}
        onConfirm={() => {
          setOpenDialogDelete({ open: false });
          deleteThreshold();
        }}
        cancelEvent={() => {
          setOpenDialogDelete({ open: false });
        }}
      >
        {messageFormat.admin.thresholdSetting.confirmDeleteThreshold}
      </BConfirm>

      <Grid
        container
        spacing={2}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginTop: '12px',
        }}
      >
        <Grid item xs={3}>
          {/* Threshold settings Name text field  */}
          <BDropDown
            id={generateID('drpdwn', 'orgadmthreshold', 'threshold')}
            name=''
            label='Event Criteria Name'
            menuItems={settingGroupDropdownList}
            value={currentThresholdSelection}
            shrink={true}
            labelWidth={200}
            fullWidth={true}
            handleChange={event => {
              thresholdSettingChange(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          {/* Set as Default for Organization */}
          <BCheckbox
            name='defaultThresholdSettings'
            id={generateID('chkbox', 'orgadmthreshold', 'default_setting')}
            color={'success'}
            checked={currentThresholdSelection === orgDefaultId}
            handleChange={(_, value) => {
              // update BE
              if (value) {
                updateDefaultForNewPatient();
              }
            }}
          />
          <Typography
            style={{ display: 'inline' }}
            id={
              generateID('chkbox', 'orgadmthreshold', 'default_setting') +
              '-label'
            }
          >
            Default for New Patient
          </Typography>
        </Grid>

        {/* Rename SettingsGroup  */}
        <Grid item xs={2}>
          <BButton
            type='blue'
            id={generateID('btn', 'orgadmthreshold', 'rename_setting')}
            caption='Rename'
            disabled={currentThresholdSelection === systemDefaultId}
            clickEvent={e => {
              e.preventDefault();
              setRenameThreshold('');
              setOpenDialogRename({ open: true });
            }}
          />
        </Grid>

        {/* Create SettingsGroup*/}
        <Grid item xs={2}>
          <BButton
            type='blue'
            id={generateID('btn', 'orgadmthreshold', 'new_setting')}
            caption='New'
            clickEvent={e => {
              e.preventDefault();
              setOpenDialogAdd({ open: true });
            }}
          />
        </Grid>

        <Grid item xs={2}>
          {/* Delete SettingsGroup */}
          <BButton
            id={generateID('btn', 'orgadmthreshold', 'delete_setting')}
            disabled={
              currentThresholdSelection === systemDefaultId ||
              currentThresholdSelection === orgDefaultId
            }
            type='blue'
            caption='Delete'
            clickEvent={e => {
              e.preventDefault();
              setOpenDialogDelete({ open: true });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SavedSettingGroups;
