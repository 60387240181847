// External Libaries
import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Avatar, Divider, Typography } from '@mui/material';
import {
  generateID,
  getEventIconStyles,
  getEventStyles,
} from '../../../utility/utilityFunctions';
import BIcon from '../../common/BIcon';
import BToggleButton from '../../common/BToggleButton';
import { ThresholdLimit } from './ThresholdLimit';
import {
  getDeviceEventLevel,
  getFormValueByIds,
  getLevelNameById,
} from '../../../utility/helper/settings/SettingOperations';
import { commonData } from '../../../data/CommonData';
import { ICONS } from '../../../utility/IconSelection';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';

// Internal Libraries

function ThresholdSettingMain({
  id,
  settingType,
  thresholds,
  threshold_report,
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  rowValue,
  initialValues,
  handleEventChange,
  currentThresholdSelection,
  isAdmin,
  mctAdmin,
}) {
  const slowVTValue = useMemo(() => {
    const slow_vt = thresholds.find(
      t => t.thresholdType.reference_id === 'slow_vt'
    );
    if (!slow_vt) return 0;

    const val = getFormValueByIds(slow_vt.id, values);
    return val;
  }, [thresholds, values]);

  const reportNotificationRequired =
    (commonData.patientRegistration.settings.deviceTypeMCT || mctAdmin) &&
    isAdmin;
  const [eventLimitChanged, setEventLimitChanged] = useState({
    changed: false,
  });
  const [eventLimitReportChanged, setEventLimitReportChanged] = useState({
    changed: false,
  });
  const [eventSeverityChanged, setEventSeverityChanged] = useState({
    changed: false,
  });
  const USER_ACCESS = React.useContext(AccessPermissionsContext)?.access;
  const { canUpdateSettings } = USER_ACCESS;

  useEffect(() => {
    // initial values changes when settings updates are saved
    setEventSeverityChanged({ changed: false });
    setEventLimitChanged({ changed: false });
  }, [initialValues]);

  useEffect(() => {
    // check for the edit icon changes - whether any event/arr has changed when template changes for patient
    setEventSeverityChanged({
      changed:
        initialValues[getLevelNameById(id)] !== values[getLevelNameById(id)],
    });
    let limitChanges = false;
    for (var i = 0; i < thresholds.length; i++) {
      if (initialValues[thresholds[i].id] !== values[thresholds[i].id]) {
        limitChanges = true;
      }
    }
    setEventLimitChanged({ changed: limitChanges });
  }, [currentThresholdSelection]);

  const actionOnLimitChange = (oldValue, newValue) => {
    if (parseFloat(oldValue) !== parseFloat(newValue)) {
      setEventLimitChanged({ changed: true });
      handleEventChange(settingType.abbr, true);
    } else {
      setEventLimitChanged({ changed: false });
      handleEventChange(
        settingType.abbr,
        false || eventSeverityChanged.changed || eventLimitReportChanged.changed
      );
    }
  };

  const actionOnLimitReportChange = (oldValue, newValue) => {
    if (oldValue !== newValue) {
      setEventLimitReportChanged({ changed: true });
      handleEventChange(settingType.abbr, true);
    } else {
      setEventLimitReportChanged({ changed: false });
      handleEventChange(
        settingType.abbr,
        false || eventSeverityChanged.changed || eventLimitChanged.changed
      );
    }
  };

  const actionOnSeverityChange = (oldValue, newValue) => {
    if (oldValue !== newValue) {
      setEventSeverityChanged({ changed: true });
      handleEventChange(settingType.abbr, true);
    } else {
      setEventSeverityChanged({ changed: false });
      handleEventChange(
        settingType.abbr,
        false || eventLimitChanged.changed || eventLimitReportChanged.changed
      );
    }
  };
  return (
    <>
      <Grid
        sx={{ alignItems: 'center' }}
        container
        className={`${rowValue % 2 === 0 ? 'even-row' : 'odd-row'}`}
      >
        <Grid item xs={4}>
          <Grid container alignItems='center'>
            <Grid item xs={9} style={{ direction: 'rtl', padding: '10px' }}>
              <Typography
                id={generateID(
                  'txt',
                  'pr_settings',
                  settingType.abbr + '_event'
                )}
              >
                {settingType.name}
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
              {/* Icons/ abbr column */}
              {settingType.abbr_type === 'string' ? (
                <Avatar
                  id={generateID(
                    'txt',
                    'pr_settings',
                    settingType.abbr + '_eventsym'
                  )}
                  variant='square'
                  sx={getEventStyles('settings', values[getLevelNameById(id)])}
                  style={{
                    display: 'inline-flex',
                    marginRight: '5px',
                    fontSize: '18px',
                  }}
                >
                  {settingType.abbr}
                </Avatar>
              ) : (
                <BIcon
                  id={generateID(
                    'icon',
                    'pr_settings',
                    settingType.abbr + '_eventsym'
                  )}
                  style={Object.assign(
                    { fontSize: '35px' },
                    getEventIconStyles(values[getLevelNameById(id)])
                  )}
                  icon={ICONS[settingType.abbr_type]}
                  fontSize='large'
                />
              )}
              {/* space for icon - change occurred for the event */}
              {eventLimitChanged.changed ||
              eventSeverityChanged.changed ||
              eventLimitReportChanged.changed ? (
                <BIcon
                  icon={ICONS['EDIT']}
                  style={{ color: commonData.standardColor.bardyBlue.color }}
                  class='patient-list-icons blink-icon'
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <>
            {thresholds &&
              thresholds.map((item, index) => (
                <Grid container key={`Threshold-${index}`}>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{ border: '1px solid #0000001f' }}
                  />
                  <ThresholdLimit
                    thresholdType=''
                    id={id}
                    item={item}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    initialValues={initialValues}
                    setFieldValue={setFieldValue}
                    settingType={settingType}
                    index={index}
                    values={values}
                    actionOnLimitChange={actionOnLimitChange}
                    hide={
                      settingType.abbr === 'VT' &&
                      slowVTValue === 0 &&
                      (item.thresholdType.reference_id === 'slow_vt_hr_bpm' ||
                        item.thresholdType.reference_id ===
                          'slow_vt_duration_beats')
                    }
                  />
                </Grid>
              ))}
          </>
        </Grid>
        {canUpdateSettings ? (
          <Grid
            item
            xs={3}
            style={{ display: 'inline-flex', justifyContent: 'center' }}
          >
            <BToggleButton
              handleChange={(ev, val) => {
                if (val) {
                  setFieldValue(getLevelNameById(id), val);
                  actionOnSeverityChange(
                    initialValues[getLevelNameById(id)],
                    val
                  );
                }
              }}
              // TODO Remove hard coded abbr disable values
              disable={
                ['VT', 'P', 'DATA_TRANSMISSION'].includes(settingType.abbr)
                  ? ['off']
                  : []
              }
              value={values[getLevelNameById(id)]}
              style={{ width: '5vw' }}
              toggleButtonList={getDeviceEventLevel(
                id,
                values,
                settingType.abbr
              )}
            />
          </Grid>
        ) : null}
      </Grid>
      <Divider style={{ color: '#333', fontWeight: 'normal' }}></Divider>
    </>
  );
}

export default ThresholdSettingMain;
