import React from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';
import { commonData } from './../../data/CommonData';

interface IProps {
  id: string;
  open: boolean;
  severity: AlertColor;
  msg: string;
  duration?: number;
  close?: () => void;
  style?: any;
}

export default function BToastMessage({
  id,
  open,
  severity,
  msg,
  duration,
  close,
  style,
}: IProps) {
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={style}
        open={open}
        autoHideDuration={duration ? duration : commonData.general.hideToastMsg} // milliseconds, null means disabled
        onClose={close}
      >
        <Alert id={id} severity={severity} onClose={close}>
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
