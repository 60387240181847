import { useState, createContext, Context, useEffect  } from "react"

type IErrorProviderContext = Context<{
  errorCodes: string[];
  addErrorCode: (code: string) => void;
  removeErrorCode: (index: number) => void;
  isSuppressingErrors: boolean;
}>;

export const ErrorProviderContext: IErrorProviderContext =
  createContext({
    errorCodes: [],
    addErrorCode: null,
    removeErrorCode: null,
    isSuppressingErrors: false,
  });

export const ErrorProvider = ({ children }): any => {

  const [errorCodes, setErrorCodes] = useState<string[]>([]);
  const [isSuppressingErrors, setIsSuppressingErrors] = useState<boolean>(false);

  // To suppress errors, add '?suppressErrors=true' to the url, only needed once
  // you can add '?suppressErrors=false' to revert to normal behavior, only needed once
  useEffect(() => {
    const url = new URL(window.location.href);
    const suppressErrorsValue : boolean = url.searchParams.get('suppressErrors') == 'true';
    setIsSuppressingErrors(suppressErrorsValue)
  }, [isSuppressingErrors])

  const addErrorCode = (code) => {
    if (!code || code === 'null') return; // suppress errors that can't be traced in AppInsights
    setErrorCodes(prevState => [...prevState, code]);
  }
  const removeErrorCode = (index) => setErrorCodes(errorCodes.filter((_, i) => i !== index));

  const value = {
    errorCodes,
    addErrorCode,
    removeErrorCode,
    isSuppressingErrors,
  }

  return (
    <ErrorProviderContext.Provider value={value}>
      {children}
    </ErrorProviderContext.Provider>
  )
}

export default ErrorProvider;
