import React from 'react';
import { SvgIcon } from '@mui/material';
const BPinOn = props => {
  return (
    <SvgIcon
      style={props.style}
      className={props.className}
      viewBox='0 0 46 46'
    >
      <path
        className={props.className}
        d='M34.66,1.23V0.84c0-0.47-0.38-0.84-0.84-0.84H14.18c-0.47,0-0.84,0.38-0.84,0.84v0.39
	c0,0.4,0.18,0.78,0.47,1.06c2.81,2.69,4.56,6.46,4.56,10.65s-1.76,7.96-4.56,10.65c-0.29,0.28-0.47,0.66-0.47,1.06v0.79
	c0,0.47,0.38,0.84,0.84,0.84h8.67v18.56c0,0.64,0.52,1.15,1.15,1.15s1.15-0.52,1.15-1.15V26.29h8.67c0.47,0,0.84-0.38,0.84-0.84
	v-0.79c0-0.4-0.18-0.78-0.47-1.06c-2.81-2.69-4.56-6.46-4.56-10.65s1.76-7.96,4.56-10.65C34.49,2.01,34.66,1.63,34.66,1.23z'
      />
    </SvgIcon>
  );
};

export default BPinOn;
