import BIcon from './BIcon';
import { ICONS } from './../../utility/IconSelection';

const BTabStepStatus = props => {
  const { completed, titleIncompleteStep } = props;
  return completed ? (
    <BIcon
      icon={ICONS['CHECKMARK']}
      style={{ color: 'green', fontWeight: 'bolder', fontSize: '1rem' }}
      fontSize='large'
    />
  ) : (
    <BIcon
      icon={ICONS['WARNING']}
      title={titleIncompleteStep || ''}
      style={{ color: '#f5a411', fontWeight: 'bolder', fontSize: '1rem' }}
      fontSize='large'
    />
  );
};

export default BTabStepStatus;
