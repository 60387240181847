import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { STATUS_DEVICE_DEACTIVATED } from '../../types/types';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.root}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.barColorPrimary}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${linearProgressClasses.barColorSecondary}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.grey[500],
  },
}));

const BProgress = ({ progress, activationStatus }) => (
  <BorderLinearProgress
    variant='determinate'
    value={progress}
    color={activationStatus === STATUS_DEVICE_DEACTIVATED ? 'secondary' : 'primary'}
  />
);

export default BProgress;
