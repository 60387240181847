import React from 'react';
import { SvgIcon } from '@mui/material';
let Temperature = props => {
  return (
    <SvgIcon
      style={props.style}
      height='200px'
      width='200px'
      className={props.className}
      viewBox='0 0 100 100'
      x='0px'
      y='0px'
    >
      <g>
        <path d='M54.504,64.918v-19.06c0-2.406-1.969-4.375-4.375-4.375s-4.375,1.969-4.375,4.375v19.06   c-4.046,1.708-6.886,5.711-6.886,10.379c0,6.219,5.042,11.261,11.261,11.261c6.219,0,11.261-5.042,11.261-11.261   C61.39,70.63,58.55,66.626,54.504,64.918z'></path>
        <path d='M61.968,60.206V18.182c0-6.56-5.337-11.896-11.896-11.896c-6.56,0-11.896,5.336-11.896,11.896v42.023   c-4.511,3.606-7.187,9.121-7.187,14.912c0,10.522,8.561,19.083,19.083,19.083c10.522,0,19.083-8.561,19.083-19.083   C69.154,69.254,66.536,63.848,61.968,60.206z M50.072,90.2c-8.317,0-15.083-6.767-15.083-15.083c0-4.777,2.301-9.322,6.153-12.157   l0.192-0.139c0.594-0.423,0.88-1.11,0.827-1.788c0.003-0.048,0.014-0.094,0.014-0.143V18.182c0-4.354,3.542-7.896,7.896-7.896   s7.896,3.542,7.896,7.896v42.756c0,0.045,0.01,0.086,0.013,0.13c-0.041,0.666,0.246,1.337,0.829,1.754   c0.126,0.09,0.252,0.183,0.38,0.279c3.791,2.882,5.965,7.262,5.965,12.017C65.154,83.434,58.389,90.2,50.072,90.2z'></path>
      </g>
    </SvgIcon>
  );
};

export default Temperature;
