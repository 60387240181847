import { SvgIcon } from '@mui/material';
const BLock = props => {
  return (
    <SvgIcon
      style={props.style}
      className={props.className}
      viewBox='0 0 24 24'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.07722 8.77625H6.92338C7.2003 8.77625 7.38492 8.63779 7.38492 8.36086V8.31471C7.38492 5.68394 9.64645 3.51471 12.3234 3.69932C14.7695 3.88394 16.6157 6.00702 16.6157 8.49932V8.36086C16.6157 8.63779 16.8003 8.77625 17.0772 8.77625H18.9234C19.2003 8.77625 19.3849 8.63779 19.3849 8.36086V8.31471C19.3849 4.11471 15.8772 0.745477 11.6311 0.930093C7.70799 1.11471 4.70799 4.43779 4.61569 8.36086C4.66184 8.59163 4.84645 8.77625 5.07722 8.77625ZM21.2311 12.4686C21.2311 11.4532 20.4003 10.6224 19.3849 10.6224H4.61569C3.6003 10.6224 2.76953 11.4532 2.76953 12.4686V21.2378C2.76953 22.2532 3.6003 23.0839 4.61569 23.0839H19.3849C20.4003 23.0839 21.2311 22.2532 21.2311 21.2378V12.4686ZM14.1234 19.7147C14.2157 19.9916 13.9849 20.3147 13.6618 20.3147H10.2926C9.96953 20.3147 9.78492 20.0378 9.83107 19.7147L10.6618 16.9455C9.96953 16.4839 9.55415 15.6532 9.69261 14.7301C9.87722 13.8532 10.5695 13.1609 11.4926 12.9762C12.9695 12.6993 14.2618 13.7609 14.2618 15.1455C14.2618 15.8839 13.8926 16.5762 13.2926 16.9455L14.1234 19.7147Z'
      />
    </SvgIcon>
  );
};

export default BLock;
