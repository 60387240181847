import { useRef } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

export default function SearchBar({ search, handleSearchChange }) {
  const inputRef = useRef(null);
  return (
    <TextField
      className={'organization-search'}
      variant='outlined'
      inputRef={inputRef}
      placeholder='Search for your organization...'
      value={search}
      sx={{ width: '100%', padding: '15px' }}
      onChange={e => handleSearchChange(e.target.value)}
      InputProps={{
        endAdornment: search ? (
          <div
            style={{ cursor: 'pointer' }}
            onClick={e => {
              handleSearchChange('');
              inputRef.current.focus();
            }}
          >
            <InputAdornment position='end'>
              <CloseIcon />
            </InputAdornment>
          </div>
        ) : (
          <InputAdornment position='end'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
