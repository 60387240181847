import React from 'react';
import { SvgIcon } from '@mui/material';
let TestReport = props => {
  return (
    <SvgIcon viewBox='0 0 46 64' style={Object.assign(props.style, {})}>
      {/* <path className={props.className} d="M380,60.26a9.36,9.36,0,0,0-6.64-2.76H167.5a89.11,89.11,0,0,0-89,89v453a89,89,0,0,0,89,89h277a89,89,0,0,0,89-89V297.2h0V217.67a9.43,9.43,0,0,0-2.76-6.67ZM394.9,158.6l37.5,37.5H394.9Zm49.6,470.9h-277a30,30,0,0,1-30-30v-453a30,30,0,0,1,30-30H335.9V247.17a7.93,7.93,0,0,0,7.93,7.93H474.5V599.5A30,30,0,0,1,444.5,629.5Z" transform="translate(-78.5 -57.5)" />
            <path className={props.className} d="M256,311.33c-29.33-3.33-75.75,13.11-68,81.34C198,480.67,306,530,306,530h0V353.34h0S299.06,316.23,256,311.33Z" transform="translate(-78.5 -57.5)" />
            <path className={props.className} d="M356,311.33c-43.05,4.9-50,42-50,42V530s108-49.33,118-137.33C431.75,324.44,385.34,308,356,311.33Z" transform="translate(-78.5 -57.5)" /> */}
      <g>
        <polygon
          className={props.className}
          points='31.99,1.79 31.99,14.08 44.24,14.08  '
        />
        <path
          className={props.className}
          d='M14.23,33.04c-0.17-0.04-0.58-0.08-1.54-0.08h-0.82v2.95h0.96c1.04,0,1.47-0.09,1.65-0.17c0.25-0.11,0.44-0.27,0.58-0.5   c0.14-0.23,0.21-0.5,0.21-0.81c0-0.39-0.1-0.69-0.29-0.94C14.78,33.25,14.53,33.1,14.23,33.04z'
        />
        <path
          className={props.className}
          d='M24.66,33.57c-0.23-0.25-0.5-0.41-0.85-0.5c-0.27-0.07-0.84-0.11-1.67-0.11h-0.7v7.56h1.33c0.56,0,0.97-0.04,1.21-0.11   c0.3-0.09,0.55-0.23,0.74-0.44c0.2-0.21,0.36-0.56,0.49-1.07c0.14-0.52,0.21-1.25,0.21-2.17c0-0.91-0.07-1.62-0.2-2.1   C25.08,34.18,24.89,33.82,24.66,33.57z'
        />
        <path
          className={props.className}
          d='M26.82,20.04c-0.44,0-0.8-0.36-0.8-0.8V0h0.08L9,0C4.04,0,0,4.05,0,9.03v45.95C0,59.96,4.03,64,9,64h28   c4.97,0,9-4.04,9-9.03V24.31l0,0v-4.27H26.82z M17.66,36.39c-0.26,0.54-0.6,0.98-1,1.29c-0.4,0.31-0.82,0.52-1.24,0.62   c-0.55,0.12-1.34,0.18-2.35,0.18h-1.19v4.59H9.16V30.4h3.8c1.38,0,2.26,0.06,2.7,0.19c0.68,0.2,1.26,0.63,1.71,1.29   c0.45,0.65,0.68,1.5,0.68,2.51C18.05,35.18,17.92,35.85,17.66,36.39z M27.77,39.51c-0.27,0.92-0.67,1.67-1.17,2.25   c-0.39,0.44-0.92,0.79-1.58,1.04c-0.49,0.18-1.15,0.27-1.96,0.27h-4.23V30.4h4.11c0.91,0,1.58,0.08,2.07,0.25   c0.66,0.23,1.23,0.63,1.69,1.2c0.46,0.56,0.81,1.25,1.05,2.06c0.23,0.8,0.35,1.79,0.35,2.95C28.1,37.87,27.99,38.77,27.77,39.51z    M36.84,32.96h-5.06v2.38h4.37v2.56h-4.37v5.17h-2.61V30.4h7.67V32.96z'
        />
      </g>
    </SvgIcon>
  );
};

export default TestReport;
