import React from 'react';
import { SvgIcon } from '@mui/material';
let BIconButtonPress = props => {
  return (
    <SvgIcon
      style={props.style}
      className={props.className}
      viewBox='0 0 40 40'
    >
      <path
        className={props.className}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.1539 22.8121L22.8462 20.6583C22.3847 20.5044 22.0001 20.0429 22.0001 19.5044V11.1198C22.0001 9.81214 20.9232 8.81214 19.6155 8.81214H19.3847C18.077 8.81214 17.0001 9.88906 17.0001 11.1198V27.6583C17.0001 29.0429 15.2308 29.6583 14.4616 28.4275L12.8462 25.0429C12.0001 23.5814 10.077 23.1968 8.69238 24.1968L7.69238 24.966L13.0001 37.5044C13.2308 38.0429 13.7693 38.3506 14.3847 38.3506H28.3078C29.0001 38.3506 29.5385 37.8891 29.6924 37.2737L32.1539 28.5044C32.7693 26.0429 31.3847 23.6583 29.1539 22.8121ZM13.3078 17.5814V10.966C13.4616 7.81214 16.0001 5.27367 19.1539 5.11983H19.7693C22.9232 5.27367 25.4616 7.81214 25.6155 10.966V17.5814C25.6155 18.1198 26.3078 18.3506 26.6924 18.0429C28.3847 16.2737 29.3847 13.8891 29.3847 11.3506C29.3847 5.65829 24.4616 1.04291 18.6155 1.58137C14.1539 2.04291 10.4616 5.42752 9.76931 9.81214C9.30777 12.8891 10.2308 15.8891 12.3078 18.0429C12.6155 18.3506 13.3078 18.1198 13.3078 17.5814Z'
      />
    </SvgIcon>
  );
};

export default BIconButtonPress;
