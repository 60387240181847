/**
 * Stores common hard coded values, reusable data items
 */
export const commonData = {
  live: {
    deviceList: [
      {
        deviceId: 1,
        active: true,
      },
    ],
  },
  login: {
    role1: {
      username: 'bardy',
      password: 'bardydx2020',
    },
  },
  patientRegistration: {
    profile: {
      altContactCount: 3,
    },
    settings: {
      deviceTypeMCT: false,
      severityList: ['high', 'low', 'on'],
    },
  },
  standardColor: {
    bardyBlue: {
      color: '#498bca',
      shade: '#498bcad6',
      disabled: '#498bca60',
    },
    bardyGray: {
      color: '#555559a6',
      noShade: '#555559',
      shade: '#55555980',
    },
    bardyRed: {
      color: '#cf0a2c',
      shade: '#cf0a2c90',
    },
    baxterBlue: {
      color: '#154898',
    },
    baxterBlueBackground: {
      color: '#edf1f7',
    },
    baxterDisabledGray: {
      color: 'rgba(26, 28, 28, 0.49)', // TODO convert to hex
      shade: 'rgba(26, 28, 28, 0.49)',
    },
    baxterRed: {
      color: '#eb4940',
    },
  },

  reviewpatient: {
    showConfirmation: false,
  },
  general: {
    loadingAPI: 'Loading...',
    errorInAPI: 'Error occurred! Please contact support team',
    saveToastMessage: 'Saved successfully!',
    saveErrorToastMessage: 'Sorry! Error occurred while saving the data.',
    hideToastMsg: 10000,
    deviceType: 'MCT',
  },
  queries: {
    indicationsLimit: 1000,
  },
  dataGrid: {
    initial_page_size: 10,
    paginationGridPageSizeOptions: [10, 20, 30, 50],
    sortingOrderOptions: ['asc', 'desc'],
  },
  organization: {
    drawerWidth: 240,
  },
};
