import React from 'react';
import { SvgIcon } from '@mui/material';
let AltContact = props => {
  return (
    <SvgIcon
      style={props.style}
      height='100px'
      width='100px'
      className={props.className}
      viewBox='0 0 48 36'
      x='0px'
      y='0px'
    >
      <circle cx='36' cy='10' r='6'></circle>
      <path d='M37,19H36a10.95857,10.95857,0,0,0-8.09,3.55A15.95385,15.95385,0,0,1,31,32v1H48V30A10.99375,10.99375,0,0,0,37,19Z'></path>
      <circle cx='14' cy='8' r='8'></circle>
      <path d='M15,19H13A12.99963,12.99963,0,0,0,0,32v4H28V32A13.00436,13.00436,0,0,0,15,19Z'></path>
    </SvgIcon>
  );
};

export default AltContact;
