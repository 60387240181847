import { Grid } from '@mui/material';
import PatientEventNotification from './PatientEventNotification';

const BasePatientNotification = props => {
  return (
    <div
      id={props.id}
      style={{
        maxWidth: '500px',
        minWidth: '400px',
      }}
    >
      <Grid item xs={12}>
        <PatientEventNotification {...props}></PatientEventNotification>
      </Grid>
    </div>
  );
};

export default BasePatientNotification;
