import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import React, { useState, useEffect } from 'react';
import _without from 'lodash/without';
import { generateID } from '../../utility/utilityFunctions';
import {
  FILTER_EVENTS,
  MAP_PATIENT_EVENT_LIST_COL_FILTERS,
} from '../../types/constants';

/**
 *
 * @param props
 * @returns
 * Steps to create custom filter
 * 1. Create component and pass GridFilterInputValueProps as props
 * 2. Define filtervalue and setfiltervalue - contains the filter values
 *
 */

const EventsDeviceFilterComponent = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;
  const FILTER_OPTIONS = item?.columnField;
  const [filterValueState, setFilterValueState] = useState<any>([]);
  useEffect(() => {
    const itemValue = item.value ?? [];
    setFilterValueState(itemValue);
  }, [item.value]);


  const removeAllDups = (arr: string[]) : string[] => {    
    let removeItems: number[] = [];
    let newArray: string[] = [];
    for (let i = 0; i < arr.length; i++) {
      let current = arr[i].toString();
      newArray.push(arr[i])
      for (let j = i + 1; j < arr.length; j++) {
        if (current === arr[j].toString()) {
          removeItems.push(i);
          removeItems.push(j);
        }
      }
    }

    for (const element of removeItems){
      newArray[element] = undefined;
    }
    return newArray.filter((e, i) => e);
  }

  const handleChange = arrItem => {        
    //Peculiar: If the initial state is a saved view, and the filter in the view
    //contains a dropdown checked item, e.g. "Viewer/Due" with "Summary Report Due" checked
    //and the user unchecks that checkbox:
    //the value of that checkbox is passed into arrItem.target.value twice.  
    //Not sure why the duplicate value and the value being duplicated are getting passed into arrItem.target.value
    //To accomodate, call the removeAllDups function to remove the duplicate and the value that is being duplicated.
    let targetValue = arrItem.target.value;
    targetValue = removeAllDups(targetValue);

    setFilterValueState(targetValue);
    applyValue({ ...item, value: targetValue });
  };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setFilterValueState(current => _without(current, value));
  };

  return (
    <FormControl
      variant='standard'
      style={{ width: '400px', paddingTop: '1px' }}
    >
      <InputLabel
        id={generateID('label', 'pl', 'filter_events')}
        shrink={true}
      ></InputLabel>
      <Select
        color='primary'
        style={{ paddingTop: '1px' }}
        labelId={generateID('label', 'pl', 'filter_events')}
        id={generateID('drpdwn', 'pl', 'filter_events')}
        multiple
        value={filterValueState}
        onChange={handleChange}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => {
              let labelValue;
              if (typeof value === 'string') {
                labelValue = value;
              } else {
                labelValue = value?.join('').replace(',', '_'); // to match with the value and map to the label
              }
              return (
                <Chip
                  key={value}
                  label={MAP_PATIENT_EVENT_LIST_COL_FILTERS[labelValue]}
                  color='primary'
                  variant='outlined'
                  size='small'
                />
              );
            })}
          </Box>
        )}
      >
        {FILTER_EVENTS[FILTER_OPTIONS].map(name => (
          <MenuItem key={name.label} value={name.value}>
            <Checkbox
              checked={
                filterValueState ? JSON.stringify(filterValueState).includes(JSON.stringify(name.value)) : false
              }
              color='primary'
            />
            <ListItemText primary={name.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const EventsDeviceFilterOperators = [
  {
    label: 'exists',
    value: 'exists',
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return params => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) === Boolean(filterItem.value);
      };
    },
    InputComponent: EventsDeviceFilterComponent,
  },
];
export default EventsDeviceFilterOperators;
