import gql from 'graphql-tag';

// start local  mutations
export const M_USER_LOGGED = gql`
  mutation setUserLoggedIn($id: Boolean!) {
    setUserLoggedIn(userLoggedIn: $id) @client
  }
`;
export const Q_USER_LOGGED = gql`
  query userLoggedIn {
    userLoggedIn @client
  }
`;
// end local mutations

export const GET_USER_ROLE = gql`
  query GET_USER_ROLE($id: String) {
    user(id: $id) {
      first_name
      last_name
      email
      organization {
        name
      }
      profile {
        id
        name
      }
      permissions {
        id
        name
      }
    }
  }
`;
