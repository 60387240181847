import gql from 'graphql-tag';
export const typeDefs = gql`
  extend type Query {
    selectedPatientId: String
    selectedDeviceId: String
    isDeviceRegistered: Boolean
    patient: Patient
    devicePrompt: Boolean
    userLoggedIn: Boolean
    currentPatientDetails: CURRENT_PATIENT
  }

  extend type CURRENT_PATIENT {
    selectedPatientId: String
    selectedDeviceId: String
    isDeviceRegistered: Boolean
  }

  extend type Mutation {
    selectPatient(id: String!): String!
    setDevicePrompt(value: Boolean!): Boolean!
    setUserLoggedIn(value: Boolean!): Boolean!
  }
`;

export const resolvers = {
  Mutation: {
    setUserLoggedIn: (root, args, { cache }) => {
      cache.modify({ data: { userLoggedIn: args.userLoggedIn } });
      return args.userLoggedIn;
    },
    setDevicePrompt: (root, args, { cache }) => {
      cache.writeData({ data: { devicePrompt: args.devicePrompt } });
      return args.devicePrompt;
    },
    selectPatient: (root, args, { cache }) => {
      
      if (!args.isDeviceRegistered) //if null/undefined default to false 
        args.isDeviceRegistered = false;

      cache.writeQuery({
        query: gql`
          query selectPatient {
            selectedPatientId
            selectedDeviceId
            isDeviceRegistered
          }
        `,

        data: { ...args },
      });
      cache.writeQuery({
        query: gql`
          query currentPatientDetails {
            currentPatientDetails
          }
        `,
        data: {
          currentPatientDetails: {
            ...args,
          },
        },
      });
      return args.selectedPatientId;
    },
  },
};

// TODO: considering deleting this safely
export const parseFetchConfigResponse = (text: string) => {
  let obj;
  try {
    obj = JSON.parse(text);
  } catch (e) {
    alert(`Error occurred in reading application configuration.\n${e}`);
  }
  return obj;
};
