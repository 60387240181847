import React from 'react';
import { SvgIcon } from '@mui/material';
let BCheckMark = props => {
  return (
    <SvgIcon
      style={props.style}
      height='200px'
      width='200px'
      className={props.className}
      viewBox='0 0 847 847'
      x='0px'
      y='0px'
      fillRule='evenodd'
      clipRule='evenodd'
    >
      <g>
        <path
          className={props.className}
          d='M423 23c221,0 400,179 400,400 0,221 -179,400 -400,400 -221,0 -400,-179 -400,-400 0,-221 179,-400 400,-400zm-86 469l275 -275c13,-13 33,-13 45,0l36 35c12,13 12,33 0,45l-333 333c-13,12 -33,12 -45,0l-161 -161c-12,-12 -12,-32 0,-45l36 -35c12,-13 32,-13 45,0l102 103z'
        ></path>
      </g>
    </SvgIcon>
  );
};

export default BCheckMark;
