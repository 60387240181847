import {
  ColsType,
  EVENT_LIST_COLUMNS,
  finalPermissions,
  PATIENT_LIST_COLUMNS,
  SUPERSET_HISTORY_FILTER_LIST,
  SUPERSET_NAVIGATION_MENU,
} from './constants';
import { ROUTE_COMPONENTS } from './permissions_components';
import {
  HISTORY_ALL_RECORDS,
  HISTORY_CEMSUMMARY_REPORT,
  HISTORY_DEVICE,
  HISTORY_EVENT_REPORT,
  HISTORY_EVENTS,
  HISTORY_NOTE,
  HISTORY_PROFILE,
  HISTORY_SETTINGS,
  IUserAccessObject,
  IUserAccessPermissions,
  IUserAccessRole,
} from './types';

const getColsFromList = (colnames, collist) => {
  return colnames.map(name => collist[name]);
};

// Patient List and Event Queue Columns access
// keys are named such that it can be retrieved by <type of list>_<type of access>
const COLUMNS_PER_ACCESS = {
  PATIENT_ClinicianAccess: [
    'name',
    'date_of_birth',
    'patient_identifier',
    'prescribing_provider',
    'tdevice_status',
    'history',
    'age_sex',
    'indications',
    'tmct_ID',
    'organization',
    'application',
  ],
  PATIENT_MCTTechnicianAccess: [
    'name',
    'date_of_birth',
    'patient_identifier',
    'prescribing_provider',
    'technician_notes',
    'tdevice_status',
    'history',
    'tviewer_due',
    'age_sex',
    'indications',
    'tmct_ID',
    'organization',
    'application',
  ],
  EVENT_ClinicianAccess: [], // clinician does not have access to event queue
  EVENT_MCTTechnicianAccess: [
    'current_status',
    'tevents',
    'tevent_date',
    'notification_date',
    'tviewer',
    'name',
    'date_of_birth',
    'tmct_ID',
    'patient_identifier',
    'history',
    'prescribing_provider',
    'technician_notes',
    'tdevice_status',
    'age_sex',
    'indications',
    'organization',
  ],
};

// Accesses other than the Patient list, Event Queue columns
export const USER_ACCESS_BY_PERMISSION: { [k in IUserAccessPermissions] } = {
  MCTTechnicianAccess: {
    canAccessTechNotes: true,
    canUpdatePatient: true,
    canUpdateSettings: true,
    canRegisterDevice: true,
    canLaunchECGViewer: true,
    canDeactivateDevice: true,
    canPublishInitialReport: true,
    canViewCCReviewDue: true,
    canApproveReports: false,
    canViewReports: true,
    navigationMenu: [
      SUPERSET_NAVIGATION_MENU.PATIENT_LIST,
      SUPERSET_NAVIGATION_MENU.EVENT_QUEUE,
      SUPERSET_NAVIGATION_MENU.CURRENT_PATIENT,
    ],
    historyFilter: [
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_ALL_RECORDS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENTS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_NOTE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENT_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_CEMSUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_DEVICE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SETTINGS],
    ],
    routeList: [
      ROUTE_COMPONENTS.events,
      ROUTE_COMPONENTS.patients,
      ROUTE_COMPONENTS.patient,
      ROUTE_COMPONENTS.reports,
    ],
  },
  ClinicianAccess: {
    canAccessTechNotes: false,
    canUpdatePatient: true,
    canUpdateSettings: false,
    canRegisterDevice: true,
    canLaunchECGViewer: false,
    canDeactivateDevice: true,
    canPublishInitialReport: false,
    canViewCCReviewDue: false,
    canApproveReports: true,
    canViewReports: true,
    navigationMenu: [
      SUPERSET_NAVIGATION_MENU.PATIENT_LIST,
      SUPERSET_NAVIGATION_MENU.NEW_PATIENT,
      SUPERSET_NAVIGATION_MENU.CURRENT_PATIENT,
    ],
    historyFilter: [
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_ALL_RECORDS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENT_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_CEMSUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_DEVICE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SETTINGS],
    ],
    routeList: [
      ROUTE_COMPONENTS.patients,
      ROUTE_COMPONENTS.patient,
      ROUTE_COMPONENTS.reports,
    ],
  },
};

// Accesses specific to the role
export const USER_ACCESS_BY_ROLE: { [k in IUserAccessRole] } = {
  mctECGTech: {
    canDeactivateDevice: true,
    canDeletePatient: false,
    canDeleteDevice: false,
  },
  clinician: {
    canDeactivateDevice: true,
    canDeletePatient: false,
    canDeleteDevice: false,
  },
  system_admin: {
    canDeactivateDevice: true,
    canDeletePatient: true,
    canDeleteDevice: true,
  },
  org_admin: {
    canDeactivateDevice: false,
    canDeletePatient: false,
    canDeleteDevice: false,
  },
  distributor_admin: {
    canDeactivateDevice: false,
    canDeletePatient: false,
    canDeleteDevice: true,
  },
  qc_user: {
    canDeactivateDevice: true,
    canDeletePatient: false,
    canDeleteDevice: false,
  },
};

export const getColumnsForPatientEventQueue = (type, permissions): ColsType => {
  let colDetails: ColsType;
  let colsArray = [];

  for (let perm = 0; perm < permissions.length; perm++) {
    colsArray = colsArray.concat(
      COLUMNS_PER_ACCESS[type + '_' + permissions[perm]]
    );
  }
  let colSet = new Set(colsArray);

  colDetails = {
    columns: getColsFromList(
      Array.from(colSet),
      type === 'EVENT' ? EVENT_LIST_COLUMNS : PATIENT_LIST_COLUMNS
    ),
    density: 'standard',
    pageSize: 20,
  };
  return colDetails;
};

export const getFinalPermissions = (
  role: string,
  permissions: string[]
): IUserAccessObject => {
  const booleanFields = [
    'canAccessTechNotes',
    'canUpdatePatient',
    'canUpdateSettings',
    'canRegisterDevice',
    'canLaunchECGViewer',
    'canDeactivateDevice',
    'canPublishInitialReport',
    'canViewCCReviewDue',
    'canApproveReports',
    'canViewReports',
    'canDeletePatient',
    'canDeleteDevice',
  ];

  const permission_objects = permissions.map(p => USER_ACCESS_BY_PERMISSION[p]); // Array
  const role_object = role ? USER_ACCESS_BY_ROLE[role] : null;

  const all_access_objs = permission_objects;

  all_access_objs.forEach(access_obj => {
    if (!access_obj) return; // role may be null

    // Keep any existing functionality,
    // or enable it if the next role/permission grants it
    booleanFields.forEach(field => {
      finalPermissions[field] = finalPermissions[field] || access_obj[field];
    });

    // Navigation Menu - create union set of all menu items, then convert to an array
    const navigation_curr = access_obj.navigationMenu.map(
      menu_item => menu_item.id
    );
    const navigation_all = finalPermissions.navigationMenu.map(
      menu_item => menu_item.id
    );
    const navigation_union_set = new Set([
      ...navigation_curr,
      ...navigation_all,
    ]);
    const navigation_array = Array.from(navigation_union_set).map(
      k => SUPERSET_NAVIGATION_MENU[k.toUpperCase()]
    );

    finalPermissions.navigationMenu = navigation_array;

    //  History Filter - create union set of all history filters, then convert to an array
    const history_curr = access_obj.historyFilter.map(
      menu_item => menu_item.value
    );
    const history_all = finalPermissions.historyFilter.map(
      menu_item => menu_item.value
    );
    const history_union_set = new Set([...history_curr, ...history_all]);
    const history_array = Array.from(history_union_set).map(
      k => SUPERSET_HISTORY_FILTER_LIST[k]
    );

    finalPermissions.historyFilter = history_array;

    //  Route List - create union set of all route components, then convert to an array
    const route_curr = access_obj.routeList.map(route => route.id);
    const route_all = finalPermissions.routeList.map(route => route.id);
    const route_union_set = new Set([...route_curr, ...route_all]);
    const route_array = Array.from(route_union_set).map(
      k => ROUTE_COMPONENTS[k]
    );

    finalPermissions.routeList = route_array;
  });

  // Add delta for user role
  if (['org_admin', 'distributor_admin', 'system_admin'].includes(role)) {
    finalPermissions.navigationMenu.push(SUPERSET_NAVIGATION_MENU['ADMIN']);
  }

  if (['org_admin', 'distributor_admin', 'system_admin'].includes(role)) {
    finalPermissions.routeList.push(ROUTE_COMPONENTS['admin']);
  }
  if (role && role_object) {
    for (const [key, value] of Object.entries(role_object)) {
      finalPermissions[key] = value;
    }
  }

  finalPermissions.routeList = finalPermissions.routeList.map(r => r.component);
  return finalPermissions as IUserAccessObject;
};
