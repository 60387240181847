import gql from 'graphql-tag';
import { useMutation } from '@apollo/client'
import { useContext } from 'react';
import { AccessPermissionsContext } from '../../auth/useAccessPermissions';

// mutation
export const SEND_AUDIT_TRAIL_LOG_MESSAGE = gql`
  mutation BDMWebSendAuditTrailLogMessage($input: AuditTrailLogInputType) {
    sendAuditTrailLogMessage(input: $input)
  }
`;

// custom hook that's used in other react components
export const useSendAuditTrailLogMessage = () => {
  const [sendAuditTrailLogMessage] = useMutation(SEND_AUDIT_TRAIL_LOG_MESSAGE);
  const username = useContext(AccessPermissionsContext)?.name;
  const sendAuditMessage = async (message) => {
    if (!message?.component_name) message.component_name = "BDM_WEB"; // default to BDM Web's enum
    if (!message?.timestamp) message.timestamp = new Date().toISOString(); // default to Now()
    if (!message?.source)  message.source = username; // default to logged in user's name
    if (!message?.device_id) message.device_id = ""; // default to empty string
    if (!message?.organization_id) message.organization_id = ""; // default to empty string
    sendAuditTrailLogMessage({ variables: { input: message } })
    .then(response => {
      //console.log("SendAuditTrailLogMessage response: ", response);
    })
    .catch(error => {
      console.error("SendAuditTrailLogMessage error: ", error);
    })
  }
  return { sendAuditMessage };
}