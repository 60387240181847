import React from 'react';
import { SvgIcon } from '@mui/material';
let BIconReports = props => {
  return (
    <SvgIcon
      fill='#fff'
      style={Object.assign(props.style, {
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
      })}
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 52.742 77.149'
    >
      <g>
        <path
          className={props.className}
          d='M35.74 10.796l0 -4.839 -3.412 0c-0.857,0 -1.552,-0.695 -1.552,-1.552 0,-1.216 -0.493,-2.318 -1.29,-3.115l0 -0.006c-0.793,-0.793 -1.894,-1.284 -3.115,-1.284 -1.217,0 -2.318,0.493 -3.115,1.29 -0.797,0.797 -1.291,1.899 -1.291,3.115 0,0.857 -0.694,1.552 -1.551,1.552l-3.413 0 0 4.839 18.739 0zm1.552 3.103l-21.842 0c-0.857,0 -1.551,-0.695 -1.551,-1.552l0 -2.419 -13.899 0 0 67.221 52.742 0 0 -67.221 -13.899 0 0 2.419c0,0.857 -0.695,1.552 -1.551,1.552zm-20.786 18.964l8.072 6.8 11.47 -13.547c0.552,-0.652 1.529,-0.733 2.181,-0.181 0.653,0.552 0.734,1.529 0.182,2.181l-12.468 14.72c-0.549,0.649 -1.521,0.731 -2.172,0.184l-9.252 -7.794c-0.653,-0.549 -0.737,-1.523 -0.188,-2.175 0.548,-0.653 1.522,-0.737 2.175,-0.188zm-6.165 21.369l32.059 0c0.857,0 1.552,0.695 1.552,1.551 0,0.857 -0.695,1.552 -1.552,1.552l-32.059 0c-0.857,0 -1.551,-0.695 -1.551,-1.552 0,-0.856 0.694,-1.551 1.551,-1.551zm-1.551 12.575c0,-0.856 0.694,-1.551 1.551,-1.551l32.059 0c0.857,0 1.552,0.695 1.552,1.551 0,0.857 -0.695,1.552 -1.552,1.552l-32.059 0c-0.857,0 -1.551,-0.695 -1.551,-1.552z'
        ></path>
      </g>
    </SvgIcon>
  );
};

export default BIconReports;
