import React from 'react';
import { SvgIcon } from '@mui/material';
const BEventNotification = props => {
  return (
    <SvgIcon
      style={props.style}
      className={props.className}
      viewBox='0 0 46 46'
    >
      <g>
        <path
          className={props.className}
          d='M32.67,1.8c-7.9,0.9-9.52,7.42-9.67,8.1c-0.15-0.68-1.77-7.2-9.67-8.1C7.65,1.15-1.33,4.35,0.17,17.6
		c1.9,16.76,22.04,26.3,22.82,26.66v0.01c0,0,0.01,0,0.01-0.01c0.01,0,0.01,0.01,0.01,0.01v0v-0.01c0.78-0.36,20.92-9.9,22.82-26.66
		C47.33,4.35,38.35,1.15,32.67,1.8z M30.94,25.11c0,0.13-0.11,0.24-0.24,0.24h-5.11c-0.13,0-0.24,0.11-0.24,0.24v5.11
		c0,0.13-0.11,0.24-0.24,0.24h-4.23c-0.13,0-0.24-0.11-0.24-0.24v-5.11c0-0.13-0.11-0.24-0.24-0.24H15.3
		c-0.13,0-0.24-0.11-0.24-0.24v-4.23c0-0.13,0.11-0.24,0.24-0.24h5.11c0.13,0,0.24-0.11,0.24-0.24V15.3c0-0.13,0.11-0.24,0.24-0.24
		h4.23c0.13,0,0.24,0.11,0.24,0.24v5.11c0,0.13,0.11,0.24,0.24,0.24h5.11c0.13,0,0.24,0.11,0.24,0.24V25.11z'
        />
      </g>
    </SvgIcon>
  );
};

export default BEventNotification;
