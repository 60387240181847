import React from 'react';

export interface IConfig {
  apiEndpointUrl: string;
  ecgApiEndpointUrl: string;
}

const defaultConfig: IConfig = {
  apiEndpointUrl: '',
  ecgApiEndpointUrl: '',
};

const Config = React.createContext<IConfig>(defaultConfig);

export default Config;
