import { Grid } from '@mui/material';

export interface IOrgContactInformation {
  orgName?: String;
  dayTimePhone?: String;
  dayTimeRange?: String;
  afterHoursPhone?: String;
  afterHoursRange?: String;
  fax?: String;
  prescribingProviderName?: String;
  prescribingProviderPhone?: String;
  prescribingProviderEmail?: String;
  sideBySide?: Boolean;
}

const OrgContactInformation = ({
  orgName,
  dayTimePhone,
  dayTimeRange,
  afterHoursPhone,
  afterHoursRange,
  fax,
  prescribingProviderEmail,
  prescribingProviderPhone,
  prescribingProviderName,
  sideBySide,
}: IOrgContactInformation) => {
  return (
    <Grid container className='padded-container'>
      <Grid item xs={sideBySide ? 5 : 12}>
        <Grid container spacing={1}>
          <Grid item xs={12} className='org-contact-header'>
            Prescribing Provider
          </Grid>
          <Grid item xs={3} className='org-contact-subheader'>
            Name:
          </Grid>
          <Grid item xs={9}>
            {prescribingProviderName}
          </Grid>
          <Grid item xs={3} className='org-contact-subheader'>
            Phone:
          </Grid>
          <Grid item xs={9} className='org-contact-underline'>
            {prescribingProviderPhone}
          </Grid>
          <Grid item xs={3} className='org-contact-subheader'>
            Email:
          </Grid>
          <Grid item xs={9} className='org-contact-underline'>
            {prescribingProviderEmail}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={sideBySide ? 7 : 12}>
        <Grid
          container
          spacing={1}
          className={sideBySide ? '' : 'margin-organization'}
        >
          <Grid item xs={12} className='org-contact-header'>
            Organization
          </Grid>
          <Grid item xs={sideBySide ? 4 : 3} className='org-contact-subheader'>
            Name:
          </Grid>
          <Grid item xs={sideBySide ? 8 : 9}>
            {orgName}
          </Grid>
          <Grid item xs={sideBySide ? 4 : 3} className='org-contact-subheader'>
            Daytime Phone:
          </Grid>
          <Grid item xs={sideBySide ? 8 : 9}>
            <span className='org-contact-underline'> {dayTimePhone}</span>
            <span className='org-contact-time-range'> {dayTimeRange}</span>
          </Grid>
          <Grid item xs={sideBySide ? 4 : 3} className='org-contact-subheader'>
            After hours Phone:
          </Grid>
          <Grid item xs={sideBySide ? 8 : 9}>
            <span className='org-contact-underline'> {afterHoursPhone}</span>
            <span className='org-contact-time-range'> {afterHoursRange}</span>
          </Grid>
          <Grid item xs={sideBySide ? 4 : 3} className='org-contact-subheader'>
            Fax:
          </Grid>
          <Grid item xs={sideBySide ? 8 : 9} className='org-contact-underline'>
            {fax}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrgContactInformation;
