import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
  Box,
  Typography,
} from '@mui/material';
import { BSpinnerReports } from '../../images/icons';

interface IBDialog {
  children?: React.ReactNode;
  style?: any;
  open?: boolean;
  setOpen?: any;
  title?: string;
  message?: string;
  spinner?: boolean;
}

const BDialog = (props: IBDialog) => {
  const { children, style, open, setOpen, title, message, spinner } = props;
  return (
    <>
      <Box sx={{ style }}>
        <Backdrop open={open} />
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          {title && (
            <DialogTitle>
              <Typography>{title}</Typography>
            </DialogTitle>
          )}
          <DialogContent>
            <Typography
              sx={{
                color: 'var(--grey-grey-900-text-color, #1A1C1C)',
                textAlign: 'center',
                fontFamily: 'Geogrotesque',
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '32px',
                width: '432px',
              }}
            >
              {message}
            </Typography>
            {spinner && (
              <Box display='flex' justifyContent='center' alignItems='center'>
                <BSpinnerReports
                  sx={{
                    width: '48px',
                    height: '48px',
                    marginTop: '48px',
                    marginBottom: '45px',
                    flexShrink: 0,
                  }}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions disableSpacing>{children}</DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default BDialog;
