import React from 'react';
import { SvgIcon } from '@mui/material';
let Contact = props => {
  return (
    <SvgIcon
      style={props.style}
      height='100px'
      width='100px'
      className={props.className}
      viewBox='0 0 100 100'
      x='0px'
      y='0px'
    >
      <g>
        <path d='M28.7801 5.98469c3.63782,-1.62757 5.17799,-0.940165 9.01424,-0.0720478 1.28269,0.566934 2.26419,1.39253 2.50632,2.63152 1.43151,7.31817 1.64765,13.7871 1.33348,20.4982 -0.185435,3.98507 -5.99769,6.07918 -9.04495,8.65519 0.549217,11.5395 10.082,28.1341 19.7954,34.2865 3.75475,-1.35237 8.4733,-5.33744 12.0178,-3.50672 5.96816,3.08388 11.4627,6.50556 17.0848,11.4048 0.951976,0.82914 1.17639,2.09175 1.02639,3.48546 -1.16694,3.75712 -1.45041,5.29138 -4.56972,7.77171 -30.1313,23.9577 -84.0798,-69.5356 -49.1638,-85.1546z'></path>
      </g>
    </SvgIcon>
  );
};

export default Contact;
