import { useContext } from 'react';
import ErrorCard from './ErrorCard';
import { ErrorProviderContext } from './ErrorProvider';
import messageFormat from '../../utility/messageToUser.json';

const ErrorContainer = () => {
  const {errorCodes, removeErrorCode, isSuppressingErrors} = useContext(ErrorProviderContext)

  // test team specifically asked to suppress errors for screenshot purposes
  if (isSuppressingErrors && errorCodes.length > 0) return (
    <div style={{color:'red'}}>
      <p> {errorCodes.length} errors suppressed</p>
    </div>
  )

  return (
    <div className={`error-container ${errorCodes.length ? 'open' : ''}`}>
      {errorCodes.map((errorCode, index) => (
        <ErrorCard
          key={index}
          title={messageFormat.errorCard.title}
          description={messageFormat.errorCard.description}
          errorCode={errorCode}
          onClose={() => removeErrorCode(index)}
        />
      ))}
    </div>
  );
};

export default ErrorContainer;