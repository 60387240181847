import Checkbox from '@mui/material/Checkbox';

const BCheckbox = props => {
  return (
    <Checkbox
      style={props.style}
      id={props.id}
      name={props.name}
      color={props.color || 'default'}
      checked={props.checked}
      onChange={props.handleChange}
      inputProps={{ 'aria-label': 'default checkbox' }}
    />
  );
};

export default BCheckbox;
