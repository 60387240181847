// External Libraries
import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  AppBar,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Avatar,
  Grid,
  Typography,
  TableCell,
} from '@mui/material';

// Internal Libraries
import {
  generateID,
  getEventIconStyles,
  getEventStyles,
  getPrettyDate,
} from './../../utility/utilityFunctions';
import { formECGViewerURL } from './../../utility/helper/patientlist/logicPatientList';
import BIcon from './../common/BIcon';
import BStyledTableRow from './../common/BStyledTableRow';
import { DF_SDSDSD_C_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA } from './../../types/types';
import Config, { IConfig } from './../../types/Config';
import { ICONS } from '../../utility/IconSelection';
import BButton from '../common/BButton';
import { PLDeviceEventGroupStatus } from '../../utility/helper/patientlist/plcomponents';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { CloseRounded } from '@mui/icons-material';
import OrgContactInformation from '../organization/OrgContactInformation';
import { AccessPermissionsContext } from '../../auth/useAccessPermissions';
import { useSendAuditTrailLogMessage } from '../../data/queries/auditTrailLogMessage';

const PatientEventNotification = props => {
  const endDate = props.endTime;
  const [toggleOrgContact, setToggleOrgContact] = useState(false);
  const config = React.useContext<IConfig>(Config);
  const eventThresholdObj = props.eventThresholdObj || {};
  const USER_ACCESS = React.useContext(AccessPermissionsContext)?.access;
  const { canAccessTechNotes } = USER_ACCESS;
  const { sendAuditMessage } = useSendAuditTrailLogMessage();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className='notification-header'>
            <Grid item xs={11} style={{ padding: '10px' }}>
              <Typography>
                {props.lastName}
                {', ' + props.firstName}&nbsp;{' '}
                {getPrettyDate(
                  endDate,
                  DF_SDSDSD_C_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA,
                  true
                )}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
                paddingRight: '10px',
              }}
              xs={1}
              className='notification-close-parent'
            >
              <CloseRounded
                id={generateID(
                  'icon',
                  'esumwin',
                  `close_${props.deviceEventId}`
                )}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  props.clickHandler();
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid
              className='notification-subheader'
              item
              xs={12}
              style={{ padding: '10px' }}
            >
              <Typography>
                {props.age + ':' + props.sex[0].toUpperCase()}{' '}
                {props.indications}
                <span
                  onClick={() => {
                    setToggleOrgContact(prev => !prev);
                  }}
                  className='org-contact-link'
                >
                  Clinical Contact
                  {toggleOrgContact ? (
                    <KeyboardArrowUp style={{ verticalAlign: 'middle' }} />
                  ) : (
                    <KeyboardArrowDown style={{ verticalAlign: 'middle' }} />
                  )}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {toggleOrgContact ? (
                <Grid container>
                  <Grid item>
                    <OrgContactInformation
                      sideBySide={true}
                      {...props.orgContactDetails}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            {canAccessTechNotes ? (
              <Grid
                item
                xs={12}
                style={{
                  padding: '10px',
                }}
              >
                <Typography>
                  {/* TODO: Get icon from UX Team */}
                  <BIcon
                    style={{ verticalAlign: 'middle' }}
                    icon={ICONS['NOTES']}
                  />{' '}
                  {props.technicianNotes}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size='small'>
              <colgroup>
                <col width={'20%'} />
                <col width={'60%'} />
              </colgroup>
              <TableBody>
                <tr style={{ background: 'lightgray' }}>
                  <TableCell align='right'></TableCell>
                  <TableCell align='left'>Event Criteria</TableCell>
                </tr>
                {props.eventList.map((row, index) => {
                  return (
                    <BStyledTableRow key={index}>
                      <TableCell
                        align='right'
                        style={{ display: 'flex', justifyContent: 'right' }}
                      >
                        {row.type !== 'string' ? (
                          <BIcon
                            icon={props.icons[row.type]}
                            fontSize='large'
                            style={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: '2.0rem',
                              },
                              getEventIconStyles(row.severity)
                            )}
                          />
                        ) : (
                          <>
                            {
                              <Avatar
                                variant='rounded'
                                sx={getEventStyles('events', row.severity)}
                                style={{ display: 'flex', alignItems: 'right' }}
                              >
                                {row.abbr}
                              </Avatar>
                            }
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {eventThresholdObj[row.abbr]?.map(item => {
                          return (
                            <div>
                              {item.deviceEventSettingThresholdType.name}{' '}
                              {item.value}
                            </div>
                          );
                        })}
                      </TableCell>
                    </BStyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              position: 'relative',
              marginBottom: '5px',
            }}
          >
            <div
              style={{
                flex: 1,
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PLDeviceEventGroupStatus current_status={props.current_status} />
            </div>
            <div style={{ marginRight: '10px' }}>
              <BButton
                caption='Settings'
                clickEvent={() =>
                  props.gotoPatientRegistration(
                    'Settings',
                    props.patientId,
                    props.deviceSettingId,
                    props.registered
                  )
                }
              />
              <span className='space-right'></span>
              <BButton
                caption='ECG Viewer'
                id={generateID(
                  'btn',
                  'esumwin',
                  `ecg_viewer_${props.deviceEventId}`
                )}
                clickEvent={() => {
                  if (typeof window !== 'undefined') {
                    window.open(
                      formECGViewerURL(config.ecgApiEndpointUrl, props.deviceId)
                    );
                    sendAuditMessage({
                      action: 'DATA_ACCESS',
                      phi_flag: true,
                      device_id: props.deviceId,
                      description: `User clicked button to open ${formECGViewerURL(config.ecgApiEndpointUrl, props.deviceId)}`,
                    });
                  }
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PatientEventNotification;
