import { CircularProgress } from '@mui/material';

interface IProps {
  sx?: any;
  className?: string;
}
const BSpinnerReports = ({ sx }: IProps) => {
  return <CircularProgress sx={sx} color='inherit' size={30} thickness={4} />;
};

export default BSpinnerReports;
