import React from 'react';
import { SvgIcon } from '@mui/material';
let ClinicalNotes = props => {
  return (
    <SvgIcon
      height='auto'
      width='auto'
      className={props.className}
      style={Object.assign(props.style, {})}
      viewBox='0 0 70 70'
      x='0'
      y='0'
    >
      <g>
        <path d='M59,24.3l-2.8-2.8c-0.9-0.9-2.4-0.9-3.3,0l-1.4,1.4c0,0,0,0,0.1,0l5.8,6.1c0,0,0.1,0.1,0.1,0.1l1.6-1.6   C59.9,26.7,59.9,25.2,59,24.3z'></path>
        <path d='M43.3,45.7l-7.2,2.5c-0.3,0.1-0.6,0.2-1,0.2c-0.8,0-1.5-0.3-2.1-0.8c-0.3-0.3-0.6-0.7-0.7-1.2H16.9c-0.6,0-1-0.4-1-1   s0.4-1,1-1h15.4c0,0,0,0,0,0l1.7-4.9H16.9c-0.6,0-1-0.4-1-1s0.4-1,1-1h17.8l0.1-0.3c0.2-0.5,0.4-0.9,0.8-1.2l3.4-3.4H16.9   c-0.6,0-1-0.4-1-1s0.4-1,1-1H41l7.6-7.6l0,0l1.8-1.8V14c0-1.7-1.3-3-3-3h-32c-1.7,0-3,1.3-3,3v42c0,1.7,1.3,3,3,3h32   c1.7,0,3-1.3,3-3V39.1L44.5,45C44.2,45.3,43.8,45.5,43.3,45.7z M16.9,16.8h24.9c0.6,0,1,0.4,1,1s-0.4,1-1,1H16.9c-0.6,0-1-0.4-1-1   S16.3,16.8,16.9,16.8z M16.9,23.6h24.9c0.6,0,1,0.4,1,1s-0.4,1-1,1H16.9c-0.6,0-1-0.4-1-1S16.3,23.6,16.9,23.6z M41.8,53.3H16.9   c-0.6,0-1-0.4-1-1s0.4-1,1-1h24.9c0.6,0,1,0.4,1,1S42.4,53.3,41.8,53.3z'></path>
        <path d='M36.9,37.4c-0.1,0.1-0.2,0.2-0.2,0.4L34.2,45c-0.1,0.4,0,0.8,0.2,1c0.2,0.2,0.5,0.3,0.7,0.3c0.1,0,0.2,0,0.3-0.1l7.2-2.5   c0.1,0,0.3-0.1,0.4-0.2L55.6,31l-5.9-6.3L36.9,37.4z'></path>
      </g>
    </SvgIcon>
  );
};

export default ClinicalNotes;
