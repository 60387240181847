import React, { useState, useEffect } from 'react';
import {
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { Box, TextFieldProps } from '@mui/material';
import BTextField from '../common/BTextField';
import { generateID } from '../../utility/utilityFunctions';

const DateFilterComponent = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;
  const [filterValueState, setFilterValueState] = useState<[string, string]>(
    item.value ?? ''
  );

  useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound: string, upperBound: string) => {
    setFilterValueState([lowerBound, upperBound]);
    applyValue({ ...item, value: [lowerBound, upperBound] });
  };
  const handleUpperFilterChange: TextFieldProps['onChange'] = event => {
    const newUpperBound = event.target.value;
    updateFilterValue(filterValueState[0], newUpperBound);
  };

  const handleLowerFilterChange: TextFieldProps['onChange'] = event => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      style={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        width: 300,
      }}
    >
      <BTextField
        id={generateID('input', 'pl_filter', 'report_date_range')}
        type='Date'
        label='Date From'
        name='datefrom'
        value={filterValueState[0]}
        shrink={true}
        fullWidth={true}
        handleChange={handleLowerFilterChange}
        max='3000-12-12'
        style={{ display: 'inline-flex' }}
        variant='standard'
      />

      <BTextField
        id={generateID('input', 'pl_filter', 'report_date_from')}
        type='Date'
        label='Date To'
        name='dateto'
        fullWidth={true}
        shrink={true}
        value={filterValueState[1]}
        handleChange={handleUpperFilterChange}
        max='3000-12-12'
        style={{ display: 'inline-flex', paddingLeft: '10px' }}
        variant='standard'
      />
    </Box>
  );
};

const dateRangeOperators: GridFilterOperator[] = [
  {
    label: 'Between',
    value: 'between',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      return ({ value }) => {
        return (
          value !== null &&
          filterItem.value[0] <= value &&
          value <= filterItem.value[1]
        );
      };
    },
    InputComponent: DateFilterComponent,
  },
];

export default dateRangeOperators;
