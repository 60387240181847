import React from 'react';
import { isEmpty } from 'lodash';
import BToastMessage from '../../common/ToastMessage';
import BSaveCancel from '../../common/BSaveCancel';
import { generateID } from '../../../utility/utilityFunctions';

const ThresholdSettingsHeaderFooter = ({
  style,
  savedChanges,
  setSavedChanges,
  changedEvents,
  getChangedEventAbbr,
  values,
  dirty,
  errors,
  setSettingUpdatesConfirm,
  setUnsaveConfirm,
  getUpdatedValues,
  gotoHome,
}) => {
  return (
    <>
      <BToastMessage
        id={generateID('toast', 'pr_settings', 'notification_msg_down')}
        open={savedChanges.apiCall}
        close={() => {
          setSavedChanges({ apiCall: false, type: '', msg: '' });
        }}
        style={style}
        severity={savedChanges.type}
        msg={savedChanges.msg}
      />

      <BToastMessage
        id={generateID('toast', 'pr_settings', 'msg_changedevents_down')}
        open={!isEmpty(changedEvents)}
        style={style}
        severity='info'
        msg={getChangedEventAbbr()}
      />
      <span>
        <BSaveCancel
          saveDisabled={!dirty || !isEmpty(errors)}
          saveId={generateID('btn', 'pr_settings', 'save_btm')}
          cancelId={generateID('btn', 'pr_settings', 'cancel_btm')}
          saveEvent={e => {
            e.preventDefault();
            setSettingUpdatesConfirm({
              open: true,
              data: getUpdatedValues(values),
            });
          }}
          cancelEvent={() => {
            if (dirty) {
              setUnsaveConfirm({ open: true });
            } else {
              gotoHome();
            }
          }}
        />
      </span>
    </>
  );
};

export default ThresholdSettingsHeaderFooter;
