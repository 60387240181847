import React from 'react';
import { SvgIcon } from '@mui/material';
const BEventReport = props => {
  return (
    <SvgIcon
      viewBox='0 0 46 64'
      style={Object.assign(props.style, {})}
      fillRule='evenodd'
      clipRule='evenodd'
    >
      <polygon
        className={props.className}
        points='31.99 1.79 31.99 14.08 44.24 14.08 31.99 1.79'
      />
      <path
        className={props.className}
        d='M26.82,20a.8.8,0,0,1-.8-.8V0H9A9,9,0,0,0,0,9V55a9,9,0,0,0,9,9H37a9,9,0,0,0,9-9V24.31h0V20Zm8.1,14c-1,8.76-11.51,13.74-11.91,13.93h0c-.4-.19-10.92-5.17-11.91-13.93C10.29,27.07,15,25.41,18,25.75A5.6,5.6,0,0,1,23,30a5.6,5.6,0,0,1,5.05-4.22C31,25.41,35.71,27.07,34.92,34Z'
      />
    </SvgIcon>
  );
};

export default BEventReport;
