import gql from 'graphql-tag';

export const ORGANIZATIONS_LIST = gql`
query Get_Organizations_ALL {
    organizations {
      id
      name
    }
}
`;

export const SETTINGS = gql`
  # Patient specific settings
  query GetPatientSettings(
    $device_id: String!
    $organization_id: String!
    $device_type: String!
  ) {
    settingGroups(
      organization_id: $organization_id
      device_type: $device_type
    ) {
      id
      name
      organization_id
    }
    settings: deviceEventSettings(
      device_id: $device_id
      filter: {
        _and: [
          {
            columnField: "deviceEventSettingType.configurable"
            operatorValue: "equals"
            value: "True"
          }
        ]
      }
    ) {
      id
      severity
      settingType: deviceEventSettingType {
        id
        name
        abbr
        abbr_type
        display_order
      }
      severity
      thresholds: notifiableDeviceEventSettingThresholds {
        id
        thresholdType: deviceEventSettingThresholdType {
          id
          min
          max
          name
          display_order
        }
        value
      }
    }
  }
`;

// Threshold settings details - fetch the threshold template details to patient
export const THRESHOLD_PATIENT_SETTINGS = gql`
  query SettingPatientGroups($device_id: String!) {
    settings: deviceEventSettings(setting_group_id: $device_id) {
      settingType: deviceEventSettingType {
        settingTypeId: id
      }
      severity
      thresholds: notifiableDeviceEventSettingThresholds {
        value
        thresholdType: deviceEventSettingThresholdType {
          thresholdTypeId: id
        }
      }
    }
  }
`;
// Get Org id
export const GET_ORG_ID = gql`
  query GetOrgID {
    organization {
      id
    }
  }
`;

// Threshold settings for Organization Admin
export const ADMIN_SETTINGS = gql`
  query GetAdminSettings(
    $organization_value: String!
    $organization_id: String!
    $device_type: String!
  ) {
    settingGroups(or: $organization_value, device_type: $device_type, organization_id: $organization_id) {
      id #TODO remove hard coded id once integrated with bdxconnect
      name
      organization_id
      organization_default
      global_default
      settings: deviceEventSettings {
        id
        severity
        settingType: deviceEventSettingType {
          id
          name
          abbr
          abbr_type
          display_order
        }
        thresholds: notifiableDeviceEventSettingThresholds {
          id
          thresholdType: deviceEventSettingThresholdType {
            id
            min
            max
            name
          }
          value
        }
        threshold_report: reportableDeviceEventSettingThresholds {
          id
          thresholdType: deviceEventSettingThresholdType {
            id
            min
            max
            name
          }
          notification_type
          value
        }
      }
    }
  }
`;

// Threshold settings details for the threshold template
export const THRESHOLD_ADMIN_SETTINGS = gql`
  query SettingGroups($device_id: String!) {
    settings: deviceEventSettings(setting_group_id: $device_id) {
      id
      severity
      settingType: deviceEventSettingType {
        id
        name
        abbr
        abbr_type
        display_order
      }
      severity
      thresholds: notifiableDeviceEventSettingThresholds {
        id
        thresholdType: deviceEventSettingThresholdType {
          id
          min
          max
          name
        }
        value
      }
      threshold_report: reportableDeviceEventSettingThresholds {
        id
        thresholdType: deviceEventSettingThresholdType {
          id
          min
          max
          name
        }
        notification_type
        value
      }
    }
  }
`;

// Patient settings update
export const UPDATE_PATIENT_SETTINGS = gql`
  mutation updatePatientSettings(
    $patientId: String!
    $deviceId: String!
    $settings: [PatientSettingsUpdateType]
    $notes: String
    $pinned: Boolean
  ) {
    updatePatientSettings(
      patientId: $patientId
      deviceId: $deviceId
      settings: $settings
      notes: $notes
      pinned: $pinned
    )
  }
`;

// Org admin settings update
export const UPDATE_ORG_SETTINGS = gql`
  mutation updateOrgSettings(
    $setting_group_id: String!
    $settings: [OrgSettingsUpdateType]
    $notes: String
  ) {
    updateSettingGroup(
      setting_group_id: $setting_group_id
      settings: $settings
      notes: $notes
    )
  }
`;

// Org admin create new settings
export const CREATE_ORG_SETTINGS = gql`
  mutation createOrgSettings(
    $organization_id: String!
    $settings: [SettingGroupCreateType]
    $name: String!
    $device_type: String!
  ) {
    createSettingGroup(
      organization_id: $organization_id
      settings: $settings
      name: $name
      device_type: $device_type
    ) {
      id
    }
  }
`;

// Rename admin settings
export const RENAME_THRESHOLD_SETTINGS = gql`
  mutation renameThresholdSettings($group_id: String!, $name: String!) {
    renameSettingGroup(group_id: $group_id, name: $name) {
      id
    }
  }
`;

export const SET_DEFAULT_NEW_PATIENT = gql`
  mutation setDefaultNewPatient(
    $setting_group_id: String!
    $organization_id: String!
    $device_type: String!
  ) {
    setDefaultOrgSettingGroup(
      setting_group_id: $setting_group_id
      org_id: $organization_id
      device_type: $device_type
    )
  }
`;

export const DELETE_THRESHOLD_SETTINGS = gql`
  mutation deleteThresholdSettings($setting_group_id: String!) {
    deleteSettingGroup(setting_group_id: $setting_group_id)
  }
`;
