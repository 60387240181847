import React from 'react';
import { SvgIcon } from '@mui/material';
let BWarning = props => {
  return (
    <SvgIcon
      style={props.style}
      height='200px'
      width='200px'
      className={props.className}
      viewBox='0 0 100 100'
      x='0px'
      y='0px'
    >
      <path d='M80.515,90.781H19.485c-6.145,0-10.779-2.168-13.052-6.103c-2.273-3.938-1.832-9.036,1.24-14.356l30.515-52.851  c3.07-5.321,7.266-8.251,11.811-8.251s8.741,2.93,11.811,8.251l30.515,52.851c3.072,5.32,3.513,10.418,1.24,14.356  C91.293,88.613,86.659,90.781,80.515,90.781z M56.227,72.18c0-3.172-2.572-5.744-5.744-5.744s-5.744,2.572-5.744,5.744  c0,3.172,2.572,5.744,5.744,5.744S56.227,75.352,56.227,72.18z M56.382,37.613c0-3.257-2.641-5.898-5.898-5.898  c-3.257,0-5.898,2.641-5.898,5.898l1.393,20.932h0.019c0.202,2.312,2.121,4.132,4.486,4.132c2.187,0,4.012-1.551,4.434-3.613  c0.034-0.167,0.037-0.345,0.052-0.518h0.04L56.382,37.613z'></path>
    </SvgIcon>
  );
};

export default BWarning;
