import React from 'react';
import { Grid, Typography } from '@mui/material';
import BTextField from '../../common/BTextField';
import { generateID } from '../../../utility/utilityFunctions';
import {
  getFormNameByIds,
  getFormValueByIds,
  getLevelNameById,
} from '../../../utility/helper/settings/SettingOperations';
import BDropDown from '../../common/BDropDown';
import BCheckbox from '../../common/BCheckBox';
import { FIRST_OCCURENCE_OPTIONS } from '../../../types/types';

export const ThresholdLimit = ({
  thresholdType,
  id,
  item,
  errors,
  touched,
  handleBlur,
  initialValues,
  setFieldValue,
  settingType,
  index,
  values,
  actionOnLimitChange,
  hide,
}) => {
  const isFirstOccurence =
    settingType.abbr === 'AF' && item.thresholdType.name === 'First Occurrence';

  const isSlowVT =
    settingType.abbr === 'VT' && item.thresholdType.reference_id === 'slow_vt';

  if (hide) return;

  return (
    <>
      <Grid item md={5} xs={5}>
        <Typography
          style={{ direction: 'rtl', paddingRight: '10px' }}
          id={generateID(
            'txt',
            'pr_settings',
            settingType.abbr + '_limitname_' + index
          )}
        >
          {item.thresholdType.name}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={2}
        style={{ display: 'inline-flex', alignItems: 'center', margin: '4px' }}
      >
        {isFirstOccurence ? (
          <BDropDown
            id={generateID(
              'drpdwn',
              'pr_settings',
              settingType.abbr + '_limit_thresholdType_' + index
            )}
            value={getFormValueByIds(item.id + thresholdType, values)}
            menuItems={FIRST_OCCURENCE_OPTIONS}
            shrink={true}
            fullWidth={false}
            className='setting-textbox'
            inputClassName='setting-limit-inputsize'
            handleChange={e => {
              setFieldValue(
                `${item.id + thresholdType}`,
                parseFloat(e.target.value)
              );
              actionOnLimitChange(
                initialValues[item.id + thresholdType],
                e.target.value
              );
            }}
          />
        ) : isSlowVT ? (
          <BCheckbox
            name={`thresholds.${item.id}`}
            id={generateID('chkbox', 'pr_setting', 'slow_vt')}
            checked={!!getFormValueByIds(item.id + thresholdType, values)}
            handleChange={() => {
              const newVal = !getFormValueByIds(item.id + thresholdType, values)
                ? 1
                : 0;

              setFieldValue(`${item.id + thresholdType}`, newVal);
              actionOnLimitChange(
                initialValues[item.id + thresholdType],
                newVal
              );
            }}
            color={'success'}
            disabled={values[getLevelNameById(id)] === 'off'}
          />
        ) : (
          <BTextField
            id={generateID(
              'input',
              'pr_settings',
              settingType.abbr + '_limit_thresholdType_' + index
            )}
            required={true}
            disabled={values[getLevelNameById(id)] === 'off'}
            type='number'
            name={getFormNameByIds(item.id + thresholdType)}
            errors={
              errors[getFormNameByIds(item.id + thresholdType)]
                ? errors[getFormNameByIds(item.id + thresholdType)]
                : false
            }
            touched={
              touched[getFormNameByIds(item.id + thresholdType)]
                ? touched[getFormNameByIds(item.id + thresholdType)]
                : false
            }
            className='setting-textbox'
            value={getFormValueByIds(item.id + thresholdType, values)}
            handleChange={e => {
              setFieldValue(
                `${item.id + thresholdType}`,
                parseFloat(e.target.value)
              );
              actionOnLimitChange(
                initialValues[item.id + thresholdType],
                e.target.value
              );
            }}
            inputClassName='setting-limit-inputsize'
            handleBlur={handleBlur}
            step={settingType.abbr === 'DATA_TRANSMISSION' ? 0.5 : 1}
          />
        )}
        <Typography
          style={{ marginLeft: '4px', fontSize: '12px' }}
          id={generateID(
            'txt',
            'pr_settings',
            settingType.abbr + '_max_' + index
          )}
        >
          ( {isFirstOccurence ? 'On' : item.thresholdType.min} -{' '}
          {isFirstOccurence ? 'Off' : item.thresholdType.max} )
        </Typography>
      </Grid>
    </>
  );
};
