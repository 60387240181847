import { Grid } from '@mui/material';
import { useEffect, useState, useContext, useMemo } from 'react';
import { GENDER_OPTIONS } from '../../../types/types';
import { generateID } from '../../../utility/utilityFunctions';
import BAutoComplete from '../../common/BAutoComplete';
import BDropDown from '../../common/BDropDown';
import BTextField from '../../common/BTextField';
import BToggleSwitch from '../../common/BToggleSwitch';
import BFormGroupTitle from '../../common/BFormGroupTitle';
import { ICONS } from '../../../utility/IconSelection';
import { EMPTY_SELECT_OPTION } from '../../../types/types';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';

interface IProps {
  errors;
  patient;
  touched;
  handleBlur;
  handleChange;
  organizations;
  setFieldValue;
}
const PatientInformation = ({
  errors,
  patient,
  touched,
  handleBlur,
  handleChange,
  organizations,
  setFieldValue,
}: IProps) => {
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const { canUpdatePatient } = USER_ACCESS;

  const [locationOptions, setLocationOptions] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const emptySelectOption = {
    value: EMPTY_SELECT_OPTION,
    label: 'Please select...',
  };

  const TODAY = useMemo(() => new Date().toISOString().slice(0, 10), []); // today's date in yyyy-mm-dd format

  useEffect(() => {
    const newOrganizationOptions = [
      emptySelectOption,
      ...organizations.map(o => ({
        value: o.id,
        label: o.name,
      })),
    ];

    setOrganizationOptions(newOrganizationOptions);
  }, []);

  useEffect(() => {
    const org = organizations.find(o => o.id === patient.organization_id);
    if (!org) {
      setLocationOptions([]);
      return;
    }

    const newOrganizationOptions = [
      emptySelectOption,
      ...organizations.map(o => ({
        value: o.id,
        label: o.name,
      })),
    ];

    const newLocationOptions = [
      emptySelectOption,
      ...org.locations.map(l => ({
        value: l.id,
        label: l.name,
      })),
    ];

    setOrganizationOptions(newOrganizationOptions);
    setLocationOptions(newLocationOptions);
  }, [organizations, patient.organization_id]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
            backgroundColor: 'rgb(238 238 238)',
          }}
        >
          <BFormGroupTitle
            title='Information'
            icon={ICONS['INFORMATION']}
          ></BFormGroupTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction='row'>
        <Grid item xs={6}>
          <BTextField
            label='First name'
            name='patient.first_name'
            id={generateID('input', 'pr_profile', 'first_name')}
            value={patient.first_name}
            required={true}
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
            errors={errors.patient ? errors.patient.first_name : false}
            touched={touched.patient ? touched.patient.first_name : false}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6}>
          <BTextField
            autoFocus={true}
            label='Last name'
            name='patient.last_name'
            id={generateID('input', 'pr_profile', 'last_name')}
            value={patient.last_name}
            required={true}
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
            errors={errors.patient ? errors.patient.last_name : false}
            touched={touched.patient ? touched.patient.last_name : false}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6}>
          <BTextField
            id={generateID('input', 'pr_profile', 'date_of_birth')}
            type='Date'
            label='Date of Birth'
            name='patient.date_of_birth'
            value={patient.date_of_birth}
            required={true}
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
            errors={errors.patient ? errors.patient.date_of_birth : false}
            touched={touched.patient ? touched.patient.date_of_birth : false}
            handleChange={handleChange}
            handleBlur={handleBlur}
            max={TODAY}
          />
        </Grid>
        <Grid item xs={6}>
          <BDropDown
            id={generateID('drpdwn', 'pr_profile', 'sex')}
            name='patient.sex'
            label='Sex at birth'
            menuItems={GENDER_OPTIONS}
            value={patient.sex}
            required={true}
            shrink={true}
            labelWidth={50}
            fullWidth={true}
            disabled={!canUpdatePatient}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <BDropDown
            id={generateID('drpdwn', 'pr_profile', 'organization')}
            name='patient.organization_id'
            label='Organization'
            menuItems={organizationOptions}
            required={true}
            shrink={true}
            labelWidth={55}
            fullWidth={true}
            disabled={!canUpdatePatient}
            value={patient.organization_id}
            handleChange={e => {
              setFieldValue('patient.location_id', '');
              handleChange(e);
            }}
            handleBlur={handleBlur}
            errors={
              touched.patient && errors.patient
                ? touched.patient.organization_id &&
                  errors.patient.organization_id
                : false
            }
          />
        </Grid>
        <Grid item xs={6}>
          <BDropDown
            id={generateID('drpdwn', 'pr_profile', 'location')}
            name='patient.location_id'
            label='Location'
            menuItems={locationOptions}
            disabled={!patient.organization_id || !canUpdatePatient}
            shrink={true}
            labelWidth={55}
            fullWidth={true}
            required={true}
            value={patient.location_id}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={
              touched.patient && errors.patient
                ? touched.patient.location_id && errors.patient.location_id
                : false
            }
          />
        </Grid>
        <Grid item xs={12}>
          <BTextField
            label='Patient ID/MRN'
            name='patient.patient_identifier'
            id={generateID('input', 'pr_profile', 'patient_identifier')}
            value={patient.patient_identifier}
            required={true}
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
            errors={errors.patient ? errors.patient.patient_identifier : false}
            touched={
              touched.patient ? touched.patient.patient_identifier : false
            }
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientInformation;
