import { SvgIcon } from '@mui/material';
let BEOSReviewDue = props => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.3234 8.76925H20.4926C20.908 8.76925 21.2311 9.09233 21.2311 9.50771V12.9382C20.1597 11.7488 18.625 11 16.8996 11C13.6014 11 11 13.7361 11 17.0549C11 20.1618 13.2797 22.758 16.2767 23.0769H4.98492C3.78492 23.0769 2.76953 22.0616 2.76953 20.8616V3.13848C2.76953 1.93848 3.78492 0.923096 4.98492 0.923096H12.6465C13.0618 0.923096 13.3849 1.24617 13.3849 1.66156V6.83079C13.3849 8.03079 14.1234 8.76925 15.3234 8.76925ZM17.4992 23.0769H19.0157C19.3132 23.0769 19.5993 23.0145 19.8615 22.9024L19.4606 22.4885C18.8566 22.7952 18.1923 23.0008 17.4992 23.0769ZM16.2003 1.06156L21.0926 5.95386C21.1849 6.04617 21.2311 6.23079 21.2311 6.36925C21.2311 6.64617 21.0003 6.9231 20.7234 6.9231H16.8003C15.9695 6.9231 15.2311 6.18463 15.2311 5.35387V1.43079C15.2311 1.15386 15.508 0.923096 15.7849 0.923096C15.9234 0.923096 16.108 0.96925 16.2003 1.06156Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8826 12C19.5869 12 21.7653 14.2488 21.7653 17.0404C21.7653 17.8934 21.5399 18.7464 21.1643 19.4443L23.831 22.1971C24.0563 22.4297 24.0563 22.7787 23.831 23.0113L23.0423 23.8255C22.8169 24.0582 22.4789 24.0582 22.2535 23.8255L19.662 21.1502C18.8732 21.7318 17.8967 22.0808 16.8826 22.0808C14.1784 22.0808 12 19.832 12 17.0404C12 14.2488 14.1784 12 16.8826 12ZM16.8826 19.7544C18.3474 19.7544 19.5117 18.5525 19.5117 17.0404C19.5117 15.5283 18.3474 14.3263 16.8826 14.3263C15.4178 14.3263 14.2535 15.5283 14.2535 17.0404C14.2535 18.5525 15.4178 19.7544 16.8826 19.7544Z'
        fill='black'
      />
    </SvgIcon>
  );
};

export default BEOSReviewDue;
