// This component creates autocomplete-searcheable list with option to select multiple list items.
/**
 * itemList format: Array<{text, value}>
 */
import { TextField, Autocomplete } from '@mui/material';

interface IBAutoComplete {
  label?: string;
  name?: string;
  id?: string;
  value?: string;
  inputValue?: any;
  required?: boolean;
  fullWidth?: boolean;
  handleChange?: any;
  handleBlur?: any;
  shrink?: any;
  errors?: any;
  touched?: any;
  style?: any;
  multiple?: boolean;
  className?: any;
  itemList: any;
  disabled?: boolean;
}

const BAutoComplete = ({
  name,
  inputValue,
  multiple,
  handleChange,
  itemList,
  shrink,
  label,
  required,
  errors,
  handleBlur,
  touched,
  id,
  disabled,
}: IBAutoComplete) => {
  return (
    <Autocomplete
      multiple={multiple}
      onBlur={handleBlur}
      options={itemList}
      getOptionLabel={option => (option.text ? option.text : '')}
      onChange={handleChange}
      value={inputValue}
      id={id}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          required={required}
          variant='outlined'
          InputLabelProps={{
            // classes: {
            //     root: classes.labelRoot,
            // },
            shrink: shrink,
          }}
          label={label}
          error={required && errors && touched}
          helperText={required && errors && touched ? errors : ''}
        />
      )}
    />
  );
};

export default BAutoComplete;
