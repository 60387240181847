import { ROUTE_COMPONENTS } from './permissions_components';
import {
  D_HISTORY_ALL_RECORDS,
  D_HISTORY_CEMSUMMARY_REPORT,
  D_HISTORY_DEVICE,
  D_HISTORY_EVENT_REPORT,
  D_HISTORY_EVENTS,
  D_HISTORY_NOTE,
  D_HISTORY_PROFILE,
  D_HISTORY_SETTINGS,
  D_HISTORY_SUMMARY_REPORT,
  HISTORY_ALL_RECORDS,
  HISTORY_CEMSUMMARY_REPORT,
  HISTORY_DEVICE,
  HISTORY_EVENT_REPORT,
  HISTORY_EVENTS,
  HISTORY_NOTE,
  HISTORY_PROFILE,
  HISTORY_SETTINGS,
  HISTORY_SUMMARY_REPORT,
} from './types';

export const MAP_PATIENT_EVENT_LIST_COL_FILTERS = {
  Critical: 'Critical',
  New: 'New',
  Review: 'Review',
  Published: 'Published',
  Done: 'No Report',
  AF: 'AF',
  VT: 'VT',
  P: 'P',
  T: 'T',
  B: 'B',
  PATIENT_EVENTS: 'Patient Events',
  Active: 'Active',
  battery_level: 'Active + Change Patch',
  'Active + Change Patch': 'Active + Change Patch', // fixes empty bubbles
  data_transmission: 'Active + No Data',
  'Active + No Data': 'Active + No Data', // fixes empty bubbles
  data_transmission_battery_level: 'Active + No Data + Change Patch',
  'Active + No Data + Change Patch': 'Active + No Data + Change Patch', // same
  Incomplete: 'Incomplete',
  Pending: 'Pending',
  Deactivated: 'Deactivated',
  periodic_summarydue: 'Periodic Report Due',
  eosdue: 'EOS Summary Report Due',
  periodic_summaryready_for_review: 'Periodic Report CC Review',
  eosready_for_review: 'EOS Summary Report CC Review',
  event: 'Event Reports',
  periodic_summary: 'Periodic Summary Reports',
  eos_cemready_for_review: 'EOS Summary Reports Ready for Review',
  eos_cemaccepted: 'EOS Summary Reports Approved',
  'mail-to-patient': 'Mail to Patient',
  clinic: 'At Clinic',
};

export const FILTER_EVENTS = {
  current_status: [
    { value: 'Critical', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Critical },
    { value: 'New', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.New },
    { value: 'Review', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Review },
    { value: 'Published', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Published },
    { value: 'Done', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Done },
  ],
  tevents: [
    { value: 'AF', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.AF },
    { value: 'VT', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.VT },
    { value: 'P', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.P },
    { value: 'T', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.T },
    { value: 'B', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.B },
    {
      value: 'PATIENT_EVENTS',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.PATIENT_EVENTS,
    },
  ],
  tdevice_status: [
    { value: 'Active', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Active },
    {
      value: 'Active + Change Patch',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.battery_level,
    },
    {
      value: 'Active + No Data',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.data_transmission,
    },
    {
      value: 'Active + No Data + Change Patch',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.data_transmission_battery_level,
    },
    {
      value: 'Deactivated',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Deactivated,
    },
    {
      value: 'Incomplete',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Incomplete,
    },
    { value: 'Pending', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Pending },
  ],
  tviewer_due: [
    {
      value: ['periodic_summary', 'due'],
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.periodic_summarydue,
    },
    { value: ['eos', 'due'], label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.eosdue },
    {
      value: ['periodic_summary', 'ready_for_review'],
      label:
        MAP_PATIENT_EVENT_LIST_COL_FILTERS.periodic_summaryready_for_review,
    },
    {
      value: ['eos', 'ready_for_review'],
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.eosready_for_review,
    },
  ],
  history: [
    { value: ['event', ''], label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.event },
    {
      value: ['periodic_summary', ''],
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.periodic_summary,
    },
    {
      value: ['eos,cem', 'ready_for_review'],
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.eos_cemready_for_review,
    },
    {
      value: ['eos,cem', 'accepted'],
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.eos_cemaccepted,
    },
  ],
  application: [
    { value: 'clinic', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS['clinic'] },
    {
      value: 'mail-to-patient',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS['mail-to-patient'],
    },
  ],
};

export const MAP_PATIENT_EVENT_LIST_COL_LABELS = {
  current_status: 'Status',
  name: 'Patient Name',
  date_of_birth: 'Date of Birth',
  patient_identifier: 'Patient Identifier',
  prescribing_provider: 'Prescribing Provider',
  technician_notes: 'Technician Notes',
  tevent_date: 'Event Date',
  notification_date: 'Notification Date',
  tevents: 'Clinical Events',
  tdevice_status: 'Device Status',
  tviewer: 'Viewer',
  tviewer_due: 'Viewer / Due',
  history: 'Reports',
  age_sex: 'Age / Sex',
  indications: 'Indications',
  tmct_ID: 'Device SN',
  organization: 'Organization',
  application: 'Application',
};

export const PORTALS = [
  {
    id: 'CAM',
    value: 'cam',
    label: 'CAM Portal',
    redirect: 'https://bdxc-web-test2.azurewebsites.net',
  },
  { id: 'BDM', value: 'bdm_web', label: 'CAM DXT Portal', redirect: '/' },
];

// User permissions

export const finalPermissions = {
  canAccessTechNotes: false,
  canUpdatePatient: false,
  canUpdateSettings: false,
  canRegisterDevice: false,
  canLaunchECGViewer: false,
  canDeactivateDevice: false,
  navigationMenu: [],
  historyFilter: [],
  routeList: [],
};

export const SUPERSET_NAVIGATION_MENU = {
  EVENT_QUEUE: {
    id: 'event_queue',
    linkTo: '/events',
    label: 'Event Queue',
    type: 'EVENT',
    value: '/events',
  },
  PATIENT_LIST: {
    id: 'patient_list',
    linkTo: '/patients',
    label: 'Patient List',
    type: 'PATIENT',
    value: '/patients',
  },
  NEW_PATIENT: {
    id: 'new_patient',
    linkTo: '/patient/new',
    label: 'NEW PATIENT',
    type: 'patientregistration',
    value: '/patient/new',
  },
  CURRENT_PATIENT: {
    id: 'current_patient',
    linkTo: null,
    label: 'CURRENT PATIENT',
    type: 'patientregistration',
    value: '/patient/:id',
  },
  ADMIN: {
    id: 'admin',
    linkTo: '/admin',
    label: 'ADMIN',
    type: null,
    value: '/admin',
  },
};

export const SUPERSET_HISTORY_FILTER_LIST = {
  [HISTORY_ALL_RECORDS]: {
    value: HISTORY_ALL_RECORDS,
    label: D_HISTORY_ALL_RECORDS,
  },
  [HISTORY_EVENTS]: { value: HISTORY_EVENTS, label: D_HISTORY_EVENTS },
  [HISTORY_NOTE]: { value: HISTORY_NOTE, label: D_HISTORY_NOTE },
  [HISTORY_EVENT_REPORT]: {
    value: HISTORY_EVENT_REPORT,
    label: D_HISTORY_EVENT_REPORT,
  },
  [HISTORY_SUMMARY_REPORT]: {
    value: HISTORY_SUMMARY_REPORT,
    label: D_HISTORY_SUMMARY_REPORT,
  },
  [HISTORY_CEMSUMMARY_REPORT]: {
    value: HISTORY_CEMSUMMARY_REPORT,
    label: D_HISTORY_CEMSUMMARY_REPORT,
  },
  [HISTORY_SETTINGS]: { value: HISTORY_SETTINGS, label: D_HISTORY_SETTINGS },
  [HISTORY_PROFILE]: { value: HISTORY_PROFILE, label: D_HISTORY_PROFILE },
  [HISTORY_DEVICE]: { value: HISTORY_DEVICE, label: D_HISTORY_DEVICE },
};

export const PATIENT_LIST_COLUMNS = {
  name: {
    hide: false,
    align: 'left',
    field: 'name',
    label: 'Patient Name',
    order: 1,
    width: 295,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'name',
  },
  date_of_birth: {
    hide: false,
    align: 'left',
    field: 'date_of_birth',
    label: 'Date of Birth',
    order: 2,
    width: 150,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'date_of_birth',
  },
  patient_identifier: {
    hide: false,
    align: 'left',
    field: 'patient_identifier',
    label: 'Patient Identifier',
    order: 4,
    width: 155,
    hideable: false,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'patient_identifier',
  },
  prescribing_provider: {
    hide: false,
    align: 'left',
    field: 'prescribing_provider',
    label: 'Prescribing Provider',
    order: 5,
    width: 198,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'prescribing_provider',
  },
  technician_notes: {
    hide: false,
    align: 'left',
    field: 'technician_notes',
    label: 'Technician Notes',
    order: 0,
    width: 278,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: false,
    filterable: true,
    resizeable: true,
    header_name: 'technician_notes',
  },
  tdevice_status: {
    hide: true,
    align: 'left',
    field: 'tdevice_status',
    label: 'Device Status',
    order: 6,
    width: 157,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'tdevice_status',
  },
  history: {
    hide: true,
    align: 'left',
    field: 'history',
    label: 'Reports',
    order: 7,
    width: 287,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'history',
  },
  tviewer_due: {
    hide: false,
    align: 'left',
    field: 'tviewer_due',
    label: 'Viewer / Due',
    order: 3,
    width: 203,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'tviewer_due',
  },
  age_sex: {
    hide: false,
    align: 'left',
    field: 'age_sex',
    label: 'Age/Sex',
    order: 8,
    width: 205,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: false,
    filterable: false,
    resizeable: true,
    header_name: 'age_sex',
  },
  indications: {
    hide: false,
    align: 'left',
    field: 'indications',
    label: 'Indications',
    order: 9,
    width: 235,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: false,
    filterable: true,
    resizeable: true,
    header_name: 'indications',
  },
  tmct_ID: {
    hide: false,
    align: 'left',
    field: 'tmct_ID',
    label: 'Device ID',
    order: 10,
    width: 264,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'tmct_ID',
  },
  organization: {
    hide: false,
    align: 'left',
    field: 'organization',
    label: 'Organization',
    order: 11,
    width: 200,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: false,
    filterable: true,
    resizeable: true,
    header_name: 'organization',
  },
  application: {
    hide: false,
    align: 'left',
    field: 'application',
    label: 'Application',
    order: 1,
    width: 295,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'application',
  },
};

export const EVENT_LIST_COLUMNS = {
  current_status: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'current_status',
    width: 200,
    minWidth: 150,
    maxWidth: null,
    order: 0,
    label: 'Status',
    header_name: 'current_status',
  },
  notification_date: {
    sortable: true,
    filterable: false,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'notification_date',
    width: 150,
    minWidth: 50,
    maxWidth: null,
    order: 1,
    label: 'Notification Date',
    header_name: 'notification_date',
  },
  tevent_date: {
    sortable: true,
    filterable: false,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'tevent_date',
    width: 150,
    minWidth: 50,
    maxWidth: null,
    order: 2,
    label: 'Event Date',
    header_name: 'tevent_date',
  },
  tevents: {
    sortable: false,
    filterable: true,
    hideable: false,
    resizeable: false,
    hide: false,
    align: 'left',
    field: 'tevents',
    width: 390,
    minWidth: 50,
    maxWidth: null,
    order: 3,
    label: 'Clinical Events',
    header_name: 'tevents',
  },
  tviewer: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'tviewer',
    width: 100,
    minWidth: 50,
    maxWidth: null,
    order: 4,
    label: 'Viewer',
    header_name: 'tviewer',
  },
  tmct_ID: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'tmct_ID',
    width: 150,
    minWidth: 50,
    maxWidth: null,
    order: 5,
    label: 'Device ID',
    header_name: 'tmct_ID',
  },
  patient_identifier: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'patient_identifier',
    width: 241,
    minWidth: 50,
    maxWidth: null,
    order: 6,
    label: 'Patient Identifier',
    header_name: 'patient_identifier',
  },
  age_sex: {
    sortable: false,
    filterable: false,
    hideable: false,
    resizeable: false,
    hide: false,
    align: 'left',
    field: 'age_sex',
    width: 75,
    minWidth: 50,
    maxWidth: null,
    order: 7,
    label: 'Age / Sex',
    header_name: 'age_sex',
  },
  indications: {
    sortable: false,
    filterable: true,
    hideable: false,
    resizeable: false,
    hide: false,
    align: 'left',
    field: 'indications',
    width: 162,
    minWidth: 50,
    maxWidth: null,
    order: 8,
    label: 'Indications',
    header_name: 'indications',
  },
  name: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'name',
    width: 251,
    minWidth: 50,
    maxWidth: null,
    order: 9,
    label: 'Patient Name',
    header_name: 'name',
  },
  history: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'history',
    width: 112,
    minWidth: 50,
    maxWidth: null,
    order: 10,
    label: 'Reports',
    header_name: 'history',
  },
  tdevice_status: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'tdevice_status',
    width: 100,
    minWidth: 50,
    maxWidth: null,
    order: 11,
    label: 'Device Status',
    header_name: 'tdevice_status',
  },
  prescribing_provider: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'prescribing_provider',
    width: 154,
    minWidth: 50,
    maxWidth: null,
    order: 12,
    label: 'Prescribing Provider',
    header_name: 'prescribing_provider',
  },
  date_of_birth: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'date_of_birth',
    width: 150,
    minWidth: 50,
    maxWidth: null,
    order: 13,
    label: 'Date of Birth',
    header_name: 'date_of_birth',
  },
  technician_notes: {
    sortable: false,
    filterable: true,
    hideable: false,
    resizeable: false,
    hide: false,
    align: 'left',
    field: 'technician_notes',
    width: 241,
    minWidth: 50,
    maxWidth: null,
    order: 14,
    label: 'Technician Notes',
    header_name: 'technician_notes',
  },
  organization: {
    sortable: false,
    filterable: true,
    hideable: false,
    resizeable: false,
    hide: false,
    align: 'left',
    field: 'organization',
    width: 200,
    minWidth: 50,
    maxWidth: null,
    order: 15,
    label: 'Organization',
    header_name: 'organization',
  },
};

export interface ColsType {
  columns: any;
  density: string;
  pageSize: number;
}
