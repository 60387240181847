import { styled } from '@mui/system';

const BStyledTableRow = styled('tr')(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  height: '40px',
}));

export default BStyledTableRow;
