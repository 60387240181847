import { useState, useContext } from 'react';
import { Grid, TextareaAutosize } from '@mui/material';
import { generateID } from '../../../utility/utilityFunctions';
import BAutoComplete from '../../common/BAutoComplete';
import BFormGroupTitle from '../../common/BFormGroupTitle';
import { ICONS } from '../../../utility/IconSelection';
import BRadio from '../../common/BRadio';
import BDropDown from '../../common/BDropDown';
import { EMPTY_SELECT_OPTION } from '../../../types/types';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';

// Indications
export const getDefaultIndiValue = (indicationList, indication) => {
  let indications = [];
  for (let i = 0; i < indicationList.length; i++) {
    if (indication.includes(indicationList[i].value)) {
      indications.push(indicationList[i]);
    }
  }
  return indications;
};

// Underlying Heart Disease
const getDefaultHeartDiseaseList = (heartDiseaseList, heart_disease) => {
  let hDiseases = [];
  for (let i = 0; i < heartDiseaseList.length; i++) {
    if (heart_disease.includes(heartDiseaseList[i].value)) {
      hDiseases.push(heartDiseaseList[i]);
    }
  }
  return hDiseases;
};

const PatientClinicalInformation = props => {
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const { canUpdatePatient } = USER_ACCESS;

  // populate the indications list
  let indicationList = props.indicationList || [];
  indicationList = indicationList.map(indication => {
    return { text: indication.desc, value: indication.id };
  });
  const [indicationDefault, setDefaultIndication] = useState(
    getDefaultIndiValue(indicationList, props.patient.indications)
  );

  const emptySelectOption = {
    value: EMPTY_SELECT_OPTION,
    label: 'Please select...',
  };

  const indicationDropdownItems = [
    emptySelectOption,
    ...props.indicationList
      .map(i => ({
        value: i.id,
        label: `${i.desc} (${i.code})`,
      }))
      .filter(i => i.id !== props.patient.primary_indication_id),
  ];

  // populate the heartDisease list
  let heartDiseaseList = props.underlyingHeartDiseaseList || [];
  heartDiseaseList = heartDiseaseList.map(disease => {
    return { text: disease.desc, value: disease.id };
  });
  const [heartDiseaseDefault, setHeartDiseaseDefault] = useState(
    getDefaultHeartDiseaseList(
      heartDiseaseList,
      props.patient.underlying_heart_diseases
    )
  );
  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '22px',
            marginBottom: '20px',
            backgroundColor: 'rgb(238 238 238)',
          }}
        >
          <BFormGroupTitle
            title='Clinical Information'
            icon={ICONS['INFORMATION']}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BDropDown
            id={generateID('drpdwn', 'pr_profile', 'indications_primary')}
            name='patient.primary_indication_id'
            label='Primary Indication for medical necessity'
            menuItems={indicationDropdownItems}
            value={props.patient.primary_indication_id}
            required={true}
            shrink={true}
            labelWidth={50}
            fullWidth={true}
            disabled={!canUpdatePatient}
            handleChange={props.handleChange}
            errors={
              props.errors.patient && props.touched.patient
                ? props.touched.patient.primary_indication_id &&
                  props.errors.patient.primary_indication_id
                : false
            }
          />
        </Grid>
        <Grid item xs={12}>
          <BAutoComplete
            id={generateID('drpdwn_auto', 'pr_profile', 'indications')}
            value={props.patient.indications}
            required={false}
            inputValue={indicationDefault}
            name='patient.indications'
            label='Additional ICD-10 indication(s)'
            multiple={true}
            handleChange={(event, value) => {
              props.setFieldValue(
                'patient.indications',
                value.map(item => item.value)
              );
              setDefaultIndication(value);
            }}
            errors={
              props.errors.patient ? props.errors.patient.indications : false
            }
            handleBlur={props.handleBlur}
            touched={
              props.touched.patient ? props.touched.patient.indications : false
            }
            itemList={indicationList}
            shrink={true}
            disabled={!canUpdatePatient}
          />
        </Grid>
        <Grid item xs={12}>
          <BRadio
            id={generateID('radio', 'pr_profile', 'pacemaker')}
            label='Pacemaker/ICD'
            name='patient.pacemaker'
            value={`${props.patient.pacemaker}`}
            options={[
              { value: 'false', label: 'No' },
              { value: 'true', label: 'Yes' },
            ]}
            handleChange={(event, value) => {
              const newVal = value === 'false' ? false : true; // Formik requires a string but DB takes a boolean
              props.setFieldValue('patient.pacemaker', newVal);
              props.handleChange();
            }}
            disabled={!canUpdatePatient}
          />
        </Grid>
        <Grid item xs={12}>
          <BRadio
            id={generateID('radio', 'pr_profile', 'prior_ecg')}
            value={String(props.patient.prior_ecg)}
            label='Prior ECG'
            disabled={!canUpdatePatient}
            name='patient.prior_ecg'
            options={[
              { value: 'true', label: 'Yes' },
              { value: 'false', label: 'No' },
              { value: 'null', label: 'Unknown' },
            ]}
            handleChange={(event, value) => {
              const valObj = {
                false: false,
                true: true,
                null: null,
              };
              const newVal = valObj[value];
              props.setFieldValue('patient.prior_ecg', newVal);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <BAutoComplete
            id={generateID(
              'drpdwn_auto',
              'pr_profile',
              'underlying_heart_disease'
            )}
            value={props.patient.underlying_heart_diseases}
            required={false}
            inputValue={heartDiseaseDefault}
            name='patient.underlying_heart_diseases'
            label='Underlying heart disease'
            multiple={true}
            handleChange={(event, value) => {
              props.setFieldValue(
                'patient.underlying_heart_diseases',
                value.map(item => item.value)
              );
              setHeartDiseaseDefault(value);
            }}
            errors={
              props.errors.patient
                ? props.errors.patient.underlying_heart_diseases
                : false
            }
            handleBlur={props.handleBlur}
            touched={
              props.touched.patient
                ? props.touched.patient.underlying_heart_diseases
                : false
            }
            itemList={heartDiseaseList}
            shrink={true}
            disabled={!canUpdatePatient}
          />
        </Grid>
        <Grid container spacing={2} direction='row'>
          <Grid item xs={12}>
            <label>Notes relevant to ECG analysis</label>
            <TextareaAutosize
              disabled={!canUpdatePatient}
              id={generateID('txtarea', 'pr_profile', 'ecg_notes')}
              aria-label='minimum height'
              style={{
                width: '100%',
                fontFamily: [
                  'Nunito',
                  'Roboto',
                  '"Helvetica Neue"',
                  'Arial',
                  'sans-serif',
                ].join(','),
                fontSize: 14,
              }}
              minRows={5}
              name='patient.notes'
              value={props.patient.ecg_analysis_notes}
              onChange={e => {
                props.setFieldValue(
                  'patient.ecg_analysis_notes',
                  e.target.value
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PatientClinicalInformation;
