// External Libaries
import { useMemo } from 'react';
import { Grid, Avatar, Typography } from '@mui/material';
import {
  generateID,
  getEventIconStyles,
  getEventStyles,
} from '../../utility/utilityFunctions';
import BIcon from '../common/BIcon';
import IOSSwitch from '../common/BIOSSwitch';
import { AdminThresholdLimit } from './AdminThresholdLimit';
import { commonData } from '../../data/CommonData';
import { ICONS } from '../../utility/IconSelection';
import { ISetting } from '../../types/types';

// Internal Libraries
interface IProps {
  setting: ISetting;
  [key: string]: any;
}

const ThresholdSettingsRow = ({
  setting,
  values,
  handleChange,
  handleSettingChange,
  handleThresholdChange,
  errors,
  touched,
  handleBlur,
  changed,
}: IProps) => {
  const slowVTValue = useMemo(() => {
    const slow_vt = setting.thresholds.find(
      t => t.thresholdType.reference_id === 'slow_vt'
    );
    if (!slow_vt) return 0;
    const val = values['thresholds'][slow_vt.id];
    return val;
  }, [setting, values]);

  return (
    <Grid container className={'threshold-container'}>
      <Grid item xs={12}>
        <Grid
          container
          className={'threshold-row'}
          sx={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Grid item xs={4}>
            <Grid container alignItems='center'>
              <Grid item xs={9} style={{ direction: 'rtl', padding: '10px' }}>
                <Typography
                  id={generateID(
                    'txt',
                    'pr_settings',
                    setting.settingType.abbr + '_event'
                  )}
                >
                  {setting.settingType.name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                {/* Icons/ abbr column */}
                {setting.settingType.abbr_type === 'string' ? (
                  <Avatar
                    id={generateID(
                      'txt',
                      'pr_settings',
                      setting.settingType.abbr + '_eventsym'
                    )}
                    variant='rounded'
                    sx={getEventStyles(
                      'settings',
                      values['settings'][setting.id]
                    )}
                    style={{
                      display: 'inline-flex',
                      marginRight: '5px',
                      fontSize: '18px',
                    }}
                  >
                    {setting.settingType.abbr}
                  </Avatar>
                ) : (
                  <BIcon
                    id={generateID(
                      'icon',
                      'pr_settings',
                      setting.settingType.abbr + '_eventsym'
                    )}
                    style={Object.assign(
                      { fontSize: '35px' },
                      getEventIconStyles(values['settings'][setting.id])
                    )}
                    icon={ICONS[setting.settingType.abbr_type]}
                    fontSize='large'
                  />
                )}
                {/* space for icon - change occurred for the event */}
                {changed ? (
                  <BIcon
                    icon={ICONS['EDIT']}
                    style={{
                      color: commonData.standardColor.bardyBlue.color,
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                    class='patient-list-icons blink-icon'
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            {setting.thresholds.map(threshold => (
              <AdminThresholdLimit
                key={threshold.id}
                item={threshold}
                hide={
                  // TODO ajk: move this out of here
                  setting.settingType.abbr === 'VT' &&
                  slowVTValue === 0 &&
                  (threshold.thresholdType.reference_id === 'slow_vt_hr_bpm' ||
                    threshold.thresholdType.reference_id ===
                      'slow_vt_duration_beats')
                }
                disabled={values['settings'][setting.id] === 'off'}
                error={errors['thresholds']?.[threshold.id] || false}
                touched={touched['thresholds']?.[threshold.id] || false}
                handleBlur={handleBlur}
                handleChange={e => {
                  handleThresholdChange(
                    threshold,
                    setting.settingType.abbr,
                    e.target.value
                  );
                }}
                value={values['thresholds'][threshold.id]}
                settingType={setting.settingType}
              />
            ))}
          </Grid>
          <Grid
            item
            xs={3}
            style={{ display: 'inline-flex', justifyContent: 'center' }}
          >
            <IOSSwitch
              checked={values['settings'][setting.id] === 'on'}
              value={values['settings'][setting.id]}
              onChange={e => {
                const newVal = e.target.value === 'off' ? 'on' : 'off';
                handleSettingChange(setting, newVal);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ThresholdSettingsRow;
