import React from 'react';
import { Grid, Typography } from '@mui/material';
import BTextField from '../common/BTextField';
import { generateID } from '../../utility/utilityFunctions';
import BDropDown from '../common/BDropDown';
import { FIRST_OCCURENCE_OPTIONS } from '../../types/types';

export const AdminThresholdLimit = ({
  item,
  disabled,
  error,
  touched,
  handleBlur,
  handleChange,
  settingType,
  value,
}) => {
  const isFirstOccurence =
    settingType.abbr === 'AF' && item.thresholdType.name === 'First Occurrence';
  return (
    <Grid container style={{ alignItems: 'center' }}>
      <Grid item md={5} xs={5}>
        <Typography
          style={{ direction: 'rtl', paddingRight: '10px' }}
          id={generateID(
            'txt',
            'pr_settings',
            settingType.abbr + '_limitname_' + item.id
          )}
        >
          {item.thresholdType.name}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={2}
        style={{ display: 'inline-flex', alignItems: 'center', margin: '4px' }}
      >
        {isFirstOccurence ? (
          <BDropDown
            id={generateID(
              'drpdwn',
              'pr_settings',
              settingType.abbr + '_limit_thresholdType_' + item.id
            )}
            value={value}
            menuItems={FIRST_OCCURENCE_OPTIONS}
            shrink={true}
            fullWidth={false}
            className='setting-textbox'
            inputClassName='setting-limit-inputsize'
            handleChange={handleChange}
          />
        ) : (
          <BTextField
            id={generateID(
              'input',
              'pr_settings',
              settingType.abbr + '_limit_thresholdType_' + item.id
            )}
            required={true}
            disabled={disabled} // TODO: need to reference setting.value, not threshold.value
            type='number'
            name={`thresholds.${item.id}`}
            errors={error}
            touched={touched}
            className='setting-textbox'
            value={value}
            handleChange={handleChange}
            inputClassName='setting-limit-inputsize'
            handleBlur={handleBlur}
            step={settingType.abbr === 'DATA_TRANSMISSION' ? 0.5 : 1}
          />
        )}
        <Typography
          style={{ marginLeft: '4px', fontSize: '12px' }}
          id={generateID(
            'txt',
            'pr_settings',
            settingType.abbr + '_max_' + item.id
          )}
        >
          ( {isFirstOccurence ? 'On' : item.thresholdType.min} -{' '}
          {isFirstOccurence ? 'Off' : item.thresholdType.max} )
        </Typography>
      </Grid>
    </Grid>
  );
};
