import './ErrorCard.css';

export interface IErrorCard {
  title: string,
  description: string,
  errorCode: string;
  onClose: () => void;
}

const ErrorCard = (props: IErrorCard) => {
  return (
    <div className="error-card">
      <button className="error-card-close" onClick={props.onClose}>
        x
      </button>
      <div className="error-card-content">
        <div className='error-card-title'>
          {props.title}
        </div>
        <div className='error-card-message'>
          {props.description}
        </div>
        <div className='error-card-message'>
          {props.errorCode}
        </div>
      </div>
    </div>
  );
};

export default ErrorCard;